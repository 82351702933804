import { useState, useEffect } from "react";
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../fx/FxLog';

export const WIN_SIZE = {
  lg: 900, // 2025-01-16 - daq: antes era 1200
}

export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({ width: undefined, height: undefined, isLarge: undefined });
  
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      FxLog.infox('useWindowSize.handleResize', '...', `width: ${window.innerWidth}px, height: ${window.innerHeight}px`);
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        isLarge: window.innerWidth >= WIN_SIZE.lg,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  
  return windowSize;
}