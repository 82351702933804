import { useNavigate } from 'react-router-dom';
import * as FxLog from '../fx/FxLog';
import { getStoreValue } from "../gx/GxStore";

export const useNav = () => {
  let navigate = useNavigate();
  
  const isCP = () => { return Boolean(getStoreValue('StoreSession', 'inClientCP')) } // 2023-05-26 - daq: Portal del Cliente

  const _navExit = () => {
    let path = isCP() ? 'cp' : 'login';
    sessionStorage.clear();
    navigate(`/${path}`, { replace: true });
  }

  const _navTo = (url, navParams) => {
    let arUrl = JSON.parse(sessionStorage.getItem('useNav.urlStack'))||[];
    arUrl.push(url);
    sessionStorage.setItem('useNav.urlStack', JSON.stringify(arUrl));
    sessionStorage.setItem('useNav.count', (Number(sessionStorage.getItem('useNav.count')||0) + 1).toString());
    navigate(`/${isCP() ? 'main_cp' : 'main'}/${url}`, { replace: true, state: navParams }); //useLocation() captura los params
  }

  const _navBack = (navParams) => {
    let arUrl = JSON.parse(sessionStorage.getItem('useNav.urlStack'))||[];
    arUrl.pop();
    let url = arUrl.slice(-1)[0];
    sessionStorage.setItem('useNav.urlStack', JSON.stringify(arUrl));
    sessionStorage.setItem('useNav.count', (Number(sessionStorage.getItem('useNav.count')||0) - 1).toString());
    if (url) {
      navigate(`/${isCP() ? 'main_cp' : 'main'}/${url}`, { replace: true, state: navParams /* ej: GxSlsAuxTableItem y GxSlsAuxTableItem */ }); //useLocation() captura los params
    }
  }

  const _navStack = () => {
    return JSON.parse(sessionStorage.getItem('useNav.urlStack'));
  }

  const _navClear = () => {
    sessionStorage.removeItem('useNav.urlStack');
    sessionStorage.setItem('useNav.count', '0');
  }

  const _navCount = () => {
    let count = sessionStorage.getItem('useNav.count')||0;
    FxLog.infox('useNav._navCount', 'count: ' + count);
    return count;
  }

  return [
    {
      navTo: _navTo,
      navBack: _navBack,
      navClear: _navClear,
      navCount: _navCount,
      navExit: _navExit,
      navStack: _navStack,
    },
  ];
};
