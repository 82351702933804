import { MdOutlinePets } from "react-icons/md"; 
import React from 'react';
import GxForm from '../../gx/GxForm';
import { useTranslation } from '../../hx/useTranslation';
import { useNav } from '../../hx/useNav';
import CxInput from '../../cx/CxInput';
import CxDate from '../../cx/CxDate';
import * as FxLog from '../../fx/FxLog';
import * as FxStr from '../../fx/FxStr';
import * as FxDat from '../../fx/FxDat';
import { StoreCalendarView, StoreCalendarEvent, StoreComm, StoreClient, clearStore } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import CxTime from '../../cx/CxTime';
import CxSearcher from '../../cx/CxSearcher';
import CxChooser from '../../cx/CxChooser';
import * as FxFetch from '../../fx/FxFetch';
import CxSnackbar from '../../cx/CxSnackbar';
import { isApp } from '../../gx/GxStore';
import CxBackdrop from '../../cx/CxBackdrop';
import moment from 'moment';
import CxDialog from '../../cx/CxDialog';
import CxIconButton from '../../cx/CxIconButton';
import { useAccess } from '../../hx/useAccess';
import { FaSquareFull } from "react-icons/fa";
import CxIconButtonNew from '../../cx/CxIconButtonNew';
import MxClientOTF from '../common/otf/MxClientOTF';
import MxAnimalOTF from '../common/otf/MxAnimalOTF';
import GxIconButtonLastClient from '../../gx/GxIconButtonLastClient';


const MxCalendarForm = (props) => {
  const [{ trans }] = useTranslation();
  const [{ isFullAccess }] = useAccess(trans('field.calendar'));
  const [{ navBack, navTo }] = useNav();

  // eslint-disable-next-line no-unused-vars
  const [storeCalendarView, setStoreCalendarView] = useSessionStorage('StoreCalendarView', StoreCalendarView);
  const [storeCalendarEvent, setStoreCalendarEvent] = useSessionStorage('StoreCalendarEvent', StoreCalendarEvent);
  const [storeComm, setStoreComm] = useSessionStorage('StoreComm', StoreComm);
  const [storeClient, setStoreClient] = useSessionStorage('StoreClient', StoreClient);

  const [dlgInfo, setDlgInfo] = React.useState(false);
  const [info, setInfo] = React.useState(null);

  const [dlgDelete, setDlgDelete] = React.useState(false);
  const [backdrop, setBackdrop] = React.useState(false);

  let r_form = React.useRef(null);
  let r_dlg_new_client = React.useRef(null);
  let r_dlg_new_animal = React.useRef(null);

  // references to fields
  const rf_date = React.useRef(null);
  const rf_txTypeCalendarCol = React.useRef(null);
  const rf_txTypeCalendarGrp = React.useRef(null);
  const rf_time_start = React.useRef(null);
  const rf_time_end = React.useRef(null);
  const rf_title = React.useRef(null);
  const rf_notes = React.useRef(null);
  const rf_txClient = React.useRef(null);
  const rf_txAnimal = React.useRef(null);

  const r_snack_ask_save = React.useRef(null);
  const r_snack_save = React.useRef(null);
  const r_snack_required = React.useRef(null);
  const r_snack_delete = React.useRef(null);
  const r_snack_overlap = React.useRef(null);
  const r_snack_error = React.useRef(null);


  React.useEffect(() => {
    clearStore('StoreComm', storeComm);
    clearStore('StoreClient', storeClient);
    formLoadCustom();
    // eslint-disable-next-line
  }, []);

  const formLoadCustom = () => {
    if (!storeCalendarEvent.inCalendar) {
      // evento nuevo
      // valores predefinidos
      let mDateTime = moment(storeCalendarEvent.dtFrom);
      let theDate = mDateTime.format(moment.HTML5_FMT.DATE);
      let theTimeStart = mDateTime.format(moment.HTML5_FMT.TIME);
      let theTimeEnd = mDateTime.add(storeCalendarView.nuIntervalMins||15, 'minutes').format(moment.HTML5_FMT.TIME);
      rf_date.current.setVal(theDate);
      rf_time_start.current.setVal(theTimeStart);
      rf_time_end.current.setVal(theTimeEnd);
      // columna
      rf_txTypeCalendarCol.current.setVal(storeCalendarEvent.txTypeCalendarCol);
      rf_txTypeCalendarCol.current.setKey(storeCalendarEvent.itCalendarCol);
      // grupo
      //rf_txTypeCalendarGrp.current.setKeyAndVal('t_calendar_grp', 'txTypeCalendarGrp', 'itCalendarGrp', '000001.SRV');
    } else {
      // evento existente
      let record = {};
      record['idValue'] = storeCalendarEvent.inCalendar;
      // enviamos también los settings
      record['boSingleCalendar'] = storeCalendarView.boSingleCalendar;
      record['txWeekDays'] = storeCalendarView.txWeekDays;
      record['txStartHour'] = storeCalendarView.txStartHour;
      record['txWorkTime1From'] = storeCalendarView.txWorkTime1From;
      record['txWorkTime1To'] = storeCalendarView.txWorkTime1To;
      record['txWorkTime2From'] = storeCalendarView.txWorkTime2From;
      record['txWorkTime2To'] = storeCalendarView.txWorkTime2To;

      Promise.all([FxFetch.doJsonX("cal/r", record)]).then((result) => {
        if (result[0]) {
          // guardamos cliente y paciente en store
          storeClient.inClient = result[0][0].inClient;
          storeClient.txClient = result[0][0].txClient;
          if ((isApp('vett') || isApp('spaw'))) {
            storeClient.inAnimal = result[0][0].inAnimal;
            storeClient.txAnimal = result[0][0].txAnimal;
          }
          setStoreClient(storeClient);

          let theDate = result[0][0].dtFrom.substring(0, 10);
          let dtFrom = result[0][0].dtFrom;
          let dtTo = result[0][0].dtTo;
          if (dtFrom !== null) {
            dtFrom = dtFrom.substring(11, 16);
          }
          if (dtTo !== null) {
            dtTo = dtTo.substring(11, 16);
          }
          rf_date.current.setVal(theDate);
          rf_txTypeCalendarCol.current.setVal(result[0][0].txTypeCalendarCol);
          rf_txTypeCalendarCol.current.setKey(result[0][0].itCalendarCol);
          rf_txTypeCalendarGrp.current.setVal(result[0][0].txTypeCalendarGrp);
          rf_txTypeCalendarGrp.current.setKey(result[0][0].itCalendarGrp);
          rf_txClient.current.setKey(result[0][0].inClient);
          rf_txClient.current.setVal(result[0][0].txClient);
          rf_time_start.current.setVal(dtFrom);
          rf_time_end.current.setVal(dtTo);
          rf_title.current.setVal(result[0][0].txRemarks)
          rf_notes.current.setVal(result[0][0].txNotes)
          if ((isApp('vett') || isApp('spaw'))) {
            rf_txAnimal.current.setKey(result[0][0].inAnimal);
            rf_txAnimal.current.setVal(result[0][0].txAnimal);
            // filtro de animales para el paciente seleccionado
            rf_txAnimal.current.setFilter(`n_animal.inClient='${result[0][0].inClient}'`);
          }
        }
      });
    }
  };

  const formDeleteCustom = () => {
    setDlgDelete(true);
  }
  const doDelete = () => {
    if (storeCalendarEvent.inCalendar) {
      setBackdrop(true);
      let url = "cal/d";
      let record = {};
      record['idValue'] = storeCalendarEvent.inCalendar;
      // enviamos también los settings
      record['boSingleCalendar'] = storeCalendarView.boSingleCalendar;
      record['txWeekDays'] = storeCalendarView.txWeekDays;
      record['txStartHour'] = storeCalendarView.txStartHour;
      record['txWorkTime1From'] = storeCalendarView.txWorkTime1From;
      record['txWorkTime1To'] = storeCalendarView.txWorkTime1To;
      record['txWorkTime2From'] = storeCalendarView.txWorkTime2From;
      record['txWorkTime2To'] = storeCalendarView.txWorkTime2To;
      Promise.all([FxFetch.doJsonX(url, record)]).then((result) => {
        if (result[0]) {
        } else {
        }
        r_snack_delete.current.setOpen(true);
      });
    }
  };

  const formSaveCustom = () => {
    if (!isRequired()) {
      // guardamos cliente y paciente en store
      storeClient.inClient = rf_txClient.current.getKey();
      storeClient.txClient = rf_txClient.current.getVal();
      if ((isApp('vett') || isApp('spaw'))) {
        storeClient.inAnimal = rf_txAnimal.current.getKey();
        storeClient.txAnimal = rf_txAnimal.current.getVal();
      }
      setStoreClient(storeClient);

      // guardamos evento en db
      let filter = { "inCalendar": storeCalendarEvent.inCalendar, };
      let record = {};
      record['t_calendar_col.txTypeCalendarCol'] = rf_txTypeCalendarCol.current.getVal();
      record['n_calendar.itCalendarCol'] = rf_txTypeCalendarCol.current.getKey();
      record['t_calendar_grp.txTypeCalendarGrp'] = rf_txTypeCalendarGrp.current.getVal();
      record['n_calendar.itCalendarGrp'] = rf_txTypeCalendarGrp.current.getKey();
      record['n_calendar.inClient'] = rf_txClient.current.getKey();
      record['n_calendar.dtFrom'] = `${rf_date.current.getVal()} ${rf_time_start.current.getVal()}:00`;
      record['n_calendar.dtTo'] = `${rf_date.current.getVal()} ${rf_time_end.current.getVal()}:00`;
      record['n_calendar.txRemarks'] = rf_title.current.getVal();
      record['n_calendar.txNotes'] = rf_notes.current.getVal();
      // enviamos también los settings
      record['boSingleCalendar'] = storeCalendarView.boSingleCalendar;
      record['txWeekDays'] = storeCalendarView.txWeekDays;
      record['txStartHour'] = storeCalendarView.txStartHour;
      record['txWorkTime1From'] = storeCalendarView.txWorkTime1From;
      record['txWorkTime1To'] = storeCalendarView.txWorkTime1To;
      record['txWorkTime2From'] = storeCalendarView.txWorkTime2From;
      record['txWorkTime2To'] = storeCalendarView.txWorkTime2To;

      if ((isApp('vett') || isApp('spaw'))) {
        record['n_calendar.inAnimal'] = rf_txAnimal.current.getKey();
      }

      //setBackdrop(true);
      Promise.all([FxFetch.doJsonF("cal/cu", filter, record)]).then((result) => {
        //setBackdrop(false);
        if (result[0]) {
          if (result[0].theValue === 'rv_overlap') {
            r_snack_overlap.current.setOpen(true);
          } else {
            storeCalendarEvent.inCalendar = result[0].theValue;
            setStoreCalendarEvent(storeCalendarEvent);
            r_snack_save.current.setOpen(true);
          }
        }
      });
    }
  };

  const isRequired = () => {
    let rv = false;
    let cc = [rf_txTypeCalendarCol, rf_txTypeCalendarGrp, rf_date, rf_time_start, rf_time_end, rf_title];
    cc.reverse().forEach((c, index) => {
      if (c.current.getIsRequired()) {
        let label = c.current.getLabel();
        r_snack_required.current.setVal(trans('field.required') + ": " + label);
        r_snack_required.current.setOpen(true);
        rv = true;
      }
    });
    return rv;
  };

  const clientChanged = (inClient, txClient) => {
    if (inClient !== null) {
      // inyecta filtros en el selector paciente
      if (isApp('vett') || isApp('spaw')) {
        rf_txAnimal.current.setFilter(`n_animal.inClient='${inClient}'`);
        rf_txAnimal.current.clear();
      }
    }
  }

  const doInfo = () => {
    let inClient = rf_txClient.current.getKey();
    if (inClient) {
      let record = {};
      record['idValue'] = inClient;
      Promise.all([FxFetch.doJsonF("clients/r", null, record)]).then((result) => {
        if (result[0]) {
          setInfo(result[0][0]);
          setDlgInfo(true);
        }
      });
    } else {
      r_snack_error.current.setVal(trans('msg.select_x', 'field.client'));
      r_snack_error.current.setOpen(true);
    }
  }

  const openCommForm = () => {
    if (storeCalendarEvent.inCalendar) {
      storeComm.inComm = null;
      storeComm.inClient = rf_txClient.current.getKey();
      if ((isApp('vett') || isApp('spaw'))) {
        storeComm.inAnimal = rf_txAnimal.current.getKey();
      }
      storeComm.dtFrom = rf_date.current.getVal();
      setStoreComm(storeComm);
      navTo("comm-form");
    } else {
      r_snack_ask_save.current.setOpen(true);
    }
  }

  const selectedClientOTF = (inClient, txClient) => {
    try {
      rf_txClient.current.setKey(inClient);
      rf_txClient.current.setVal(txClient);
      clientChanged(inClient, txClient);

    } catch (error) {
      FxLog.errorx('MxCalendarForm.selectedClientOTF', error)
    }
  }

  const selectedAnimalOTF = (inAnimal, txAnimal) => {
    try {
      rf_txAnimal.current.setKey(inAnimal);
      rf_txAnimal.current.setVal(txAnimal);

    } catch (error) {
      FxLog.errorx('MxCalendarForm.selectedAnimalOTF', error)
    }
  }

  const openFormAnimal = () => {
    if (!storeClient.inAnimal) {
      r_snack_error.current.setValAndOpen(trans('msg.save_x_before', 'field.patient'));
    } else {
      storeClient.inClient = rf_txClient.current.getKey();
      storeClient.txClient = rf_txClient.current.getVal();
      storeClient.inAnimal = rf_txAnimal.current.getKey();
      storeClient.txAnimal = rf_txAnimal.current.getVal();
      setStoreClient(storeClient);
      navTo("animal-form");
    }
  }

  return (
    <div>
      <GxForm
        moduleName={`${trans('field.calendar')}: ${trans('field.event')}`}
        xref={r_form}
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <CxIconButton type="info" classType="ibs_iconbutton_toolbar" onClick={() => doInfo()} tooltip={trans('field.additional_information')} />
            <GxIconButtonLastClient theInput={rf_txClient} onClientChanged={clientChanged} />
            {isFullAccess() && <CxIconButton type="comms" classType="ibs_iconbutton_toolbar" onClick={openCommForm} tooltip={trans('field.comm')} />}
            {(isApp('vett') || isApp('spaw')) && <CxIconButtonNew icon={<MdOutlinePets fontSize={23} />} classType="ibs_iconbutton_toolbar" onClick={openFormAnimal} tooltip={trans('field.patient')} />}
            {isFullAccess() && <CxIconButton type="delete" classType="ibs_iconbutton_toolbar" onClick={() => formDeleteCustom()} />}
            {isFullAccess() && <CxIconButton type="save" classType="ibs_iconbutton_toolbar" onClick={() => formSaveCustom()} />}
          </div>
        }
      >
        <CxDate xref={rf_date} label={trans('field.date')} required />
        <CxTime xref={rf_time_start} label={trans('field.from')} required />
        <CxTime xref={rf_time_end} label={trans('field.to')} required />

        <CxChooser xref={rf_txTypeCalendarCol} label={trans('field.column')}
          dbfield="t_calendar_col.txTypeCalendarCol" dbfieldkey={`n_calendar.itCalendarCol`}
          table="t_calendar_col"
          col_id="itCalendarCol"
          col_tx="txTypeCalendarCol"
          bottomLegend={`${trans('msg.setup_new_1')}: ${trans('field.settings')} > ${trans('field.options')} > ${trans('field.calendars')} > ${trans('field.columns')}`}
          required
        />

        <CxChooser xref={rf_txTypeCalendarGrp} label={trans('field.group')}
          dbfield="t_calendar_grp.txTypeCalendarGrp" dbfieldkey={`n_calendar.itCalendarGrp`}
          table="t_calendar_grp"
          col_id="itCalendarGrp"
          col_tx="txTypeCalendarGrp"
          extra_column_field_1="txRgb"
          extra_column_value_1={{
            id: "2", Header: trans('field.color'), accessor: row => {
              return <CxIconButtonNew icon={<FaSquareFull fontSize="20px" style={{ color: FxStr.rgb2hex(row["txRgb"]), border: '1px solid gray' }} />} />
            }, width: '20%', align: 'center'
          }}
          bottomLegend={`${trans('msg.setup_new_1')}: ${trans('field.settings')} > ${trans('field.options')} > ${trans('field.calendars')} > ${trans('field.groups')}`}
          required
        />

        <CxInput xref={rf_title} label={trans('field.event')} maxLength={150} speech required />

        <CxSearcher xref={rf_txClient} label={trans('field.client')}
          onClear={() => { if (rf_txAnimal.current) rf_txAnimal.current.clear(); }}
          onButtonAddOTF={() => {
            rf_txClient.current.setDlg(false);
            r_dlg_new_client.current.setDlg(true);
          }}
          onSelection={(row) => clientChanged(row.original.inClient, row.original.txClient)}
          url="clients/searcher"
          col_id="inClient"
          col_tx="txClient"
          colorRefs={['table_cell_red|' + trans('field.debtor')]}
          tooltip={`${trans('field.client')}`}
          columns={[
            { id: "0", Header: "ID", accessor: "inClient", show: false },
            { id: "1", Header: trans('field.client'), accessor: row => rf_txClient.current.getTable().highlightText(row["txClient"]), width: '100%' },
            { id: "R", Header: "balance", accessor: row => Boolean((row["theBalance"]) < 0 ? true : false), show: false },
          ]}
        />
        {(isApp('vett') || isApp('spaw')) &&
          <CxSearcher xref={rf_txAnimal} 
            label={trans('field.patient')}
            url="animals"
            col_id="inAnimal"
            col_tx="txAnimal"
            canOpenDialog={() => { return rf_txClient.current.getKey() !== ''; }}
            tooltip={`${trans('field.patient')}`}
            columns={[
              { id: "0", Header: "ID", accessor: "inAnimal", show: false },
              { id: "1", Header: trans('field.patient'), accessor: row => rf_txAnimal.current.getTable().highlightText(row["txAnimal"]), width: '100%' },
              { id: "A", Header: "inactive", accessor: row => FxDat.isExpired(row["dtTo"]), show: false },
            ]}
            colorRefs={[
              'table_cell_gray|' + trans('field.inactive'),
            ]}
            onButtonAddOTF={() => {
              let inClient = rf_txClient.current.getKey();
              if (inClient !== '') {
                rf_txAnimal.current.setDlg(false);
                r_dlg_new_animal.current.setInClient(inClient);
                r_dlg_new_animal.current.setDlg(true);
              }
            }}
          />
        }

        <CxInput xref={rf_notes} label={trans('field.notes')} minRows={6} speech />

      </GxForm>

      <CxSnackbar xref={r_snack_required} severity="error" />
      <CxSnackbar xref={r_snack_save} severity="success" value={trans('msg.saved')} /* onClose={() => navBack()} no salimos xq puede querer guardar un recordatorio */ />
      <CxSnackbar xref={r_snack_delete} severity="success" value={trans('msg.deleted')} onClose={() => navBack()} />
      <CxSnackbar xref={r_snack_overlap} severity="error" value={trans('msg.time_overlap')} />

      <CxDialog title={trans('field.information')} open={dlgInfo}
        onOk={() => setDlgInfo(false)}
        width='400px' height='400px'>
        {info && <div style={{ width: '250px', }}>
          {info.txMobile && <div>
            <p style={{ height: '5px', fontSize: '12px' }}>{trans('field.mobile') + ":"}</p>
            <p><a href={`tel:${info.txMobile}`}><b>{info.txMobile}</b></a></p>
          </div>}
          {info.txPhone1 && <div>
            <p style={{ height: '5px', fontSize: '12px' }}>{trans('field.phone1') + ":"}</p>
            <p><a href={`tel:${info.txPhone1}`}><b>{info.txPhone1}</b></a></p>
          </div>}
          {info.txPhone2 && <div>
            <p style={{ height: '5px', fontSize: '12px' }}>{trans('field.phone2') + ":"}</p>
            <p><a href={`tel:${info.txPhone2}`}><b>{info.txPhone2}</b></a></p>
          </div>}
          {info.txEmail && <div>
            <p style={{ height: '5px', fontSize: '12px' }}>{trans('field.email') + ":"}</p>
            <p><a href={`mailto:${info.txEmail}`}><b>{info.txEmail}</b></a></p>
          </div>}
        </div>}
      </CxDialog>


      <CxDialog title={trans('msg.warning')} open={dlgDelete}
        onCancel={() => setDlgDelete(false)}
        onOk={() => { setDlgDelete(false); doDelete() }}
        height='200px'>
        {trans('msg.ask_delete')}
      </CxDialog>

      <CxSnackbar xref={r_snack_ask_save} severity="error" value={trans('msg.save_before')} />
      <CxSnackbar xref={r_snack_error} severity="error" />

      <MxClientOTF xref={r_dlg_new_client} onOk={selectedClientOTF} />
      {(isApp('vett') || isApp('spaw')) && <MxAnimalOTF xref={r_dlg_new_animal} onOk={selectedAnimalOTF} />}

      <CxBackdrop open={backdrop} />
    </div>
  );

}

export default MxCalendarForm;
