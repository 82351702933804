// funciones para log.

// archivos donde mostrar info en formato 'NombreArchivo.NombreFuncion'
const filesIncluded = [];

export const infox = async (fileNameAndFxName, message, object) => {
  if (filesIncluded.includes(fileNameAndFxName) || filesIncluded.includes(fileNameAndFxName.split('.')[0])) {
    console.log(" ");
    console.log(fileNameAndFxName + ": " + (message||''), object||'');
  }
}

export const errorx = async (fileNameAndFxName, object) => {
  console.log(" ");
  console.error(fileNameAndFxName, object||'');
}
