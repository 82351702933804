import React, { useImperativeHandle } from "react";
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../fx/FxLog';
import * as FxPrint from '../fx/FxPrint';
import '../../styles/main.css';
import CxTable from './CxTable';
import { useBlob } from '../hx/useBlob';
import { useTranslation } from '../hx/useTranslation';
import CxIconButton from './CxIconButton';
import CxSnackbar from './CxSnackbar';
import { clearStore, StoreFile, } from "../gx/GxStore";
import { useSessionStorage } from '../hx/useSessionStorage';
import { useNav } from '../hx/useNav';


const CxTableAttachmentBlob = props => {
  const [{ trans }] = useTranslation();
  const { title, fileFilter } = props;
  const [{ navTo }] = useNav();
  const [{ _blobLoad, _blobSave, _blobDelete, _blobGetBase64 }] = useBlob(props.url, props.fld_inXxx, props.fld_inXxxBlob, props.fld_txXxxBlob);
  const [storeFile, setStoreFile] = useSessionStorage('StoreFile', StoreFile);

  const [lockPrint, setLockPrint] = React.useState(true);
  const [lockDownload, setLockDownload] = React.useState(true);
  const [lockDelete, setLockDelete] = React.useState(true);

  const [inXxx, setInXxx] = React.useState(props.inXxx);

  const [inClient, setInClient] = React.useState(props.inClient);
  const [inSupplier, setInSupplier] = React.useState(props.inSupplier);
  const [inLab, setInLab] = React.useState(props.inLab);

  const r_table_file = React.useRef(null);
  const rf_inputfile = React.useRef(null);

  const r_snack_ask_save = React.useRef(null);

  useImperativeHandle(props.xref, () => {
    return {
      getType, setInXxx, setInClient, setInSupplier, setInLab, isEmpty, 
    };
  });
  const getType = () => { return 'CxTableAttachmentBlob' };
  const isEmpty = () => { return r_table_file.current.isEmpty() };

  const blobPrint = async (originalRow) => {
    let inXxxBlob = originalRow.inXxxBlob;
    let txXxxBlob = originalRow.txXxxBlob;
    Promise.all([_blobLoad(inXxxBlob, txXxxBlob)]).then((result) => {
      let txFileFormat = result[0].txFileFormat;
      let txFileNameInput = result[0].txFileNameInput;
      let obPDF = `${result[0].prefix}${result[0].obFile}`;

      clearStore('StoreFile', storeFile);
      Promise.all([FxPrint.getClientInfo(inClient), FxPrint.getSupplierInfo(inSupplier), FxPrint.getLabInfo(inLab)]).then((info) => {
        let clientInfo = info[0];
        let supplierInfo = info[1];
        let labInfo = info[2];
        storeFile.addressee = clientInfo || supplierInfo || labInfo;
        storeFile.txFileFormat = txFileFormat;
        storeFile.txFileNameInput = txFileNameInput;
        storeFile.obPDF = obPDF;
        storeFile._send = true; //falla en GxViewer.doSend()

        Promise.all([setStoreFile(storeFile)]).then((result) => {
          navTo("file-viewer");
        });
      });
    });
  }
  const blobDownload = async (originalRow) => {
    let inXxxBlob = originalRow.inXxxBlob;
    let txXxxBlob = originalRow.txXxxBlob;
    Promise.all([_blobLoad(inXxxBlob, txXxxBlob)]).then((result) => {
      let rv = `${result[0].prefix}${result[0].obFile}`;
      const a = document.createElement('a');
      a.download = txXxxBlob;
      a.href = rv;
      a.click();
    });
  }
  const blobDelete = async (originalRow) => {
    Promise.all([_blobDelete(originalRow.inXxxBlob)]).then((result) => {
      r_table_file.current.redisplay();
    });
  }
  const blobCreate = async (op, row) => {
    if (row === null) { //nuevo
      rf_inputfile.current.click();
      /* // 2024-05-02 - daq: Esto fué necesario hasta que hicimos que pregrabe los blobs con un random id (FxStr.getRandomID).
      if (inXxx) {
        rf_inputfile.current.click();
      } else {
        r_snack_ask_save.current.setOpen(true);
      }
      */
    }
  }
  const onSelectFile = (event) => {
    event.stopPropagation();
    event.preventDefault();
    var file = event.target.files[0];
    if (file) {
      if (file.name.toLowerCase().endsWith('.png') || file.name.toLowerCase().endsWith('.jpg') || file.name.toLowerCase().endsWith('.jpeg') || file.name.toLowerCase().endsWith('.pdf')) {
        Promise.all([_blobGetBase64(file)]).then((result) => {
          let strBase64 = result[0];
          Promise.all([_blobSave(inXxx, file.name, strBase64)]).then((result) => {
            Promise.all([r_table_file.current.setFilter(`${props.table}.${props.fld_inXxx}='${inXxx || ''}'`)]).then((result) => {
              r_table_file.current.redisplay();
            });
          });
        });
      }
      clear();
    }
  }
  const clear = () => {
    rf_inputfile.current.value = '';
  };

  return (
    <div style={{ margin: props.margin }}>

      <CxTable
        xref={r_table_file}
        title={title || trans('field.attachments')}
        url={`${props.url}/blob`}
        filter={`${props.table}.${props.fld_inXxx}='${inXxx || ''}'`}
        onOpenForm={blobCreate}
        hasButtonAdd={true}
        disabled={props.disabled||false}
        cursorDefault
        columns={[
          { id: "0", Header: "ID", accessor: "inXxxBlob", show: false },
          { id: "1", Header: trans('field.file'), accessor: "txXxxBlob", width: '70%' },
          {
            id: "BTN_PRT", iconID: lockPrint ? "lock" : "unlock", iconTooltip: trans('field.column_lock_unlock'), onIconClick: () => setLockPrint(!lockPrint),
            accessor: (originalRow, rowIndex) => { return originalRow["txXxxBlob"].includes('.pdf') && <CxIconButton type="print" classType="ibs_iconbutton_table" disabled={lockPrint} onClick={() => blobPrint(originalRow)} tooltip={trans('field.print')} /> },
            align: 'center', width: '10%', show: Boolean(props.printable)
          },
          {
            id: "BTN_DNL", iconID: lockDownload ? "lock" : "unlock", iconTooltip: trans('field.column_lock_unlock'), onIconClick: () => setLockDownload(!lockDownload),
            accessor: (originalRow, rowIndex) => { return <CxIconButton type="download" classType="ibs_iconbutton_table" disabled={lockDownload} onClick={() => blobDownload(originalRow)} tooltip={trans('field.download')} /> },
            align: 'center', width: '10%'
          },
          {
            id: "BTN_DEL", iconID: lockDelete ? "lock" : "unlock", iconTooltip: trans('field.column_lock_unlock'), onIconClick: () => setLockDelete(!lockDelete),
            accessor: (originalRow, rowIndex) => { return <CxIconButton type="delete" classType="ibs_iconbutton_table" disabled={lockDelete} onClick={() => blobDelete(originalRow)} tooltip={trans('field.delete')} /> },
            align: 'center', width: '10%', show: !props.disabled
          },
        ]}
      />
      <input type="file" accept={fileFilter || "image/jpeg, image/jpg, image/png, .pdf"} ref={rf_inputfile} style={{ display: "none" }} onChange={onSelectFile} />

      <CxSnackbar xref={r_snack_ask_save} severity="error" value={trans('msg.save_before')} />

    </div>
  );
};

export default CxTableAttachmentBlob;
