import React from 'react';
import { useTranslation } from '../hx/useTranslation';
import * as FxFetch from '../fx/FxFetch';
import * as FxLog from '../fx/FxLog';
import * as FxTable from '../fx/FxTable';
import CxTable from '../cx/CxTable';
import CxHr from '../cx/CxHr';
import CxSnackbar from '../cx/CxSnackbar';
import CxLink from '../cx/CxLink';
import { useSessionStorage } from '../hx/useSessionStorage';
import { StoreClient, StoreSlsAux, StoreSlsLst, StoreSlsDoc, clearStore, isApp, } from "./GxStore";
import { useNav } from '../hx/useNav';
import { useWindowSize } from '../hx/useWindowSize';
import { useLocation } from 'react-router-dom';


const GxSlsAuxTable = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navTo }] = useNav();
  const { state } = useLocation(); //captura los parámetros de navBack()
  const size = useWindowSize();

  const [storeClient,] = useSessionStorage('StoreClient', StoreClient);
  const [storeSlsAux, setStoreSlsAux] = useSessionStorage('StoreSlsAux', StoreSlsAux);
  const [storeSlsLst, setStoreSlsLst] = useSessionStorage('StoreSlsLst', StoreSlsLst);
  const [storeSlsDoc, setStoreSlsDoc] = useSessionStorage('StoreSlsDoc', StoreSlsDoc);

  const rf_table_pss = React.useRef(null);
  const r_snack_ask_save = React.useRef(null);

  // eslint-disable-next-line
  const [strRelatedField, setStrRelatedField] = React.useState(props.strRelatedField);
  // eslint-disable-next-line
  const [strRelatedId, setStrRelatedId] = React.useState(props.strRelatedId);

  const [linkMoveDisabled, setLinkMoveDisabled] = React.useState(false);


  React.useEffect(() => {
    clearStore('StoreSlsAux', storeSlsAux);

    loadRates('cli', storeClient.inClient, storeClient.txClient);
    if ((isApp('vett') || isApp('spaw')) && storeClient.inAnimal) {
      loadRates('ani', storeClient.inAnimal, storeClient.txAnimal);
    }

    // eslint-disable-next-line
  }, []);

  React.useImperativeHandle(props.xref, () => {
    return {
      setStrRelatedId,
    };
  });

  const doMoveToSls = () => {
    let record = {};
    record['pageNo'] = '0';
    record['pageSize'] = '1000';

    record['filter'] = `n_invoice_item_x.${strRelatedField}='${strRelatedId}'`;

    Promise.all([FxFetch.doJsonX("sls/aux", record)]).then((result) => {
      FxLog.infox("GxSlsAuxTable.doMoveToSls", "...result[0]:", result[0]);
      if (result[0]) {
        FxLog.infox("GxSlsAuxTable.doMoveToSls", "creando nuevo...");
        clearStore('StoreSlsLst', storeSlsLst);
        clearStore('StoreSlsDoc', storeSlsDoc);

        storeSlsLst.listType = 'i';
        storeSlsLst.listTypeBackground = 'var(--ibs_color_blue_light)';
        storeSlsLst.table = 'n_invoice';
        storeSlsLst.table_idField = 'inInvoice';
        storeSlsLst.table_txField = 'txInvoice';
        storeSlsLst.table_dtField = 'dtInvoice';
        storeSlsLst.table_item = 'n_invoice_item';
        storeSlsLst.table_item_idField = 'inInvoiceItem';
        storeSlsLst.table_paym_idField = 'inPayment';
        setStoreSlsLst(storeSlsLst);

        storeSlsDoc.boFirstRender = true;
        storeSlsDoc.boDataChanged = '0';
        storeSlsDoc.docType = 'i';
        storeSlsDoc.inXXX = null;

        storeSlsDoc.inClient = storeClient.inClient;
        storeSlsDoc.txClient = storeClient.txClient;
        if ((isApp('vett') || isApp('spaw'))) {
          storeSlsDoc.inAnimal = storeClient.inAnimal;
          storeSlsDoc.txAnimal = storeClient.txAnimal;
        }
        if (storeSlsDoc.inEmployee) {
          FxLog.infox("GxSlsAuxTable.doMoveToSls", "...rf_txEmployee.current.getVal():", props.rf_txEmployee.current.getVal());
          storeSlsDoc.inEmployee = props.rf_txEmployee.current.getKey();
          storeSlsDoc.txEmployee = props.rf_txEmployee.current.getVal();
        }

        // items
        var items = storeSlsDoc.arItems || [];
        for (let i = 0; i < result[0].length; i++) {
          var item = {
            idx: i,
            inXxxItem: result[0][i].inInvoiceItem,
            inPs: result[0][i].inPs,
            txPs: result[0][i].txPs,
            txLotCode: hasLots(result[0][i].hasLots),

            nuQuantity: result[0][i].nuQuantity,
            nuPrice: result[0][i].nuPrice,
            nuTaxable: result[0][i].nuTaxable,
            nuTaxPerc: result[0][i].nuTaxPerc,
            nuTax: result[0][i].nuTax,
            nuDiscountPerc: result[0][i].nuDiscountPerc,
            nuDiscount: result[0][i].nuDiscount,
            nuAmount: result[0][i].nuAmount,

            txPsAlias: result[0][i].txPsAlias,
            txAlert: result[0][i].txAlert,
            txRemarks: result[0][i].txRemarks,
          };
          items.push(item);
        }
        storeSlsDoc.arItems = items;

        Promise.all([setStoreSlsDoc(storeSlsDoc)]).then((result) => {
          navTo("sls-doc-form");
        });
      }
    });
  }

  const hasLots = (psHasLots) => {
    if (psHasLots === '1') {
      /* DAQ 2022.05.11 - indica a MxSlsDocForm.doLots() que este ps está pendiente de añadir lote */
      return '**pending**';
    }
    return '';
  }

  const onFetchPs = () => {
    if (state && state.scroll === 'bottom') {
      window.scrollTo(0, document.body.scrollHeight);
    }
    setLinkMoveDisabled(rf_table_pss.current.isEmpty())
  }

  const openFormAuxItem = async (op, row) => {
    if (!strRelatedId) {
      r_snack_ask_save.current.setOpen(true);
    } else {
      if (row === null) {
        storeSlsAux.inInvoiceItemX = null;
      } else {
        storeSlsAux.inInvoiceItemX = row.original.inInvoiceItemX;
      }
      storeSlsAux.inClient = storeClient.inClient;
      storeSlsAux.strRelatedField = strRelatedField;
      storeSlsAux.strRelatedId = strRelatedId;
      Promise.all([setStoreSlsAux(storeSlsAux)]).then((result) => {
        navTo("sls-aux-item");
      });
    }
  }

  const loadRates = (who, inXXX, txXXX) => {
    try {
      let record = {};
      record['who'] = who;
      record['inXXX'] = inXXX;
      Promise.all([FxFetch.doJsonX(`sls/doc/rates`, record)]).then((result) => {
        if (result[0]) {
          var rates = [result[0]];
          storeSlsAux.arRates = rates;
        } else {
          storeSlsAux.arRates = '';
        }
        setStoreSlsAux(storeSlsAux);
      });

    } catch (error) {
      FxLog.errorx("GxSlsAuxTable.loadRates", error);
    }
  }

  return (
    <div style={{ margin: '20px var(--ibs_container_margin) 50px var(--ibs_container_margin)' }}>
      <CxHr title={trans('field.pss')} >
        <CxLink label={`[${trans('field.copy_to_x', 'field.sales').toUpperCase()}]`} style={{ margin: '0 0 0 1px', fontSize: '12px' }} onClick={doMoveToSls} disabled={linkMoveDisabled} />
      </CxHr>
      <CxTable
        xref={rf_table_pss}
        url="sls/aux"
        filter={`n_invoice_item_x.${strRelatedField}='${strRelatedId}'`}
        onOpenForm={openFormAuxItem}
        onFetchPage={onFetchPs}
        hasButtonAdd={true}
        columns={[
          { id: "inInvoiceItemX", Header: "ID", accessor: "inInvoiceItemX", show: false },
          { id: "txPs", Header: trans('field.ps'), accessor: "txPs", width: '35%' },
          { id: "txTypePsGroup", Header: trans('field.group'), accessor: "txTypePsGroup", width: '25%', show: size.isLarge },
          { id: "txTypePsSubgroup", Header: trans('field.subgroup'), accessor: "txTypePsSubgroup", width: '25%', show: size.isLarge },
          { id: "nuAmount", Header: trans('field.amount'), accessor: row => (FxTable.cellTo2r00(row["nuAmount"])), align: 'right', width: '15%' },
        ]}
      />

      <CxSnackbar xref={r_snack_ask_save} severity="error" value={trans('msg.save_before')} />

    </div>
  );
}

export default GxSlsAuxTable;
