import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from '../../hx/useTranslation';
import '../../../styles/main.css';
import CxToolbar from '../../cx/CxToolbar';
import CxSnackbar from '../../cx/CxSnackbar';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import * as FxFile from '../../fx/FxFile';
//import * as FxDat from '../../fx/FxDat';
import { StoreMarketing, isApp } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import { StoreLongTask, clearStore } from "../../gx/GxStore";
import { useNav } from '../../hx/useNav';
import CxInput from '../../cx/CxInput';
import CxInputTemplate from '../../cx/CxInputTemplate';
import CxIconButton from '../../cx/CxIconButton';
import CxFile from '../../cx/CxFile';


const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'flex-start',
    gap: 'var(--ibs_container_gap)',
    margin: 'var(--ibs_container_margin)',
  },
  button_frame: {
    margin: '20px 0 0 0',
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'flex-start',
    gap: '12px',
    width: '100%',
  },
}));

const MxMarketingOptionE = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navTo }] = useNav();
  const classes = useStyles(props);

  const rf_txSubject = React.useRef(null);
  const rf_txContent = React.useRef(null);
  const rf_file = React.useRef(null);

  const [templateName, setTemplateName] = React.useState('');
  
  const r_snack_error = React.useRef(null);
  
  const [storeLongTask, setStoreLongTask] = useSessionStorage('StoreLongTask', StoreLongTask);

  // eslint-disable-next-line
  const [storeMarketing, setStoreMarketing] = useSessionStorage('StoreMarketing', StoreMarketing);

  React.useEffect(() => {
    clearStore('StoreLongTask', storeLongTask);
    formLoad();
    // eslint-disable-next-line
  }, []);

  const formLoad = async () => {
  }

  const doTemplateSelected = (name, cont) => {
    FxLog.infox('MxMarketingOptionE.doTemplateSelected', '...', name +' - '+ cont);
    setTemplateName(name);
    rf_txSubject.current.setVal(name);
    rf_txContent.current.setVal(cont);
  }

  const sendAll = () => {
    try {
      if (rf_txSubject.current.isEmpty()) {
        r_snack_error.current.setVal(trans('field.required') + ': ' + trans('field.subject'));
        r_snack_error.current.setOpen(true);
        return;
      }
      if (rf_txContent.current.isEmpty()) {
        r_snack_error.current.setVal(trans('field.required') + ': ' + trans('field.content'));
        r_snack_error.current.setOpen(true);
        return;
      }
      
      // crea la tarea.
      if (rf_file.current.isEmpty()) {
        setLongTask();
        Promise.all([setStoreLongTask(storeLongTask)]).then((result) => {
          navTo('marketing-option-tsk');
        });

      } else {
        // tiene adjunto
        let file = rf_file.current.getFile();
        Promise.all([FxFile.getBase64FromFile(file)]).then((result) => {
          const base64Prefix = 'data:application/pdf;base64,';
          const base64KeyStore = result[0].replace(base64Prefix, '');
          setLongTask(base64KeyStore);
          Promise.all([setStoreLongTask(storeLongTask)]).then((result) => {
            navTo('marketing-option-tsk');
          });
        });
      }

    } catch (error) {
      FxLog.errorx("MxMarketingOptionE.sendAll", error);
    }
  }

  const setLongTask = (base64KeyStore) => {
    storeLongTask._url = 'marketing/send';
    storeLongTask._canCancel = true;
    storeLongTask._record = { 
      txTaskName: "marketing", 
      op: 'E', 
      templateName: templateName,
      txSubject: rf_txSubject.current.getVal(),
      txContent: rf_txContent.current.getVal(),
      arListedId: storeMarketing.arListedId,
      base64: base64KeyStore||'',
    };
  }

  const getPlaceholders = () => {
    let ph = [];
    if (isApp('vett') || isApp('spaw')) {
      ph.push({ id: "[name]", type: trans('field.name') });
      ph.push({ id: "[first_name]", type: trans('field.name_first') });
      ph.push({ id: "[last_name]", type: trans('field.name_last') });
      ph.push({ id: "[contact]", type: trans('field.contact') });
      ph.push({ id: "[animal]", type: trans('field.patient') });
      if (storeMarketing.arPssFq) {
        ph.push({ id: "[ps]", type: trans('field.vacc') + '-' + trans('field.name') });
        ph.push({ id: "[next]", type: trans('field.vacc') + '-' + trans('field.next') });
      }
      return ph;
    }
    if (isApp('opti')) {
      ph.push({ id: "[name]", type: trans('field.name') });
      ph.push({ id: "[first_name]", type: trans('field.name_first') });
      ph.push({ id: "[last_name]", type: trans('field.name_last') });
      ph.push({ id: "[contact]", type: trans('field.contact') });
      if (storeMarketing.arPssFq) {
        ph.push({ id: "[ps]", type: trans('field.repl') + '-' + trans('field.name') });
        ph.push({ id: "[next]", type: trans('field.repl') + '-' + trans('field.next') });
      }
      return ph;
    }
    if (isApp('invo')) {
      ph.push({ id: "[name]", type: trans('field.name') });
      ph.push({ id: "[first_name]", type: trans('field.name_first') });
      ph.push({ id: "[last_name]", type: trans('field.name_last') });
      ph.push({ id: "[contact]", type: trans('field.contact') });
      return ph;
    }
  }

  return (
    <div>
      <CxToolbar 
        moduleName={`${trans('field.marketing')}: ${trans('field.options')}: ${trans('field.email')}`} 
        background={storeMarketing.marketingType==='E' ? 'var(--ibs_color_red_light)' : 'var(--ibs_color_yellow_light)'}
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <CxIconButton type="chevron_right" classType="ibs_iconbutton_toolbar" onClick={() => sendAll()} tooltip={trans('field.next')} />
          </div>
        }
      />

      <div className={classes.container}>

        <CxInput xref={rf_txSubject} label={trans('field.subject')} maxLength={200} required />
        <CxFile xref={rf_file} label={`${trans('field.attachment')} - PDF`} accept='application/pdf'
          /*onLoad={() => {
            let fileName = localStorage.getItem('CxDlgCertificate.certificate_name');
            let base64File = localStorage.getItem('CxDlgCertificate.certificate_file');
            if (base64File) {
              Promise.all([FxFile.getFileFromBase64(base64File, fileName, 'application/x-pkcs12')]).then((result) => {
                let file = result[0];
                if (file) {
                  rf_file.current.setFile(file);
                  rf_file.current.setFileName(file.name);
                }
              });
            }
          }}*/
          //width='var(--ibs_control_width_md)'
        />

        <CxInputTemplate xref={rf_txContent} label={trans('field.content')} minRows={8} required
          tpl_table={"n_email_tpl"} tpl_col_id={"inEmailTpl"} tpl_col_tx={"txName"} tpl_filter={""} tpl_extra_filter_1={""} tpl_content_field={"txContent"}
          hasTplButtonAdd
          onTemplateSelected={doTemplateSelected}
          placeholders={getPlaceholders}
          speech
        />


      </div>

      <CxSnackbar xref={r_snack_error} severity="error" />

    </div>
  );

}

export default MxMarketingOptionE;
