import React, { useRef } from 'react';
import CxDate from '../../cx/CxDate';
import CxHidden from '../../cx/CxHidden';
import CxInput from '../../cx/CxInput';
import CxChooser from '../../cx/CxChooser';
import GxForm from '../../gx/GxForm';
import { useNav } from '../../hx/useNav';
import { useTranslation } from '../../hx/useTranslation';
// eslint-disable-next-line no-unused-vars
import CxSnackbar from '../../cx/CxSnackbar';
import CxStatic from '../../cx/CxStatic';
import { StoreClient, StoreCns, isApp } from "../../gx/GxStore";
import { useAccess } from '../../hx/useAccess';
import { useSessionStorage } from '../../hx/useSessionStorage';
import CxImageEditor from '../../cx/CxImageEditor';

const MxClientImageForm = (props) => {
  const [{ trans }] = useTranslation();
  const [{ isFullAccess }] = useAccess(trans('field.clients'));
  const [{ navBack }] = useNav();

  const [storeClient, setStoreClient] = useSessionStorage('StoreClient', StoreClient);
  const [storeCns, ] = useSessionStorage('StoreCns', StoreCns);

  let r_form = React.useRef(null);
  
  const rf_date = React.useRef(null);
  const rf_itImage = React.useRef(null);
  const rf_image = React.useRef(null);

  const r_snack_ask_save = React.useRef(null);


  React.useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const formLoad = async (data) => {
    if (data === null) { //nuevo registro
      rf_date.current.setValToday();
      rf_itImage.current.setKeyAndVal("t_image", "txTypeImage", "itImage", "000001.SRV");
    }
  }
  const formSave = async (lastSaveRv) => {
    // se guardó exitosamente
    storeClient.inImage = lastSaveRv.theValue;
    Promise.all([setStoreClient(storeClient)]).then((result) => {
      rf_image.current.doSave(lastSaveRv.theValue);
      navBack();
    });
  }
  const formDelete = async (rv) => {
  }

  return (
    <div>
      <GxForm
        moduleName={`${(isApp('vett') || isApp('spaw')) ? trans('field.patient') : trans('field.client')}: ${trans('field.image')}`}
        xref={r_form}
        url="images"
        filterIdField='inImage'
        filterIdValue={storeClient.inImage}
        onFormLoad={formLoad}
        onFormSave={isFullAccess() && formSave}
        onFormDelete={isFullAccess() && formDelete}
      >
        <CxHidden xref={useRef(null)} dbfield="n_image.inClient" value={storeClient.inClient || ''} />
        <CxHidden xref={useRef(null)} dbfield="n_image.inAnimal" value={storeClient.inAnimal || ''} />
        <CxHidden xref={useRef(null)} dbfield="n_image.inCns" value={storeCns.inCns || ''} />

        {(isApp('invo') || isApp('opti')) && <CxStatic label={`${storeClient.txClient}`} border='none' fontcolor='var(--ibs_color_blue)' fontsize='12px' />}
        {(isApp('vett') || isApp('spaw')) && <CxStatic label={`${storeClient.txClient} / ${storeClient.txAnimal}`} border='none' fontcolor='var(--ibs_color_blue)' fontsize='12px' />}

        <CxDate xref={rf_date} label={trans('field.date')} dbfield={`n_image.dtFrom`} required />
        
        <CxChooser xref={rf_itImage}
          label={trans('field.type')}
          dbfield="t_image.txTypeImage" dbfieldkey="n_image.itImage"
          table="t_image"
          col_id="itImage"
          col_tx="txTypeImage"
          required
        />

        <CxInput xref={useRef(null)} label={trans('field.remarks')} dbfield="n_image.txRemarks" minRows={4} speech />

        <CxImageEditor xref={rf_image} url='images' inXXX={storeClient.inImage} can_search pen_red pen_blue pen_yellow />

      </GxForm>

      <CxSnackbar xref={r_snack_ask_save} severity="error" value={trans('msg.save_before')} />

    </div>
  );

}

export default MxClientImageForm;
