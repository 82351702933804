import React, { useImperativeHandle } from "react";
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../fx/FxLog';

const CxLink = props => {
  const { onClick, ...rest } = props;
  const [show, setShow] = React.useState(rest.show==='false' ? false : true);
  const [label, setLabel] = React.useState(rest.label||'');
  const [fontSize, setFontSize] = React.useState(rest.fontSize||'12px');
  
  React.useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const doClick = (e) => {
    e.preventDefault();
    if (onClick) {
      onClick();
    }
  };

  useImperativeHandle(props.xref, () => {
    return {
      getType, setShow, setLabel, setFontSize
    };
  });
  const getType = () => { return 'CxLink' };

  return (
    <span style={{ width: rest.width||'100%', textAlign: 'left' }}>
      {show && 
        <button className="ibs_button_link" onClick={(e) => doClick(e)} style={{ fontSize: fontSize }} {...rest} >
          {label}
        </button>
      }
    </span>
  );

};

export default CxLink;
