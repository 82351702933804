import React from 'react';
import GxForm from '../../gx/GxForm';
import * as FxLog from '../../fx/FxLog';
import { useTranslation } from '../../hx/useTranslation';
// eslint-disable-next-line no-unused-vars
import { StoreClient } from "../../gx/GxStore";
import CxImageEditor from '../../cx/CxImageEditor';
import { useSessionStorage } from '../../hx/useSessionStorage';


const MxAnimalPhotoForm = (props) => {
  const [{ trans }] = useTranslation();

  const [storeClient, ] = useSessionStorage('StoreClient', StoreClient);
  
  const rf_image = React.useRef(null);


  React.useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const onSelect = () => {
    // se guardó exitosamente
    FxLog.infox('MxAnimalPhotoForm.onSelect', '...', );
    rf_image.current.doSave(storeClient.inAnimal);
  }
  const onClear = () => {
    // se guardó exitosamente
    FxLog.infox('MxAnimalPhotoForm.onClear', '...', );
    rf_image.current.doDelete(storeClient.inAnimal);
  }

  return (
    <div>
      <GxForm
        moduleName={`${trans('field.photo')}`}
      >
        <CxImageEditor xref={rf_image} 
          url='animals/photo' 
          inXXX={storeClient.inAnimal} 
          can_search 
          onSelect={onSelect} 
          onClear={onClear} 
          />

      </GxForm>

    </div>
  );

}

export default MxAnimalPhotoForm;
