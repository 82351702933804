import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from '../../hx/useTranslation';
import '../../../styles/main.css';
import CxToolbar from '../../cx/CxToolbar';
import CxSnackbar from '../../cx/CxSnackbar';
import CxStatic from '../../cx/CxStatic';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import * as FxFetch from '../../fx/FxFetch';
//import * as FxDat from '../../fx/FxDat';
import { StoreMarketing, isApp } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import { StoreLongTask, clearStore } from "../../gx/GxStore";
import { useNav } from '../../hx/useNav';
import CxInputTemplate from '../../cx/CxInputTemplate';
import CxIconButton from '../../cx/CxIconButton';


const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'flex-start',
    gap: 'var(--ibs_container_gap)',
    margin: 'var(--ibs_container_margin)',
  },
  button_frame: {
    margin: '20px 0 0 0',
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'flex-start',
    gap: '12px',
    width: '100%',
  },
}));

const MxMarketingOptionS = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navTo }] = useNav();
  const classes = useStyles(props);

  const rf_credits = React.useRef(null);
  const rf_txContent = React.useRef(null);
  
  const [templateName, setTemplateName] = React.useState('');
  
  const r_snack_error = React.useRef(null);
  
  const [storeLongTask, setStoreLongTask] = useSessionStorage('StoreLongTask', StoreLongTask);

  // eslint-disable-next-line
  const [storeMarketing, setStoreMarketing] = useSessionStorage('StoreMarketing', StoreMarketing);

  React.useEffect(() => {
    clearStore('StoreLongTask', storeLongTask);
    formLoad();
    checkCredits();
    // eslint-disable-next-line
  }, []);

  const formLoad = async () => {
  }

  const checkCredits = () => {
    try {
      let record = {};
      record['itSms'] = '';
      Promise.all([FxFetch.doJsonX(`/sett/sms/get_credits`, record)]).then((result) => {
        if (result[0].theValue.startsWith("rv_")) {
          rf_credits.current.clear();
        } else {
          let credits = Number(result[0].theValue);
          let listed = storeMarketing.arListedId.split('\n').length-1;
          rf_credits.current.setLabel(`${trans('field.addressees')}: ${listed}, ${trans('field.credits')}: ${result[0].theValue||0}`);
          FxLog.infox('MxMarketingOptionS.checkCredits', '...credits/listed:', credits +'/'+ listed);
          FxLog.infox('MxMarketingOptionS.checkCredits', '...storeMarketing.arListedId:', storeMarketing.arListedId);
          rf_credits.current.setFontColor(credits<listed ? 'var(--ibs_color_red)' : 'var(--ibs_color_green)');
        }
      });

    } catch (error) {
      FxLog.errorx("MxMarketingOptionS.checkCredits", error);
    }
  };

  const doTemplateSelected = (name, cont) => {
    FxLog.infox('MxMarketingOptionS.doTemplateSelected', '...', name +' - '+ cont);
    setTemplateName(name);
    rf_txContent.current.setVal(cont.substring(0, 160));
  }

  const sendAll = (op) => {
    try {
      if (rf_txContent.current.isEmpty()) {
        r_snack_error.current.setVal(trans('field.required') + ': ' + trans('field.content'));
        r_snack_error.current.setOpen(true);
        return;
      }

      storeLongTask._url = 'marketing/send';
      storeLongTask._canCancel = true;
      storeLongTask._record = { 
        txTaskName: "marketing", 
        op: op, 
        templateName: templateName,
        txContent: rf_txContent.current.getVal(),
        arListedId: storeMarketing.arListedId,
      };
      Promise.all([setStoreLongTask(storeLongTask)]).then((result) => {
        navTo('marketing-option-tsk');
      });

    } catch (error) {
      FxLog.errorx("MxMarketingOptionS.sendAll", error);
    }
  }

  const getPlaceholders = () => {
    let ph = [];
    if (isApp('vett') || isApp('spaw')) {
      ph.push({ id: "[name]", type: trans('field.name') });
      ph.push({ id: "[first_name]", type: trans('field.name_first') });
      ph.push({ id: "[last_name]", type: trans('field.name_last') });
      ph.push({ id: "[contact]", type: trans('field.contact') });
      ph.push({ id: "[animal]", type: trans('field.patient') });
      if (storeMarketing.arPssFq) {
        ph.push({ id: "[ps]", type: trans('field.vacc') + '-' + trans('field.name') });
        ph.push({ id: "[next]", type: trans('field.vacc') + '-' + trans('field.next') });
      }
      return ph;
    }
    if (isApp('opti')) {
      ph.push({ id: "[name]", type: trans('field.name') });
      ph.push({ id: "[first_name]", type: trans('field.name_first') });
      ph.push({ id: "[last_name]", type: trans('field.name_last') });
      ph.push({ id: "[contact]", type: trans('field.contact') });
      if (storeMarketing.arPssFq) {
        ph.push({ id: "[ps]", type: trans('field.repl') + '-' + trans('field.name') });
        ph.push({ id: "[next]", type: trans('field.repl') + '-' + trans('field.next') });
      }
      return ph;
    }
    if (isApp('invo')) {
      ph.push({ id: "[name]", type: trans('field.name') });
      ph.push({ id: "[first_name]", type: trans('field.name_first') });
      ph.push({ id: "[last_name]", type: trans('field.name_last') });
      ph.push({ id: "[contact]", type: trans('field.contact') });
      return ph;
    }
  }

  return (
    <div>
      <CxToolbar 
        moduleName={`${trans('field.marketing')}: ${trans('field.options')}: ${trans('field.sms')}`} 
        background={storeMarketing.marketingType==='E' ? 'var(--ibs_color_red_light)' : 'var(--ibs_color_yellow_light)'}
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <CxIconButton type="chevron_right" classType="ibs_iconbutton_toolbar" onClick={() => sendAll('S')} tooltip={trans('field.next')} />
          </div>
        }
      />

      <div className={classes.container}>

      <CxStatic xref={rf_credits} border='none' fontsize='14px' />
      <CxInputTemplate xref={rf_txContent} label={trans('field.content')} minRows={2} maxLength={160} required
          tpl_table={"n_email_tpl"} tpl_col_id={"inEmailTpl"} tpl_col_tx={"txName"} tpl_filter={""} tpl_extra_filter_1={""} tpl_content_field={"txContent"}
          hasTplButtonAdd
          onTemplateSelected={doTemplateSelected}
          placeholders={getPlaceholders}
          speech
        />


      </div>

      <CxSnackbar xref={r_snack_error} severity="error" />

    </div>
  );

}

export default MxMarketingOptionS;
