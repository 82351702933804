import { MdCheck } from "react-icons/md"; 
import { MdClear } from "react-icons/md"; 
import React, { useImperativeHandle } from "react";
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../fx/FxLog';
import { useStyles } from "./StylesCx";
import { makeStyles } from '@material-ui/core/styles';
import CxTooltip from './CxTooltip';
import { FormLabel, FormControlLabel, FormGroup, } from "@material-ui/core";
import CxIconButtonNew from "./CxIconButtonNew";
import * as FxFetch from '../fx/FxFetch';


const useStylesLocal = makeStyles({
  ctl_label: {
    padding: '5px 0 8px 4px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    margin: '0px 0px 0px 0px',
    cursor: 'default',
    textAlign: 'left',
    //color: 'var(--ibs_color_gray)',
    fontSize: 'var(--ibs_control_label_font_size_radio)',
    "&.MuiFormControlLabel-root": {
      width: props => {
        if (props.width) {
          return props.width;
        } else {
          return '100%'
        }
      },
    },
  },

  ctl_group: {
    margin: '5px 12px 8px 12px',
    /* quitado porque los ítems rebasan el margen inferior
    height: props => {
      if (props.height) {
        return props.height;
      } else {
        return ''
      }
    },
    */
  },

  ctl_check: {
    "& .MuiTypography-body1": {
      textAlign: 'left',
      margin: '5px 0px 5px 2px',
      fontSize: props => { return props.fontSize || 'var(--ibs_control_label_font_size_radio)' },
      width: props => { return props.itemWidth || '100px' },
    },
  },
});


const CxMultiCheck = props => {
  const classes = useStyles(props);
  const classesL = useStylesLocal(props);
  const [list, setList] = React.useState(props.list || []);
  const [wasChanged, setChanged] = React.useState(false);
  
  const r_checked = React.useRef('');

  const rf_group = React.useRef('');
  const rf_check = React.useRef([]);


  React.useEffect(() => {
    if (props.tableName) {
      // para obtener los datos de la db con query general. eg. tableName="t_payment" idField="itPayment" txField="txTypePayment"
      getValuesFromDb_General();
    }
    if (props.url) {
      // para obtener los datos de la db con query personalizado. 
      getValuesFromDb_Custom();
    }

    // eslint-disable-next-line
  }, []);

  const getValuesFromDb_General = () => {
    let record = {};
    record['pageNo'] = '0';
    record['pageSize'] = '1000';
    record['sortBy'] = props.idField;
    record['filter'] = '';
    record['tableName'] = props.tableName;
    record['idField'] = props.idField;
    record['idFieldAlias'] = "id";
    record['txField'] = props.txField;
    record['txFieldAlias'] = 'name';
    record['txExtraFilter1'] = props.txExtraFilter1;
    Promise.all([FxFetch.doJsonX('general/page', record)]).then((result) => {
      if (result[0]) {
        let ls = result[0].reverse(); //invertimos array
        Promise.all([setList(ls)]).then((result) => {
          doCheck();
        });
      }
    });
  }

  const getValuesFromDb_Custom = () => {
    // obtiene y devuelve los eventos para la fecha del calendario.
    FxLog.infox('CxMultiCheck.getValuesFromDb_Custom', '...props.url:', props.url);
    let record = {};
    record['txExtraFilter1'] = props.txExtraFilter1;
    Promise.all([FxFetch.doJsonX(props.url, record)]).then((result) => {
      if (result[0]) {
        let ls = result[0].reverse(); //invertimos array
        Promise.all([setList(ls)]).then((result) => {
          doCheck();
        });
       }
    });
  }

  useImperativeHandle(props.xref, () => {
    return {
      getType, setVal, getVal, getLabel, getDbfield, isEmpty, wasChanged, setChanged, 
    };
  });
  const getType = () => { return 'CxMultiCheck' };
  const setVal = (checkedPSV) => { setIdsChecked(checkedPSV) };
  const getVal = () => { return getIdsChecked() || '' };
  const getLabel = () => { return '' };
  const getDbfield = () => { return props.dbfield };
  const isEmpty = () => { return r_checked.current.length === 0 };

  /* Listado de ítems a checkear, separados por pipes. Ej: '000003.SRV|000001.SRV' */
  const doCheck = () => {
    for (let index = rf_check.current.length - 1; index >= 0; index--) {
      if (r_checked.current.includes(rf_check.current[index].id)) {
        rf_check.current[index].checked = true;
      }
    }
  };

  const setIdsChecked = (checkedPSV) => {
    r_checked.current = checkedPSV;
  };

  const getIdsChecked = () => {
    let newValue = '';

    for (let index = rf_check.current.length - 1; index >= 0; index--) {
      let ctl = rf_check.current[index];
      if (ctl.checked) {
        newValue += '|' + ctl.id;
      }
    }
    newValue = newValue.substring(1);


    setVal(newValue)
    setChanged(true);
    
    return newValue;
  };

  const doClear = () => {
    for (let index = rf_check.current.length - 1; index >= 0; index--) {
      rf_check.current[index].checked = false;
    }

    setVal('')
    setChanged(true);
  };

  const doCheckAll = () => {
    for (let index = rf_check.current.length - 1; index >= 0; index--) {
      rf_check.current[index].checked = true;
    }

    setVal('')
    setChanged(true);
  };

  return (
    <CxTooltip text={props.tooltip ? props.tooltip : null} tooltipType={props.tooltipType}>
      <div id='div_CxMultiCheck' className={classes.frame}>
        <div className={classes.frame_content} style={{ padding: '0px', }}>
          <FormLabel component="legend" focused={false} classes={{ root: classesL.ctl_label, }} >
            {props.label}
          </FormLabel>
          <FormGroup ref={rf_group} row={true} classes={{ root: classesL.ctl_group, }}>
            {list.map((item, i) => (
              <FormControlLabel classes={{ root: classesL.ctl_check, }}
                key={item.id}
                label={item.name}
                control={
                  <label className="ibs_checkbox">
                    <input ref={(element) => rf_check.current[i] = element} type="checkbox"
                      id={item.id}
                      value={item.name}
                      defaultChecked={Boolean(item.checked === '1') || false}
                    />
                  </label>
                }
              />
            ))}
          </FormGroup>

        </div>
        {!props.hidebuttons && <div className={classes.frame_buttons}>
          {!props.readOnly && <CxIconButtonNew icon={<MdClear fontSize={20} />} classType={classes.frame_icons_new} onClick={doClear} disabled={props.disabled} />}
          {!props.readOnly && <CxIconButtonNew icon={<MdCheck fontSize={20} />} classType={classes.frame_icons_new} onClick={doCheckAll} disabled={props.disabled} />}
        </div>}
      </div>
    </CxTooltip>
  );

};

export default CxMultiCheck;
