import { BiLockAlt } from "react-icons/bi";
import { IoMdAdd } from "react-icons/io";
import { MdClear } from "react-icons/md";
import React, { useState, useImperativeHandle } from "react";
import TextField from '@material-ui/core/TextField';
import NumberFormat from 'react-number-format';
import '../../styles/main.css';
// eslint-disable-next-line
import * as FxLog from '../fx/FxLog';
import { getStoreValue } from "../gx/GxStore";
import CxIconButtonNew from "./CxIconButtonNew";
import { useStyles } from "./StylesCx";
import CxDlgPin from './CxDlgPin';


const NumberFormatCustom = (...params) => (props) => {
  // params will be available in the body of your component
  const { inputRef, onChange, ...other } = props;
  FxLog.infox('CxNumber.NumberFormatCustom', '...params[0]:', params[0]);

  return (
    <NumberFormat
      {...other}
      style={{
        fontSize: 'var(--ibs_control_text_font_size)',
      }}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={false}
      isNumericString
      decimalSeparator={getStoreValue('StoreSession', 'txDecimalSeparator')}
      prefix="" //podría ser $ o €
      isAllowed={(values) => {
        FxLog.infox('CxNumber.NumberFormatCustom', '...values:', values);
        const { value } = values;
        if (params[0].sign==='positive' && value==='-') {
          return false;
        }
        return true;
      }}
    />
  );
}


const CxNumber = props => {
  const classes = useStyles(props);
  const { onBlur, onClear, onChange, onEnter, InputLabelProps, InputProps, defaultValue, sumButton, redText, ...rest } = props;
  const [myValue, setMyValue] = useState('');
  const r_val = React.useRef(false); // para calc intermedios, xq myValue no se actualiza inmediatamente ya que useState es asincrónico.
  const [isRequired, setIsRequired] = useState(false);
  const [wasChanged, setChanged] = useState(false);
  const [locked, setLocked] = React.useState(false);

  const r_clearing = React.useRef(false);
  const r_dlg_pin = React.useRef(null);

  const rf_control = React.useRef(null);
  const rf_button_action1 = React.useRef(null);

  const MyComp = React.useRef(NumberFormatCustom(rest));

  React.useEffect(() => {
    setIsRequired(props.required);
    if (defaultValue) {
      setVal(defaultValue);
    }
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (r_clearing.current) {
      r_clearing.current = false;
      if (onClear !== undefined) onClear();
    }
    // eslint-disable-next-line
  }, [myValue]);

  const doClear = () => {
    r_clearing.current = true;
    setVal('');
    setChanged(true);
    setFocus();
  }

  const doAdd = () => {
    let newVal = (parseFloat(r_val.current) || 0) + 1;
    setVal(newVal);
    if (props.onBlur !== undefined) props.onBlur(props.dbfield, myValue);
  }

  // para poder usar setVal desde el parent component con ref.current
  useImperativeHandle(props.xref, () => {
    return {
      getType, setVal, setFloatVal, getVal, getFloatVal, getIntVal, getIsRequired, getLabel, getDbfield, setFocus,
      setFocusSel, isEmpty, isZero, clear, clearToEmpty, wasChanged, setChanged, setLocked, getValOrEmpty
    };
  });
  const setVal = (newValue) => { r_val.current = newValue; setMyValue(newValue); }
  const getVal = () => { return r_val.current ? r_val.current : props.decimals === 0 ? '0' : '0.0' };
  const getValOrEmpty = () => { return getVal()>0 ? getVal() : '' }; // 2025-01-16 - daq: en lugar de devolver cero, devuele vacío.
  const setFloatVal = (newValue) => { setVal(newValue.toString()); }
  const getFloatVal = () => { return parseFloat(getVal() || 0) };
  const getIntVal = () => { return parseInt(getVal() || 0) };
  const getIsRequired = () => { return isRequired && (myValue === null || myValue === '') };
  const getLabel = () => { return props.label };
  const getDbfield = () => { return props.dbfield };
  const setFocus = () => rf_control.current.focus();
  const setFocusSel = () => rf_control.current.select();
  const isEmpty = () => { return myValue.length === 0 };
  const isZero = () => { return getIntVal() === 0 };
  const getType = () => { return 'CxNumber' };
  const clearToEmpty = () => {
    setVal('');
    setChanged(true);
  };
  const clear = () => {
    setVal(props.decimals === 0 ? '0' : '0.0');
    setChanged(true);
    if (props.onClear !== undefined) props.onClear();
  };

  return (
    <div className={classes.frame}>
      <div className={classes.frame_content}>
        <TextField
          inputRef={rf_control}
          disabled={locked}
          className={classes.control}
          InputLabelProps={{
            classes: { root: classes.root },
            shrink: true,
            ...InputLabelProps
          }}
          InputProps={{
            disableUnderline: true,
            required: false,
            inputComponent: MyComp.current,
            inputProps: { //custom props can be passed to the NumberFormat using inputProps
              decimalScale: props.decimals,
              fixedDecimalScale: props.decimalsfixed,
              style: {
                color: redText ? 'var(--ibs_color_red)' : ((props.disabled || locked) ? 'var(--ibs_color_disabled)' : 'var(--ibs_color_black)'),
              },
            },
            classes: { root: classes.root, underline: classes.underline },
            ...InputProps
          }}
          value={myValue}
          onBlur={() => {
            if (props.onBlur !== undefined) props.onBlur(props.dbfield, myValue);
          }}
          onChange={(e) => {
            Promise.all([setVal(e.target.value)]).then((result) => {
              if (onChange) onChange(e.target.value);
            });
          }}
          onFocus={event => {
            if (rest.autoselect) event.target.select();
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              if (onEnter) onEnter();
            } else {
              setChanged(true);
            }
          }}
          onKeyUp={(e) => {
            if (props.onKeyUp !== undefined) props.onKeyUp(e);
          }}
          {...rest}
        />
      </div>

      {!props.hidebuttons && <div className={classes.frame_buttons}>
        {!props.readOnly && !locked && !rest.action1 && <CxIconButtonNew icon={<MdClear fontSize={20} />} classType={classes.frame_icons_new} onClick={doClear} disabled={props.disabled} />}
        {sumButton && <CxIconButtonNew icon={<IoMdAdd fontSize={20} />} classType={classes.frame_icons_new} onClick={doAdd} disabled={props.disabled} />}
        {locked && <CxIconButtonNew icon={<BiLockAlt fontSize={20} />} classType={classes.frame_icons_new} onClick={() => r_dlg_pin.current.setOpen(true)} />}
        {rest.action1 && 
          <CxIconButtonNew xref={rf_button_action1} 
            icon={rest.action1.icon} 
            classType={'table_button'} 
            onClick={() => {
              rf_button_action1.current.setIcon(rest.action1.icon)
              rest.action1.onClick();
            }
            } 
            tooltip={rest.action1.tooltip} 
          />
        }
      </div>}

      {locked && <CxDlgPin xref={r_dlg_pin} onOk={() => setLocked(false)} />}

    </div>
  );
};

export default CxNumber;
