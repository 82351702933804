import React from 'react';
import GxForm from '../../gx/GxForm';
import GxPrintLong from '../../gx/GxPrintLong';
import { useTranslation } from '../../hx/useTranslation';
import CxInput from '../../cx/CxInput';
import CxDlgTable from '../../cx/CxDlgTable';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import * as FxEdition from '../../fx/FxEdition';
import * as FxTable from '../../fx/FxTable';
import * as FxFetch from '../../fx/FxFetch';
import CxSnackbar from '../../cx/CxSnackbar';
import { useNav } from '../../hx/useNav';
import CxChooser from '../../cx/CxChooser';
import CxCombo from '../../cx/CxCombo';
import CxNumber from '../../cx/CxNumber';
import CxIconButtonNew from '../../cx/CxIconButtonNew';
import { } from "../../gx/GxStore";
import { isApp, getStoreValueBo, getStoreValue, StorePs, StorePsComponent, StoreRxAutoPs, clearStore } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import CxTable from '../../cx/CxTable';
import * as MxPsCalc from './MxPsCalc';
import CxAccordion from '../../cx/CxAccordion';
import CxDate from '../../cx/CxDate';
import CxState from '../../cx/CxState';
import CxSearcher from './../../cx/CxSearcher';
import { useAccess } from '../../hx/useAccess';
import CxDlgAlert from '../../cx/CxDlgAlert';
import { GrClone } from "react-icons/gr";
import { RiPlayList2Fill } from "react-icons/ri";


const MxPsForm = (props) => {
  const [{ trans }] = useTranslation();
  const [{ isFullAccess }] = useAccess(trans('field.pss'));
  const [{ navTo /*, navBack*/ }] = useNav();

  const r_form = React.useRef(null);
  let r_gx_print_long = React.useRef(null);

  const r_snack_ask_save = React.useRef(null);
  const r_snack_exists = React.useRef(null);
  const r_dlg_alert = React.useRef(null);

  const r_dlgtable_ps_to_clone = React.useRef(null);
  const [dlgPsToClone, setDlgPsToClone] = React.useState(false);

  const rf_txPs = React.useRef(null);
  const rf_nuCost = React.useRef(null);
  const rf_nuPrice = React.useRef(null);
  const rf_nuQuantity = React.useRef(null);
  const rf_nuDiscountPercP = React.useRef(null);
  const rf_nuDiscountPercS = React.useRef(null);
  const rf_nuTaxPercP = React.useRef(null);
  const rf_nuTaxPercS = React.useRef(null);
  const rf_nuSurchargePercP = React.useRef(null);
  const rf_CostIncTax = React.useRef(null);
  const rf_PriceIncTax = React.useRef(null);
  const rf_Markup = React.useRef(null);

  const rf_txSupplier = React.useRef(null);
  const rf_txPsSimilar = React.useRef(null);
  const rf_nuNature = React.useRef(null);
  const rf_txUse = React.useRef(null);
  const rf_txTypePsGroup = React.useRef(null);
  const rf_txTypePsSubgroup = React.useRef(null);
  const rf_txPsShort = React.useRef(null);
  const rf_txPsCode = React.useRef(null);
  const rf_txReference = React.useRef(null);
  const rf_txPsAlias = React.useRef(null);

  const [storePs, setStorePs] = useSessionStorage('StorePs', StorePs);
  const [storePsComponent, setStorePsComponent] = useSessionStorage('StorePsComponent', StorePsComponent);
  const [storeRxAutoPs, setStoreRxAutoPs] = useSessionStorage('StoreRxAutoPs', StoreRxAutoPs);


  React.useEffect(() => {
    clearStore('StorePsComponent', storePsComponent);
    clearStore('StoreRxAutoPs', storeRxAutoPs);

    // eslint-disable-next-line
  }, []);

  const formLoad = async (data) => {
    if (data === null) { //nuevo registro
      r_form.current.getRef('n_ps.dtFrom').setValToday();
      r_form.current.getRef('n_ps.nuQuantity').setVal('1');
    } else {
      rf_txPs.current.setLocked(true);
      calcCostIncTax();
      calcPriceIncTax();
      calcMarkup();
    }
  }
  const formSave = async (lastSaveRv) => {
    if (lastSaveRv.theValue.startsWith("rv_")) {
      // errores al guardar
      if (lastSaveRv.theValue.startsWith("rv_exists")) {
        r_snack_exists.current.setOpen(true);
      }
    } else {
      storePs.inPs = lastSaveRv.theValue;
      Promise.all([setStorePs(storePs)]).then((result) => {
        //navBack(); // 2022-12-09 - daq: comentado xq puede querer quedarse para añadir componentes.
      });
    }
  }
  const formDelete = async (rv) => {
  }

  const groupCleared = () => {
    r_form.current.getRef('t_ps_subgroup.txTypePsSubgroup').clear();
    r_form.current.getRef('t_ps_subgroup.txTypePsSubgroup').setFilter(`undefined='${undefined}'`);
  }

  const groupSelected = (row) => {
    r_form.current.getRef('t_ps_subgroup.txTypePsSubgroup').clear();
  }

  const subgroupOpen = (row) => {
    let itPsGroup = r_form.current.getRef('t_ps_group.txTypePsGroup').getKey();
    r_form.current.getRef('t_ps_subgroup.txTypePsSubgroup').setFilter(`itPsGroup='${itPsGroup}'`);
    return true; // para que abra la ventana de búsqueda.
  }

  /* CÁLCULOS *************************************************************************** */
  const calcCostIncTax = () => {
    let cost = rf_nuCost.current.getFloatVal();
    let taxPerc = rf_nuTaxPercP.current.getFloatVal();
    rf_CostIncTax.current.setFloatVal(MxPsCalc.addTax(cost, taxPerc));
  }

  const calcPriceIncTax = () => {
    let price = rf_nuPrice.current.getFloatVal();
    let taxPerc = rf_nuTaxPercS.current.getFloatVal();
    rf_PriceIncTax.current.setFloatVal(MxPsCalc.addTax(price, taxPerc));
  }

  const calcCostFromCostIncTax = () => {
    let cit = rf_CostIncTax.current.getFloatVal();
    let taxPerc = rf_nuTaxPercP.current.getFloatVal();
    rf_nuCost.current.setFloatVal(MxPsCalc.removeTax(cit, taxPerc));
  }

  const calcPriceFromPriceIncTax = () => {
    let pit = rf_PriceIncTax.current.getFloatVal();
    let taxPerc = rf_nuTaxPercS.current.getFloatVal();
    rf_nuPrice.current.setFloatVal(MxPsCalc.removeTax(pit, taxPerc));
  }

  const calcPrice = () => {
    let markup = rf_Markup.current.getFloatVal();
    let cost = rf_nuCost.current.getFloatVal();
    let taxPercS = rf_nuTaxPercS.current.getFloatVal();
    let taxPercP = rf_nuTaxPercP.current.getFloatVal();
    let surchargePercP = rf_nuSurchargePercP.current.getFloatVal();
    if (cost !== 0) rf_nuPrice.current.setFloatVal(MxPsCalc.getPrice(markup, cost, taxPercS, taxPercP, surchargePercP));
  }

  const calcMarkup = () => {
    let taxPercS = rf_nuTaxPercS.current.getFloatVal();
    let taxPercP = rf_nuTaxPercP.current.getFloatVal();
    let surchargePercS = 0;
    let surchargePercP = rf_nuSurchargePercP.current.getFloatVal();
    let price = rf_nuPrice.current.getFloatVal();
    let cost = rf_nuCost.current.getFloatVal();
    let disPercS = rf_nuDiscountPercS.current.getFloatVal();
    let disPercP = rf_nuDiscountPercP.current.getFloatVal();
    let disS = disPercS === 0 ? 0 : price * disPercS / 100;
    let disP = disPercP === 0 ? 0 : cost * disPercP / 100;
    rf_Markup.current.setFloatVal(MxPsCalc.getMarkupPerc(price - disS, cost - disP, taxPercS, taxPercP, surchargePercS, surchargePercP));
  }
  /* ************************************************************************************ */

  const openFormComponent = async (op, row) => {
    if (!storePs.inPs) {
      r_snack_ask_save.current.setOpen(true);
    } else {
      if (row === null) {
        storePsComponent.inPs = storePs.inPs;
        storePsComponent.irPsPs = null;
        storePsComponent.inPsAttached = null;
        setStorePsComponent(storePsComponent);
      } else {
        storePsComponent.inPs = storePs.inPs;
        storePsComponent.irPsPs = row.original.irPsPs;
        storePsComponent.inPsAttached = row.original.inPsAttached;
        setStorePsComponent(storePsComponent);
      }
      navTo("ps-component-form");
    }
  }

  const openFormRxAuto = async (op, row) => {
    if (!storePs.inPs) {
      r_snack_ask_save.current.setOpen(true);
    } else {
      if (row === null) {
        StoreRxAutoPs.inPs = storePs.inPs;
        StoreRxAutoPs.txPs = rf_txPs.current.getVal();
        StoreRxAutoPs.itRxAuto = null;
        setStoreRxAutoPs(StoreRxAutoPs);
      } else {
        StoreRxAutoPs.inPs = storePs.inPs;
        StoreRxAutoPs.txPs = rf_txPs.current.getVal();
        StoreRxAutoPs.itRxAuto = row.original.itRxAuto;
        setStoreRxAutoPs(StoreRxAutoPs);
      }
      navTo("ps-rx-auto-form");
    }
  }

  const doPrintX = async (option) => {
    let filter = {};
    filter['reportFormat'] = `PDF`;
    filter['inPs'] = storePs.inPs;
    filter['txPs'] = rf_txPs.current.getVal();
    let record = {};
    r_gx_print_long.current.setScale(1.5);
    r_gx_print_long.current.setUrlBuild('pss/report/movs_build');
    r_gx_print_long.current.setUrlGet('pss/report/movs_get');
    //----------------
    Promise.all([
      r_gx_print_long.current.setFilter(filter),
      r_gx_print_long.current.setRecord(record),
    ]).then((result) => {
      r_gx_print_long.current.doPrint();
    });
  }

  const doClone = (row) => {

    Promise.all([setDlgPsToClone(false)]).then((result) => {
      let record = {};
      record['idValue'] = row.original.inPs;
      Promise.all([FxFetch.doJsonX("pss/r", record)]).then((result) => {
        if (result[0]) {
          r_form.current.formReload(result[0]);
          /*
          rf_nuNature.current.setKeyAndVal2(row.original.nuNature);
          rf_txUse.current.setKeyAndVal2(row.original.txUse);
          rf_txTypePsGroup.current.setKey(row.original.itPsGroup);
          rf_txTypePsGroup.current.setVal(row.original.txTypePsGroup);
          rf_txTypePsSubgroup.current.setKey(row.original.itPsSubgroup);
          rf_txTypePsSubgroup.current.setVal(row.original.txTypePsSubgroup);
          */
          rf_txPs.current.clear();
          rf_txPs.current.setFocus();
          rf_txPsShort.current.clear();
          rf_txPsAlias.current.clear();
          rf_txPsCode.current.clear();
          rf_txReference.current.clear();
          rf_txPsSimilar.current.clear();

          calcCostIncTax();
          calcPriceIncTax();
          calcMarkup();
        }
      });
    });
  }

  return (
    <div>
      <GxForm
        moduleName={`${trans('field.ps')}`}
        xref={r_form}
        url="pss"
        filterIdField='inPs'
        filterIdValue={storePs.inPs}
        onFormLoad={formLoad}
        onFormSave={isFullAccess() && formSave}
        onFormDelete={isFullAccess() && formDelete}
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>

            {/* botón de clonación */}
            {!storePs.inPs && <CxIconButtonNew icon={<GrClone fontSize="20px" />} classType="ibs_iconbutton_toolbar" onClick={() => setDlgPsToClone(true)} tooltip={trans('field.clone')} />}

            {/* botón de movimientos */}
            <CxIconButtonNew icon={<RiPlayList2Fill />} classType="ibs_iconbutton_toolbar" onClick={doPrintX} tooltip={trans('rpt.ps_stock_movs')} />

          </div>
        }
      >

        <CxInput xref={rf_txPs} label={trans('field.name')} dbfield="n_ps.txPs" maxLength='100' capitalize={getStoreValueBo('StoreSession', 'boUseUcaseForNames')} required />
        <CxInput xref={rf_txPsShort} label={trans('field.name_short')} dbfield="n_ps.txPsShort" maxLength='12' capitalize={getStoreValueBo('StoreSession', 'boUseUcaseForNames')} required />
        <CxInput xref={rf_txPsAlias} label={`${trans('field.alias')}-${trans('field.invoicing')}`} dbfield="n_ps.txPsAlias" maxLength='100' />
        <CxInput xref={rf_txPsCode} label={trans('field.code')} dbfield="n_ps.txPsCode" maxLength='50' />

        <CxSearcher xref={rf_txSupplier} label={trans('field.supplier')}
          dbfield="n_supplier.txSupplier" dbfieldkey={`n_ps.inSupplier`}
          url="suppliers"
          col_id="inSupplier"
          col_tx="txSupplier"
          columns={[
            { id: "0", Header: "ID", accessor: "inSupplier", show: false },
            { id: "1", Header: trans('field.supplier'), accessor: row => rf_txSupplier.current.getTable().highlightText(row["txSupplier"]), width: '100%' },
          ]}
        />
        <CxDate xref={React.useRef(null)} label={trans('field.from')} dbfield="n_ps.dtFrom" />
        <CxDate xref={React.useRef(null)} label={trans('field.deactivation')} dbfield="n_ps.dtTo" />

        <CxCombo xref={rf_nuNature}
          label={`${trans('field.nature')}`}
          dbfield='n_ps.nuNature'
          list={[
            { id: '0', type: trans('field.product') },
            { id: '1', type: trans('field.service') },
          ]}
          required
          autoSelectFirst
        />

        <CxCombo xref={rf_txUse}
          label={`${trans('field.use')}`}
          dbfield='n_ps.txUse'
          list={[
            { id: 'x', type: `${trans('field.sales')}+${trans('field.purchases')}` },
            { id: 's', type: trans('field.sales') },
            { id: 'p', type: trans('field.purchases') },
          ]}
          required
          autoSelectFirst
        />

        <CxChooser xref={rf_txTypePsGroup}
          label={`${trans('field.group')}`}
          dbfield='t_ps_group.txTypePsGroup' dbfieldkey='n_ps.itPsGroup'
          table="t_ps_group"
          col_id="itPsGroup"
          col_tx="txTypePsGroup"
          filter={`t_ps_group.boActive='${'1'}'`}
          onClear={groupCleared}
          onSelection={groupSelected}
          required
        />

        <CxChooser xref={rf_txTypePsSubgroup}
          label={`${trans('field.subgroup')}`}
          dbfield='t_ps_subgroup.txTypePsSubgroup' dbfieldkey='n_ps.itPsSubgroup'
          canOpenDialog={subgroupOpen}
          table="t_ps_subgroup"
          col_id="itPsSubgroup"
          col_tx="txTypePsSubgroup"
          filter={`t_ps_subgroup.boActive='${'1'}'`}
          required
        />

        <CxState mode='checkbox' xref={React.useRef(null)} label={trans('field.favorite')} dbfield="n_ps.boFavorite" />
        <CxSearcher xref={rf_txPsSimilar} label={trans('field.similar')}
          id='inPsSimilar'
          dbfield="txPsSimilar" dbfieldkey={`n_ps.inPsSimilar`}
          url="pss"
          col_id="inPs"
          col_tx="txPs"
          columns={[
            { id: "0", Header: "ID", accessor: "inPs", show: false },
            { id: "1", Header: trans('field.ps'), accessor: row => rf_txPsSimilar.current.getTable().highlightText(row["txPs"]), width: '100%' },
          ]}
        />

        <CxNumber xref={React.useRef(null)} dbfield="n_ps.nuFrequency" label={trans('field.frequency')} />
        <CxNumber xref={React.useRef(null)} dbfield="n_ps.nuBillingCode" label={trans('field.billing_code')} decimals={0} />
        <CxInput xref={rf_txReference} label={trans('field.reference')} dbfield="n_ps.txReference" maxLength='50' />

        <CxNumber xref={rf_nuQuantity}
          label={trans('field.default_x_0', 'field.quantity')}
          dbfield="n_ps.nuQuantity"
          decimals={2}
          decimalsfixed="true"
        />

        <CxState mode='checkbox' xref={React.useRef(null)} label={trans('field.stock_control')} dbfield="n_ps.boStockControl" />
        <CxState mode='checkbox' xref={React.useRef(null)} label={trans('field.hide_if_price_zero')} dbfield="n_ps.boHideIfPriceZero" />


        <CxInput xref={React.useRef(null)} label={`${trans('field.alert')} (${trans('field.pos')})`} dbfield="n_ps.txAlert" maxLength={200} redText />
        <CxInput xref={React.useRef(null)} label={trans('field.notes')} dbfield="n_ps.txNotes" minRows={4} />

        {/* ---------------------------------------------------------------------------------------------------------- */}
        <CxAccordion defaultExpanded title={trans('field.values')}>

          <CxNumber xref={rf_nuCost}
            label={trans('field.cost')}
            dbfield="n_ps.nuCost"
            decimals={4}
            decimalsfixed="true"
            onBlur={() => { calcMarkup(); calcCostIncTax() }}
            required
          />

          <CxNumber xref={rf_nuPrice}
            label={trans('field.price')}
            dbfield="n_ps.nuPrice"
            decimals={4}
            decimalsfixed="true"
            onBlur={() => { calcMarkup(); calcPriceIncTax() }}
            required
          />

          <CxNumber xref={rf_nuDiscountPercP}
            label={`${trans('field.discount_perc')}-${trans('field.purchases')}`}
            dbfield="n_ps.nuDiscountPercP"
            decimals={2}
            decimalsfixed="true"
            onBlur={() => { calcMarkup(); }}
          />

          <CxNumber xref={rf_nuDiscountPercS}
            label={`${trans('field.discount_perc')}-${trans('field.sales')}`}
            dbfield="n_ps.nuDiscountPercS"
            decimals={2}
            decimalsfixed="true"
            onBlur={() => { calcMarkup(); }}
          />

          <CxNumber xref={rf_nuTaxPercP}
            label={`${trans('field.tax_perc')}-${trans('field.purchases')}`}
            dbfield="nuTaxPercP"
            decimals={2}
            decimalsfixed="true"
            onBlur={() => { calcCostIncTax(); calcPriceIncTax(); calcMarkup() }}
            required
          />

          <CxNumber xref={rf_nuTaxPercS}
            label={`${trans('field.tax_perc')}-${trans('field.sales')}`}
            dbfield="nuTaxPercS"
            decimals={2}
            decimalsfixed="true"
            onBlur={() => { calcCostIncTax(); calcPriceIncTax(); calcMarkup() }}
            required
          />

          <CxChooser xref={rf_nuSurchargePercP}
            label={`${trans('field.surcharge_perc')}-${trans('field.purchases')}`}
            dbfield='nuSurchargePercP' dbfieldkey='n_ps.itSurchargeGroupP'
            decimals={2}
            table="t_surcharge"
            col_id="itSurchargeGroup"
            col_tx="nuSurchargePerc"
            filter={`inBranch='${getStoreValue('StoreSession', 'inBranch')}'`}
            onValueChange={() => { calcMarkup() }}
          />

          <CxNumber xref={rf_CostIncTax}
            dbfield='dummy_costinctax'
            label={`${trans('field.cost')}+${trans('field.tax')}`}
            decimals={2}
            decimalsfixed="true"
            onBlur={() => { calcCostFromCostIncTax(); calcMarkup() }}
          />

          <CxNumber xref={rf_PriceIncTax}
            dbfield='dummy_priceinctax'
            label={`${trans('field.price')}+${trans('field.tax')}`}
            decimals={2}
            decimalsfixed="true"
            onBlur={() => { calcPriceFromPriceIncTax(); calcMarkup() }}
          />

          <CxNumber xref={rf_Markup}
            dbfield='dummy_markup'
            label={`${trans('field.markup_perc')}`}
            decimals={2}
            decimalsfixed="true"
            //disabled="true"
            onBlur={() => { calcPrice(); calcCostIncTax(); calcPriceIncTax(); calcMarkup(); }}
          />
        </CxAccordion>
        {/* ---------------------------------------------------------------------------------------------------------- */}

        <CxAccordion title={trans('field.package')} >
          <CxInput xref={React.useRef(null)} label={`${trans('field.units')}-${trans('field.purchases')}`} dbfield="n_ps.txUnitP" maxLength='20' />
          <CxInput xref={React.useRef(null)} label={`${trans('field.units')}-${trans('field.sales')}`} dbfield="n_ps.txUnitS" maxLength='20' />
          <CxNumber xref={React.useRef(null)} label={trans('field.conversion_factor')} dbfield="n_ps.nuConversionFactor" decimals={2} />
        </CxAccordion>

        <CxAccordion title={trans('field.purse')} >
          <CxNumber xref={React.useRef(null)} label={trans('field.cash')} dbfield="n_ps.nuPurseCash" />
          <CxNumber xref={React.useRef(null)} label={trans('field.card')} dbfield="n_ps.nuPurseCard" />
          <CxNumber xref={React.useRef(null)} label={trans('field.check')} dbfield="n_ps.nuPurseCheck" />
          <CxNumber xref={React.useRef(null)} label={trans('field.ddebit_s')} dbfield="n_ps.nuPurseDebit" />
          <CxNumber xref={React.useRef(null)} label={trans('field.credit_transfer_s')} dbfield="n_ps.nuPurseCreTran" />
          <CxNumber xref={React.useRef(null)} label={trans('field.money_transfer_s')} dbfield="n_ps.nuPurseMonTran" />
        </CxAccordion>

        <CxTable
          url="pss/components"
          filter={`r_ps_ps.inPs='${storePs.inPs}'`}
          onOpenForm={openFormComponent}
          hasButtonAdd={isFullAccess()}
          disabledUntilUpgrade={!FxEdition.isInEdition('PSS_cmp')}
          columns={[
            { id: "0", Header: "ID", accessor: "irPsPs", show: false },
            { id: "1", Header: '_inPsAttached', accessor: "inPsAttached", show: false },
            { id: "2", Header: trans('field.component'), accessor: "txPsAttached", width: '70%' },
            { id: "3", Header: trans('field.quantity'), accessor: "nuQuantity", width: '30%', align: 'right' }
          ]}
        />

        {isApp('vett') &&
          <CxTable
            url="pss/rx_auto"
            filter={`t_rx_auto.inPs='${storePs.inPs}'`}
            onOpenForm={openFormRxAuto}
            hasButtonAdd={isFullAccess()}
            //disabledUntilUpgrade={!FxEdition.isInEdition('CLI_rxs')}
            columns={[
              { id: "0", Header: "ID", accessor: "itRxAuto", show: false },
              { id: "1", Header: `${trans('field.rx')}-${trans('field.rx_regime')}`, accessor: "txRegime", width: '100%' },
              /* { id: "2", Header: trans('field.rx_drug_strength'), accessor: "txDrugStrength", width: '50%' }, */
            ]}
          />
        }

      </GxForm>

      <CxSnackbar xref={r_snack_ask_save} severity="error" value={trans('msg.save_before')} />
      <CxSnackbar xref={r_snack_exists} severity="error" value={trans('msg.existing_x', 'field.ps')} />

      <CxDlgAlert xref={r_dlg_alert} />
      <GxPrintLong xref={r_gx_print_long} />

      <CxDlgTable
        xref={r_dlgtable_ps_to_clone}
        open={dlgPsToClone}
        onSelect={doClone}
        hasSearchBox={true}
        onClose={() => setDlgPsToClone(false)}
        url={'pss'}
        tooltip={`${trans('field.ps')} / ${trans('field.code')}`}
        columns={[
          { id: "0", Header: "ID", accessor: "inPs", show: false },
          { id: "1", Header: trans('field.ps'), accessor: row => r_dlgtable_ps_to_clone.current.getTable().highlightText(row["txPs"]), width: '50%' },
          { id: "2", Header: trans('field.code'), accessor: row => r_dlgtable_ps_to_clone.current.getTable().highlightText(row["txPsCode"]), width: '25%', align: 'right' },
          { id: "4", Header: trans('field.total'), accessor: row => FxTable.cellTo2r00(row["theTotalPrice"]), width: '25%', align: 'right' },
          { id: "W", Header: "_composite", accessor: row => Boolean(row["boComposite"] === '1'), show: false },
          { id: "G", Header: "_frequency", accessor: row => Boolean(row["nuFrequency"] > 0), show: false },
          { id: "R", Header: "_stock_below_min", accessor: row => Boolean(row["boStockControl"] === '1') && Boolean(row["nuStock"] < row["nuStockMin"]), show: false },
        ]}
        title={`${trans('msg.select_ps_to_clone')}...`}
      />

    </div>
  );

}

export default MxPsForm;
