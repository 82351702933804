import React from 'react';
import GxList from '../../gx/GxList';
import { useTranslation } from '../../hx/useTranslation';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import * as FxTable from '../../fx/FxTable';
import { useNav } from '../../hx/useNav';
import { useWindowSize } from '../../hx/useWindowSize';
import { clearStore, StoreClient, StoreCns } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import GxDlgSelectClient from '../../gx/GxDlgSelectClient';
import GxIconButtonLastClient from '../../gx/GxIconButtonLastClient';


const MxActivityVettC = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navTo }] = useNav();

  const size = useWindowSize();
  const [storeClient, setStoreClient] = useSessionStorage('StoreClient', StoreClient);
  const [storeCns, setStoreCns] = useSessionStorage('StoreCns', StoreCns);
  
  const r_list = React.useRef(null);
  const r_dlg_select_client = React.useRef(null);

  React.useEffect(() => {
    clearStore('StoreCns', storeCns);
    // eslint-disable-next-line
  }, []);

  const openForm = async (op, row) => {
    if (row === null) {
      FxLog.infox('MxActivityVettC.openForm', '...op:', op);
      let iv = {};
      iv = { txType: op.split(':')[1] };
      r_dlg_select_client.current.setIntermediateValues(iv);
      r_dlg_select_client.current.setOpen(true);
    } else {
      FxLog.infox('MxActivityVettC.openForm', '...row:', row);
      storeClient.inClient = row.original.inClient;
      storeClient.txClient = row.original.txClient;
      storeClient.inAnimal = row.original.animal_inAnimal;
      storeClient.txAnimal = row.original.animal_txAnimal;
      Promise.all([setStoreClient(storeClient)]).then((result) => {
        FxLog.infox('MxActivityVettC.openForm', '...storeClient:', storeClient);
        storeCns.inCns = row.original._id;
        setStoreCns(storeCns);
        if (row.original.txType === 'G') navTo("animal-cns-grl-form");
        if (row.original.txType === 'H') navTo("animal-cns-hos-form");
      });
    }
  }

  const getCnsOptions = () => {
    return [
      { id: 'G', text: trans('field.cns_grl') },
      { id: 'H', text: trans('field.cns_hos') },
    ];
  }

  const getTableColumns = () => {
    let cols = [];
    let id = -1;
    try{
    cols.push({ id: id++, Header: "ID", accessor: "_id", show: false });
    cols.push({ id: "inClient", Header: "inClient", accessor: "inClient", show: false });
    cols.push({ id: "inAnimal", Header: "inAnimal", accessor: "inAnimal", show: false });
    cols.push({ id: id++, Header: trans('field.client'), accessor: row => r_list.current.getTable().highlightText(row["txClient"]), width: '20%' });
    cols.push({ id: id++, Header: trans('field.patient'), accessor: row => r_list.current.getTable().highlightText(row["animal_txAnimal"]), width: '10%' });

    if (size.isLarge) { // PANTALLA GRANDE
      cols.push({ id: id++, Header: trans('field.date'), accessor: row => (FxTable.cellToLocalDate(row["dtFrom"])), width: '10%' });
      cols.push({ id: id++, Header: trans('field.type'), accessor: row => FxTable.replace(row["txType"], getCnsOptions()), width: '10%' });
      cols.push({ id: id++, Header: trans('field.remarks'), accessor: row => row["txRemarks"], width: '50%' });
      cols.push({ id: "G", Header: "automatic", accessor: row => Boolean(row["itStatusCns"] === '000002.SRV'), show: false });

    } else { // PANTALLA PEQUEÑA
      cols.push({ id: id++, Header: trans('field.date'), accessor: row => (FxTable.cellToLocalDate(row["dtFrom"])), width: '10%' });
    }
    return cols;

    } catch(error) {
      FxLog.errorx('MxActivityVettC.getTableColumns', error)
    }
    return cols;
  }

  return (
    <div>
      <GxList
        moduleName={`${trans('field.activity')}: ${trans('field.cnss')}`}
        xref={r_list}
        tooltip={`${trans('field.client')} / ${trans('field.patient')}`}
        fullscreen
        url={`activity/vett/typelist/c`}
        onOpenForm={openForm}
        colorRefs={['table_cell_black|' + trans('field.pending'), 'table_cell_green|' + trans('field.finished_1')]}
        columns={getTableColumns()}
        buttonAddOptions={[
          { id: 'G', text: trans('field.cns_grl') },
          { id: 'H', text: trans('field.cns_hos') },
        ]}
        onOptions={() => {}} // para que muestre botón de opciones.
        optionalFromTo={true}
        optionalFromToButtonToday={true}
        optionalFromToButtonLast7Days={true}
        optionalFromToButtonLast30Days={true}
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <GxIconButtonLastClient theList={r_list} />
          </div>
        }
        speechExternal
      />

      <GxDlgSelectClient xref={r_dlg_select_client} 
        includeAnimal={true}
        onSelect={(row, intermediateValues) => {
          FxLog.infox('MxActivityVettC.return.GxDlgSelectClient', '...row:', row);
          row.original.txType = intermediateValues.txType; // para consultas
          FxLog.infox('MxActivityVettC.return.GxDlgSelectClient', '...intermediateValues.txType:', intermediateValues.txType);
          Promise.all([setStoreClient(storeClient)]).then((result) => {
            openForm(null, row);
          });
        }} 
      />

    </div>
  );

}

export default MxActivityVettC;
