import React from 'react';
import { useTranslation } from '../hx/useTranslation';
//import * as FxLog from '../fx/FxLog';
import CxDlgTable from '../cx/CxDlgTable';
import { isApp } from "../gx/GxStore";

/** Selector independiente lanzado desde código, por ejemplo para seleccionar cliente o empleado sin necesitar un CxSearcher */

const GxDlgSelectClient = (props) => {
  const [{ trans }] = useTranslation();
  const { onSelect } = props;

  const r_dlg = React.useRef(null);
  
  const [intermediateValues, setIntermediateValues] = React.useState(null); //para guardar estructura de valores intermedios.
  const [includeAnimal, setIncludeAnimal] = React.useState(props.includeAnimal && (isApp('vett') || isApp('spaw')));

  const [open, setOpen] = React.useState(false);

  React.useImperativeHandle(props.xref, () => {
    return {
      setIncludeAnimal, setOpen, setIntermediateValues
    };
  });

  return (
    <div>

      <CxDlgTable
        xref={r_dlg}
        title={props.title || trans('msg.select_x', includeAnimal ? `${trans('field.client')} / ${trans('field.patient')}` : `${trans('field.client')}`)}
        hasSearchBox={true}
        open={open}
        onClose={() => setOpen(false)}
        onSelect={(row) => {
          if (onSelect) {
            onSelect(row, intermediateValues);
            setOpen(false);
          }
        }}
        url={includeAnimal ? 'clients' : 'clients/searcher'}
        tooltip={includeAnimal ? `${trans('field.client')} / ${trans('field.patient')}` : `${trans('field.client')}`}
        columns={includeAnimal ?
          [
            { id: "inClient", Header: "inClient", accessor: "inClient", show: false },
            { id: "txClient", Header: trans('field.client'), accessor: row => r_dlg.current.getTable().highlightText(row["txClient"]), width: '70%' },
            { id: "animal_inAnimal", Header: "animal_inAnimal", accessor: "animal_inAnimal", show: false },
            { id: "animal_txAnimal", Header: trans('field.patient'), accessor: row => r_dlg.current.getTable().highlightText(row["animal_txAnimal"]), width: '30%' },
          ] : [
            { id: "inClient", Header: "inClient", accessor: "inClient", show: false },
            { id: "txClient", Header: trans('field.client'), accessor: row => r_dlg.current.getTable().highlightText(row["txClient"]), width: '100%' },
          ]
        }
        height='450px'
      />

    </div>
  );
}

export default GxDlgSelectClient;
