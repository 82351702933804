import React, { useImperativeHandle } from "react";
import * as FxFetch from '../fx/FxFetch';
import * as FxLog from '../fx/FxLog';
import * as FxNum from '../fx/FxNum';
import * as FxInet from '../fx/FxInet';
import CxDlgAlert from '../cx/CxDlgAlert';
import CxSnackbar from '../cx/CxSnackbar';
import CxBackdrop from '../cx/CxBackdrop';


const GxLongTaskWin = (props) => {
  const { onProgress, onEnd } = props;

  const SEC = 1000; // un segundo en milisegundos
  const timer = React.useRef(null);
  
  const r_dlg_alert = React.useRef(null);
  const r_snack_error = React.useRef(null);

  const [backdrop, setBackdrop] = React.useState(false);

  useImperativeHandle(props.xref, () => {
    return {
      doProcessStart, getDlgAlert,
    };
  });

  React.useEffect(() => {
    formLoad();

    return () => {
      runTimer(false);
    }
    // eslint-disable-next-line
  }, []);

  const getDlgAlert = () => {
    return r_dlg_alert.current;
  }

  const formLoad = async () => {
    FxLog.infox("GxLongTaskWin.formLoad", "loading...");
  }

  const runTimer = (boStart) => {
    if (boStart) { //starting up timer...
      timer.current = setInterval(() => {
        if (FxInet.isOnline()) {
          doProgressCheck();
        }
      }, 0.5 * SEC);

    } else {
      if (timer.current) { //cleaning up timer...
        clearInterval(timer.current);
      }
    }
  }

  const doProcessStart = () => {
    runTimer(true);
    setBackdrop(true);
    r_dlg_alert.current.clear();
    r_dlg_alert.current.setHasButtonClose(false);
    r_dlg_alert.current.setAutoCloseMilliseconds(1500);
    r_dlg_alert.current.setOpen(true);
  }

  const doProgressCheck = () => {
    try {
      let record = {};
      record['txTaskName'] = props.txTaskName;
      Promise.all([FxFetch.doJsonX('long_task/get_progress', record)]).then((result) => {
        if (result[0]) {
          FxLog.infox("GxLongTaskWin.doProgressCheck", "...result[0]:", result[0]);
          let txProgressText = result[0].txProgressText;
          let txTaskMessage = result[0].txTaskMessage;
          let nuTaskPercentage = FxNum.toInt(result[0].nuTaskPercentage);
          let txResultError = result[0].txResultError;
          let txResultJson = result[0].txResultJson;

          doProgress(txProgressText, txTaskMessage, nuTaskPercentage);
          if (nuTaskPercentage === 100 || txResultError) {
            doClose(txResultJson, txResultError);
          }
        }
      });

    } catch (error) {
      FxLog.errorx("GxLongTaskWin.doProgressCheck", error);
    }
  }

  const doProgress = (txProgressText, txTaskMessage, nuTaskPercentage) => {
    r_dlg_alert.current.setText(txProgressText);
    if (onProgress) {
      onProgress(txProgressText, txTaskMessage, nuTaskPercentage);
    }
  }
  const doClose = (txResultJson, txResultError) => {
    runTimer(false);
    if (onEnd) {
      onEnd(txResultJson, txResultError);
    }
  }


  return (
    <div>
      <CxSnackbar xref={r_snack_error} severity="error" />
      <CxBackdrop open={backdrop} />
      <CxDlgAlert xref={r_dlg_alert} 
        width='var(--ibs_control_width_sm)' 
        onClose={() => setBackdrop(false)} 
        />
    </div>
  );
}

export default GxLongTaskWin;
