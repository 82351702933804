import React from 'react';
import { useTranslation } from '../hx/useTranslation';
import * as FxFetch from '../fx/FxFetch';
import * as FxEdition from '../fx/FxEdition';
import * as FxLog from '../fx/FxLog';
import * as FxSms from '../fx/FxSms';
import CxDlgAlert from '../cx/CxDlgAlert';
import { StoreFile, } from "./GxStore";
import { useSessionStorage } from '../hx/useSessionStorage';
import { CiMail } from "react-icons/ci";
import CxDialog from '../cx/CxDialog';
import CxSnackbar from '../cx/CxSnackbar';
import CxRadio from '../cx/CxRadio';
import CxStatic from '../cx/CxStatic';
import CxInput from '../cx/CxInput';
import CxInputTemplate from '../cx/CxInputTemplate';
import CxDlgUpgrade from '../cx/CxDlgUpgrade';
import CxLink from '../cx/CxLink';
import CxDlgSmsPurchase from './GxDlgSmsPurchase';


const GxDlgSend = (props) => {
  const [{ trans }] = useTranslation();

  const r_dlg_alert_save_email = React.useRef(null);
  const r_dlg_alert_save_mobile = React.useRef(null);
  const r_dlg_alert_sending = React.useRef(null);
  const r_dlg_upgrade = React.useRef(null);
  const r_dlg_sms_purchase = React.useRef(null);

  const rf_sms_credits = React.useRef(null);

  const r_snack_required = React.useRef(null);

  const [smsCredits, setSmsCredits] = React.useState('');
  const [dlgSend, setDlgSend] = React.useState(false);
  const [sendMethod, setSendMethod] = React.useState('EM');
  const rf_send_method = React.useRef(null);
  const rf_send_email_address = React.useRef(null);
  const rf_send_mobile = React.useRef(null);
  const rf_send_email_subject = React.useRef(null);
  const rf_send_content = React.useRef(null);

  const [storeFile, setStoreFile] = useSessionStorage('StoreFile', StoreFile);

  React.useEffect(() => {
    doCheckSmsCredits();

    // eslint-disable-next-line
  }, []);

  React.useImperativeHandle(props.xref, () => {
    return {
      setOpen, setStore,
    };
  });

  const setOpen = (boOpen) => {
    setDlgSend(boOpen);
  };

  const setStore = (store) => {
    setStoreFile(store);
  };

  const isClientEmailMissing = () => {
    return storeFile.addressee && !storeFile.addressee.addressee_txEmail && storeFile.addressee.addressee_xxType === 'client' && storeFile.addressee.addressee_xxId;
  }

  const isClientMobileMissing = () => {
    return storeFile.addressee && !storeFile.addressee.addressee_txMobile && storeFile.addressee.addressee_xxType === 'client' && storeFile.addressee.addressee_xxId;
  }

  const hasSendRequired = () => {
    if (sendMethod === 'EM' && rf_send_email_address.current.isEmpty()) {
      r_snack_required.current.setVal(trans('field.required') + ": " + rf_send_email_address.current.getLabel());
      r_snack_required.current.setOpen(true);
      return true;
    }
    if (sendMethod === 'EM' && rf_send_email_subject.current.isEmpty()) {
      r_snack_required.current.setVal(trans('field.required') + ": " + rf_send_email_subject.current.getLabel());
      r_snack_required.current.setOpen(true);
      return true;
    }
    if (sendMethod === 'WA' && rf_send_mobile.current.isEmpty()) {
      r_snack_required.current.setVal(trans('field.required') + ": " + rf_send_mobile.current.getLabel());
      r_snack_required.current.setOpen(true);
      return true;
    }
    if (rf_send_content.current.isEmpty()) {
      r_snack_required.current.setVal(trans('field.required') + ": " + rf_send_content.current.getLabel());
      r_snack_required.current.setOpen(true);
      return true;
    }
    return false;
  }

  const doSaveClientEmail = () => {
    let filter = {};
    let record = {};
    filter['table'] = 'n_client';
    filter['where'] = `inClient='${storeFile.addressee.addressee_xxId}'`;
    record['txEmail'] = rf_send_email_address.current.getVal();
    Promise.all([FxFetch.doJsonF('general/cu', filter, record)]).then((result) => {
      if (result[0].theValue === 'OK') {
        storeFile.addressee.addressee_txEmail = rf_send_email_address.current.getVal();
        Promise.all([setStoreFile(storeFile)]).then((result) => {
          r_dlg_alert_save_email.current.setOpen(false);
          doSend();
        });
      }
    });
  }

  const doSaveClientMobile = () => {
    let filter = {};
    let record = {};
    filter['table'] = 'n_client';
    filter['where'] = `inClient='${storeFile.addressee.addressee_xxId}'`;
    record['txMobile'] = rf_send_mobile.current.getVal();
    Promise.all([FxFetch.doJsonF('general/cu', filter, record)]).then((result) => {
      if (result[0].theValue === 'OK') {
        storeFile.addressee.addressee_txMobile = rf_send_mobile.current.getVal();
        Promise.all([setStoreFile(storeFile)]).then((result) => {
          r_dlg_alert_save_mobile.current.setOpen(false);
          doSend();
        });
      }
    });
  }

  const doCheckSmsCredits = () => {
    try {
      Promise.all([FxSms.getCredits()]).then((result) => {
        setSmsCredits(result);
      });

    } catch (error) {
      FxLog.errorx("GxDlgSend.doCheckSmsCredits", error);
    }
  };

  const doSmsPurchase = () => {
    setDlgSend(false);
    r_dlg_sms_purchase.current.setOpen(true);
  }

  const doSend = async () => {
    try {
      if (hasSendRequired()) return;

      setDlgSend(false);

      if (!storeFile.addressee) {
        storeFile.addressee = { addressee_txName: '', addressee_txEmail: '', addressee_txMobile: '', addressee_txCity: '', addressee_txState: '', addressee_txCountry: '', }
      }

      // ---------------> ENVÍA EMAIL
      // --------------------------------------------------------------------------------------------------------
      if (sendMethod === 'EM') {
        storeFile.addressee.addressee_txEmail = rf_send_email_address.current.getVal();
        Promise.all([setStoreFile(storeFile)]).then((result) => {
          let filter = { ...storeFile.filter, ...storeFile.addressee };
          filter['txEmailSubject'] = rf_send_email_subject.current.getVal();
          filter['txEmailContent'] = rf_send_content.current.getVal();

          let record = {};
          let strBase64 = storeFile.obPDF
          record['base64'] = strBase64 ? strBase64.replace('data:application/pdf;base64,', '') : null;

          r_dlg_alert_sending.current.setText(trans('msg.sending_x', 'field.email'));
          r_dlg_alert_sending.current.setTextColor('var(--ibs_color_blue)');
          r_dlg_alert_sending.current.setHasButtonClose(false);
          r_dlg_alert_sending.current.setOpen(true);

          return Promise.all([FxFetch.doJsonF('dlg_send/send/email', filter, record)]).then((result) => {
            if (result[0]) {
              let txEmailResult = result[0].theStatus;
              // EMAIL ---------------------------------------------------
              // muestra mensaje de éxito o fallo para el email enviado.
              if (txEmailResult === 'OK') {
                r_dlg_alert_sending.current.setText(trans('msg.operation_successful'));
                r_dlg_alert_sending.current.setTextColor('var(--ibs_color_green)');
              } else {
                r_dlg_alert_sending.current.setText(trans('msg.operation_failed'));
                r_dlg_alert_sending.current.setTextColor('var(--ibs_color_red)');
              }
              r_dlg_alert_sending.current.setHasButtonClose(true);
            } else {
              r_dlg_alert_sending.current.setOpen(false);
            }
          });
        });
      }

      // ---------------> ENVÍA SMS
      // --------------------------------------------------------------------------------------------------------
      if (sendMethod === 'SM') {
        storeFile.addressee.addressee_txMobile = rf_send_mobile.current.getVal();
        Promise.all([setStoreFile(storeFile)]).then((result) => {
          let filter = { ...storeFile.filter, ...storeFile.addressee };
          filter['txSmsContent'] = rf_send_content.current.getVal();

          let record = {};
          let strBase64 = storeFile.obPDF
          record['base64'] = strBase64 ? strBase64.replace('data:application/pdf;base64,', '') : null;

          r_dlg_alert_sending.current.setText(trans('msg.sending_x', 'field.sms'));
          r_dlg_alert_sending.current.setTextColor('var(--ibs_color_blue)');
          r_dlg_alert_sending.current.setHasButtonClose(false);
          r_dlg_alert_sending.current.setOpen(true);

          return Promise.all([FxFetch.doJsonF('dlg_send/send/sms', filter, record)]).then((result) => {
            if (result[0]) {
              let txSmsResult = result[0].theStatus;
              // SMS ---------------------------------------------------
              // muestra mensaje de éxito o fallo para el sms enviado.
              if (txSmsResult === 'OK') {
                r_dlg_alert_sending.current.setText(trans('msg.operation_successful'));
                r_dlg_alert_sending.current.setTextColor('var(--ibs_color_green)');
              } else {
                r_dlg_alert_sending.current.setText(trans('msg.operation_failed'));
                r_dlg_alert_sending.current.setTextColor('var(--ibs_color_red)');
              }
              r_dlg_alert_sending.current.setHasButtonClose(true);
            } else {
              r_dlg_alert_sending.current.setOpen(false);
            }
          });
        });
      }

      // ---------------> ENVÍA WHATSAPP
      // --------------------------------------------------------------------------------------------------------
      if (sendMethod === 'WA') {
        if (!FxEdition.isInEdition('COM_wha')) {
          r_dlg_upgrade.current.setOpen(true);
          return;
        }
        storeFile.addressee.addressee_txMobile = rf_send_mobile.current.getVal();
        Promise.all([setStoreFile(storeFile)]).then((result) => {
          let theMobile = rf_send_mobile.current.getVal();
          let theContent = rf_send_content.current.getVal();
          let filter = { ...storeFile.filter, ...storeFile.addressee };

          let record = {};

          if (storeFile.obPDF) {
            // con adjunto
            let strBase64 = storeFile.obPDF
            record['base64'] = strBase64 ? strBase64.replace('data:application/pdf;base64,', '') : null;
            record['txFileNameInput'] = storeFile.txFileNameInput;

            return Promise.all([FxFetch.doJsonF('dlg_send/send/blob_upload', filter, record)]).then((result) => {
              if (result[0].theFilePath) {
                let theUrl = 'https://www.iberical.com/' + result[0].theFilePath;
                theContent = theContent + '%0a' + theUrl;
                // abre whatsapp
                let url = `https://web.whatsapp.com/send?phone=${theMobile}&text=${theContent}`;
                const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
                if (newWindow) newWindow.opener = null;
              }
            });

          } else {
            // sin adjunto
            let url = `https://web.whatsapp.com/send?phone=${theMobile}&text=${theContent}`;
            const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null;
          }

        });
      }

    } catch (error) {
      FxLog.errorx("GxDlgSend.doSend", error);
    }
  }

  return (
    <div>

      <CxDlgAlert xref={r_dlg_alert_save_email} text={trans('msg.ask_save_x_in_file', 'field.email')} onYes={doSaveClientEmail} onNo={() => {
        storeFile.addressee.addressee_txEmail = rf_send_email_address.current.getVal();
        Promise.all([setStoreFile(storeFile)]).then((result) => {
          r_dlg_alert_save_email.current.setOpen(false);
          Promise.all([doSend()]).then((result) => {
            storeFile.addressee.addressee_txEmail = '';
            setStoreFile(storeFile);
          });
        });
      }} />
      <CxDlgAlert xref={r_dlg_alert_save_mobile} text={trans('msg.ask_save_x_in_file', 'field.mobile')} onYes={doSaveClientMobile} onNo={() => {
        storeFile.addressee.addressee_txMobile = rf_send_mobile.current.getVal();
        Promise.all([setStoreFile(storeFile)]).then((result) => {
          r_dlg_alert_save_mobile.current.setOpen(false);
          Promise.all([doSend()]).then((result) => {
            storeFile.addressee.addressee_txMobile = '';
            setStoreFile(storeFile);
          });
        });
      }} />

      <CxDialog
        title={trans('field.send')}
        open={dlgSend}
        onClose={() => setDlgSend(false)}
        onOk={() => {
          if (!hasSendRequired()) {
            if (sendMethod === 'EM' && isClientEmailMissing() && !rf_send_email_address.current.isEmpty()) {
              // ofrece guardar email de cliente
              r_dlg_alert_save_email.current.setOpen(true);
              return;
            }
            if (sendMethod === 'WA' && isClientMobileMissing() && !rf_send_mobile.current.isEmpty()) {
              // ofrece guardar móvil de cliente
              r_dlg_alert_save_mobile.current.setOpen(true);
              return;
            }
            doSend();
          }
        }
        }
        height='430px'
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', width: 'var(--ibs_control_width_lg)' }}>
          <CxRadio xref={rf_send_method}
            orientation="row"
            list={[
              { id: 'EM', type: trans('field.email') },
              { id: 'SM', type: trans('field.sms') },
              { id: 'WA', type: trans('field.whatsapp') },
            ]}
            onSelection={(id) => setSendMethod(id)}
            defaultValue={sendMethod}
          />

          {sendMethod === 'EM' && isClientEmailMissing() && <CxStatic label={trans('msg.no_x_email_in_file', 'field.client').toUpperCase()} border='none' fontcolor='var(--ibs_color_red)' fontsize='10px' />}
          {sendMethod === 'SM' && isClientMobileMissing() && <CxStatic label={trans('msg.no_x_mobile_in_file', 'field.client').toUpperCase()} border='none' fontcolor='var(--ibs_color_red)' fontsize='10px' />}
          {sendMethod === 'WA' && isClientMobileMissing() && <CxStatic label={trans('msg.no_x_mobile_in_file', 'field.client').toUpperCase()} border='none' fontcolor='var(--ibs_color_red)' fontsize='10px' />}

          {sendMethod === 'EM' && <CxInput xref={rf_send_email_address} label={trans('field.email')} width="var(--ibs_control_width_lg)" defaultValue={storeFile.addressee && storeFile.addressee.addressee_txEmail} required />}
          {sendMethod === 'SM' && <CxInput xref={rf_send_mobile} label={trans('field.mobile')} width="var(--ibs_control_width_lg)" defaultValue={storeFile.addressee && storeFile.addressee.addressee_txMobile} required />}
          {sendMethod === 'WA' && <CxInput xref={rf_send_mobile} label={trans('field.mobile')} width="var(--ibs_control_width_lg)" defaultValue={storeFile.addressee && storeFile.addressee.addressee_txMobile} required />}

          {sendMethod === 'EM' && <CxInput xref={rf_send_email_subject} label={trans('field.subject')} width="var(--ibs_control_width_lg)" required />}

          <CxInputTemplate xref={rf_send_content}
            label={trans('field.content')}
            minRows={5}
            width="var(--ibs_control_width_lg)"
            tpl_table={"n_email_tpl"}
            tpl_col_id={"inEmailTpl"}
            tpl_col_tx={"txName"}
            tpl_content_field={"txContent"}
            onTemplateSelected={(name, cont) => {
              if (sendMethod === 'EM') rf_send_email_subject.current.setVal(name);
              rf_send_content.current.setVal(cont);
            }}
            hasTplButtonAdd
            required
          />

          {sendMethod === 'SM' && <CxStatic xref={rf_sms_credits} label={trans('field.credits') + ': ' + (smsCredits||'0.00')} border='none' fontcolor={smsCredits ? 'var(--ibs_color_blue)' : 'var(--ibs_color_red)'} fontsize='14px' />}
          {sendMethod === 'SM' && <CxLink label={`[${trans('field.purchase').toUpperCase()}]`} style={{ margin: '0 0 0 1px', fontSize: '12px' }} onClick={doSmsPurchase} />}

        </div>
      </CxDialog>

      <CxDlgAlert xref={r_dlg_alert_sending} icon={<CiMail fontSize="30px" style={{ fill: 'var(--ibs_color_black)', paddingBottom: '10px' }} />} />
      <CxDlgUpgrade xref={r_dlg_upgrade} />
      <CxDlgSmsPurchase xref={r_dlg_sms_purchase} />

      <CxSnackbar xref={r_snack_required} severity="error" />

    </div>
  );
}

export default GxDlgSend;
