import React from 'react';
import CxSnackbar from '../../cx/CxSnackbar';
import * as FxDat from '../../fx/FxDat';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import * as FxTable from '../../fx/FxTable';
import * as FxStr from '../../fx/FxStr';
import GxList from '../../gx/GxList';
import GxPrint from '../../gx/GxPrint';
import { StoreHistory } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import { useTranslation } from '../../hx/useTranslation';
import { useWindowSize } from '../../hx/useWindowSize';
import { isApp, } from "../../gx/GxStore";
import CxDlgAlert from '../../cx/CxDlgAlert';
import CxIconButton from '../../cx/CxIconButton';


const MxHistorySlsList = (props) => {
  const [{ trans }] = useTranslation();
  const size = useWindowSize();

  const [, updateState] = React.useState();
  const forceUIUpdate = React.useCallback(() => updateState({}), []);

  const r_list = React.useRef(null);
  let r_print = React.useRef(null);

  const r_snack_success = React.useRef(null);
  const r_snack_error = React.useRef(null);
  const r_dlg_alert = React.useRef(null);

  // eslint-disable-next-line no-unused-vars
  const [storeHistory, setStoreHistory] = useSessionStorage('StoreHistory', StoreHistory);


  React.useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const doPrintX = async (option) => {
    let filter = {};
    filter['reportFormat'] = `PDF`;
    filter['inBranch'] = storeHistory.inBranch;
    filter['dtFrom'] = storeHistory.dtFrom;
    filter['dtTo'] = storeHistory.dtTo;
    filter['txPs'] = storeHistory.txPs;
    filter['itPsGroup'] = storeHistory.itPsGroup;
    filter['itPsSubgroup'] = storeHistory.itPsSubgroup;
    filter['inClient'] = storeHistory.inClient; //sólo ventas
    let record = {};
    //let inClient = storeClient.inClient;
    r_print.current.setScale(1);
    r_print.current.setUrl('history_sls/report');
    //----------------
    Promise.all([
      r_print.current.setFilter(filter),
      r_print.current.setRecord(record),
      //r_print.current.setInClient(inClient)
    ]).then((result) => {
      r_print.current.doPrint();
    });
  }

  const getParams = () => {
    let params = '';
    params += `inBranch=${storeHistory.inBranch}`;
    params += `&dtFrom=${storeHistory.dtFrom}`;
    params += `&dtTo=${storeHistory.dtTo}`;
    params += `&txPs=${storeHistory.txPs}`;
    params += `&inClient=${storeHistory.inClient}`;
    params += `&inAnimal=${storeHistory.inAnimal}`;
    params += `&inSupplier=${storeHistory.inSupplier}`;
    params += `&itPsGroup=${storeHistory.itPsGroup}`;
    params += `&itPsSubgroup=${storeHistory.itPsSubgroup}`;

    return params;
  }

  const optionsSelected = (options) => {
    localStorage.setItem('MxHistorySlsList.addcol_txBranchId', options.includes('txBranchId') ? '1' : '0');
    localStorage.setItem('MxHistorySlsList.addcol_txTypeSeries', options.includes('txTypeSeries') ? '1' : '0');
    forceUIUpdate();
  }

  const getTableColumns = () => {
    let cols = [];
    let id = -1;
    cols.push({ id: id++, Header: "ID", accessor: "inInvoiceItem", show: false });
    cols.push({ id: 'hidden01', Header: "inInvoice", accessor: "inInvoice", show: false });
    if (size.isLarge) { // PANTALLA GRANDE
      cols.push({ id: "xx3", Header: trans('field.branch_id'), accessor: row => row["txBranchId"], width: '8%', show: Boolean(localStorage.getItem('MxHistorySlsList.addcol_txBranchId') === '1') });
      cols.push({ id: id++, Header: trans('field.date'), accessor: row => FxTable.cellToLocalDate(row["dtInvoice"]), width: '10%' });
      cols.push({ id: id++, Header: trans('field.client'), accessor: "txClient", width: '20%' });
      (isApp('vett') || isApp('spaw')) && cols.push({ id: id++, Header: trans('field.patient'), accessor: row => row["txAnimal"], width: '10%' });
      cols.push({ id: "xx4", Header: trans('field.series'), accessor: row => row["txTypeSeries"], width: '10%', show: Boolean(localStorage.getItem('MxHistorySlsList.addcol_txTypeSeries') === '1') });
      cols.push({ id: id++, Header: trans('field.invoice'), accessor: "txInvoice", width: '10%' });
      cols.push({ id: id++, Header: trans('field.ps'), accessor: row => FxStr.highlightSearch(row["txPs"], storeHistory.txPs), width: '24%' });
      cols.push({ id: id++, Header: trans('field.lot'), accessor: "txLotCode", width: '8%' });
      cols.push({ id: id++, Header: trans('field.expiration'), accessor: row => FxTable.cellToLocalDate(row["dtLotExpr"]), width: '8%' });
      cols.push({ id: id++, Header: trans('field.quantity_s'), accessor: row => (FxTable.cellTo2r00(row["nuQuantity"])), width: '10%', align: 'right' });
      cols.push({ id: id++, Header: trans('field.total'), accessor: row => (FxTable.cellTo2r00(row["nuAmount"])), width: '10%', align: 'right' });
    } else { // PANTALLA PEQUEÑA
      cols.push({ id: id++, Header: trans('field.date'), accessor: row => FxTable.cellToLocalDate(row["dtInvoice"]), width: '25%' });
      cols.push({ id: id++, Header: trans('field.ps'), accessor: row => FxStr.highlightSearch(row["txPs"], storeHistory.txPs), width: '55%' });
      cols.push({ id: id++, Header: trans('field.total'), accessor: row => (FxTable.cellTo2r00(row["nuAmount"])), width: '20%', align: 'right' });
    }
    cols.push({ id: 'G', Header: "theStatus", accessor: row => Boolean(row["inReceipt"]), show: false });
    return cols;
  }

  return (
    <div>
      <GxList
        moduleName={`${trans('field.history')}-${trans('field.sales')}: ${FxDat.toLocalDate(storeHistory.dtFrom)} / ${FxDat.toLocalDate(storeHistory.dtTo)}`}
        xref={r_list}
        fullscreen
        url="history_sls"
        hasSearchBox={false}
        hasButtonAdd={false}
        addParams={getParams()}
        colorRefs={['table_cell_green|' + trans('field.receipted')]}
        disabled={true}
        columns={getTableColumns()}
        optionalColumns={[
          { id: 'txBranchId', name: trans('field.branch_id'), checked: localStorage.getItem('MxHistorySlsList.addcol_txBranchId') },
          { id: 'txTypeSeries', name: trans('field.series'), checked: localStorage.getItem('MxHistorySlsList.addcol_txTypeSeries') },
        ]}
        onOptions={optionsSelected}
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>

            {/* botón de impresión */}
            <CxIconButton type="print" classType="ibs_iconbutton_toolbar" onClick={doPrintX} tooltip={trans('field.print')} />

          </div>
        }
      >
      </GxList>

      <CxDlgAlert xref={r_dlg_alert} />
      <GxPrint xref={r_print}/>

      <CxSnackbar xref={r_snack_success} severity="success" value={trans('msg.operation_successful')} />
      <CxSnackbar xref={r_snack_error} severity="error" />

    </div>
  );

}

export default MxHistorySlsList;
