import React from 'react';
import { useTranslation } from '../../hx/useTranslation';
import CxSearcher from '../../cx/CxSearcher';
import CxSnackbar from '../../cx/CxSnackbar';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import * as FxFetch from '../../fx/FxFetch';
import '../../../styles/main.css';
import GxForm from '../../gx/GxForm';
import CxIconButton from '../../cx/CxIconButton';
//import { useNav } from '../../hx/useNav';


const MxSettAnimalMergeBreedForm = (props) => {
  const [{ trans }] = useTranslation();
  //const [{ navBack }] = useNav();
  const r_snack_required = React.useRef(null);
  const r_snack_same_item = React.useRef(null);
  const r_snack_op_in_progress = React.useRef(null);
  const r_snack_op_successful = React.useRef(null);
  let r_form = React.useRef(null);

  const rf_txTypeAnimalBreedToKeep = React.useRef(null);
  const rf_txTypeAnimalBreedToDelete = React.useRef(null);


  React.useEffect(() => {
    // eslint-disable-next-line
  }, []);
  
  const doMerge = () => {
    // chequea requeridos
    if (rf_txTypeAnimalBreedToKeep.current.isEmpty()) {
      r_snack_required.current.setVal(trans('field.required') + ": " + rf_txTypeAnimalBreedToKeep.current.getLabel());
      r_snack_required.current.setOpen(true);
      return;
    }
    if (rf_txTypeAnimalBreedToDelete.current.isEmpty()) {
      r_snack_required.current.setVal(trans('field.required') + ": " + rf_txTypeAnimalBreedToDelete.current.getLabel());
      r_snack_required.current.setOpen(true);
      return;
    }
    if (rf_txTypeAnimalBreedToKeep.current.getKey()===rf_txTypeAnimalBreedToDelete.current.getKey()) {
      r_snack_same_item.current.setOpen(true);
      return;
    }
    
    let itAnimalBreed_toKeep = rf_txTypeAnimalBreedToKeep.current.getKey();
    let itAnimalBreed_toDelete = rf_txTypeAnimalBreedToDelete.current.getKey();

    let record = {};
    record['itAnimalBreed_toKeep'] = itAnimalBreed_toKeep;
    record['itAnimalBreed_toDelete'] = itAnimalBreed_toDelete;
    r_snack_op_in_progress.current.setOpen(true);
    Promise.all([FxFetch.doJsonX(`/sett/animal/merge/breed/do`, record)]).then((result) => {
      rf_txTypeAnimalBreedToKeep.current.clear();
      rf_txTypeAnimalBreedToDelete.current.clear();
      r_snack_op_in_progress.current.setOpen(false);
      r_snack_op_successful.current.setOpen(true);
    });
  }

  return (
    <div>
      <GxForm
        moduleName={`${trans('field.merge_x', 'field.breeds')}`}
        xref={r_form}
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <CxIconButton type="issue" classType="ibs_iconbutton_toolbar" onClick={doMerge} tooltip={trans('field.merge_duplicates')} />
          </div>
        }
      >

      <CxSearcher xref={rf_txTypeAnimalBreedToKeep}
        label={trans('field.keep')}
        url="sett/animal_breed" 
        col_id="itAnimalBreed" 
        col_tx="txTypeAnimalBreed"
        tooltip={`${trans('field.breed')} / ${trans('field.species')}`}
        columns={[
          { id: "0", Header: "ID", accessor: "itAnimalBreed", show: false },
          { id: "1", Header: trans('field.breed'), accessor: row => rf_txTypeAnimalBreedToKeep.current.getTable().highlightText(row["txTypeAnimalBreed"]), width: '50%' },
          { id: "2", Header: trans('field.species'), accessor: row => rf_txTypeAnimalBreedToKeep.current.getTable().highlightText(row["txTypeAnimalSpecies"]), width: '50%' },
        ]}
        width='var(--ibs_control_width_md)'
        required
      />

      <CxSearcher xref={rf_txTypeAnimalBreedToDelete}
        label={trans('field.delete')}
        url="sett/animal_breed" 
        col_id="itAnimalBreed" 
        col_tx="txTypeAnimalBreed"
        tooltip={`${trans('field.breed')} / ${trans('field.species')}`}
        columns={[
          { id: "0", Header: "ID", accessor: "itAnimalBreed", show: false },
          { id: "1", Header: trans('field.breed'), accessor: row => rf_txTypeAnimalBreedToDelete.current.getTable().highlightText(row["txTypeAnimalBreed"]), width: '50%' },
          { id: "2", Header: trans('field.species'), accessor: row => rf_txTypeAnimalBreedToDelete.current.getTable().highlightText(row["txTypeAnimalSpecies"]), width: '50%' },
        ]}
        width='var(--ibs_control_width_md)'
        required
      />

      </GxForm>

      <CxSnackbar xref={r_snack_required} severity="error" />
      <CxSnackbar xref={r_snack_same_item} severity="error" value={trans('msg.same_item')} />
      <CxSnackbar xref={r_snack_op_in_progress} severity="info" value={trans('msg.operation_in_progress')} autoHideDuration={10000} />
      <CxSnackbar xref={r_snack_op_successful} severity="success" value={trans('msg.operation_successful')} />
    </div>
  );

}

export default MxSettAnimalMergeBreedForm;
