import React from 'react';
import GxList from '../../gx/GxList';
import CxDlgTable from '../../cx/CxDlgTable';
import { useTranslation } from '../../hx/useTranslation';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import * as FxTable from '../../fx/FxTable';
import * as FxStr from '../../fx/FxStr';
import { useNav } from '../../hx/useNav';
import { useWindowSize } from '../../hx/useWindowSize';
import { clearStore, getStoreValue, StoreClient, StoreRxAuto } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import GxDlgSelectClient from '../../gx/GxDlgSelectClient';
import * as MxAnimalRxAutoFormH1 from '../files/MxAnimalRxAutoFormH1';
import GxIconButtonLastClient from '../../gx/GxIconButtonLastClient';


const MxActivityVettR = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navTo }] = useNav();

  const isSpain = () => { return Boolean(getStoreValue('StoreSession', 'txIdi').startsWith('es.') || getStoreValue('StoreSession', 'txIdi').startsWith('xx.') ) }
  
  const size = useWindowSize();
  const [storeClient, setStoreClient] = useSessionStorage('StoreClient', StoreClient);
  const [storeRxAuto, setStoreRxAuto] = useSessionStorage('StoreRxAuto', StoreRxAuto);
  
  const r_list = React.useRef(null);
  let colorRefs = [];
  const r_dlg_select_client = React.useRef(null);
  const r_dlgtable_rxs_invoice = React.useRef(null);

  const [dlgRxsInvoice, setDlgRxsInvoice] = React.useState(false);

  React.useEffect(() => {
    clearStore('StoreRxAuto', storeRxAuto);

    colorRefs.push('table_cell_black|' + trans('field.manual'));
    colorRefs.push('table_cell_green|' + trans('field.automatic_0'));
    if (isSpain()) {
      colorRefs.push('table_cell_blue|Presvet - En proceso');
      colorRefs.push('table_cell_red|Presvet - Error');
    }

    // eslint-disable-next-line
  }, []);

  const openForm = async (op, row) => {
    if (row === null) {
      let iv = {};
      iv = { txType: op.split(':')[1], _id: null, txRxAuto: null, itSeriesRx: null };
      r_dlg_select_client.current.setIntermediateValues(iv);
      r_dlg_select_client.current.setOpen(true);
    } else {
      storeClient.inClient = row.original.inClient;
      storeClient.txClient = row.original.txClient;
      storeClient.inAnimal = row.original.animal_inAnimal;
      storeClient.txAnimal = row.original.animal_txAnimal;

      storeRxAuto.inClient = row.original.inClient;
      storeRxAuto.inAnimal = row.original.animal_inAnimal;
      storeRxAuto.txClient = row.original.txClient;
      storeRxAuto.txAnimal = row.original.animal_txAnimal;

      storeRxAuto.boFirstRender = true;
      storeRxAuto.boDataChanged = '0';
      storeRxAuto.boAuto = Boolean(row.original.inInvoice);

      if (row.original.txType === 'A') {
        storeRxAuto.txRxAuto = null;
        storeRxAuto.itSeriesRx = null;
        storeRxAuto.inInvoice = null;
      } else {
        storeRxAuto.inRxAuto = row.original._id;
        storeRxAuto.txRxAuto = row.original.txRxAuto;
        storeRxAuto.itSeriesRx = row.original.itSeriesRx;
      }

      Promise.all([setStoreClient(storeClient)]).then((result) => {
        FxLog.infox('MxActivityVettR.openForm', '...storeClient:', storeClient);
        Promise.all([MxAnimalRxAutoFormH1.load(storeRxAuto)]).then((result) => {
          Promise.all([setStoreRxAuto(storeRxAuto)]).then((result) => {
            if (row.original.txType === 'A') {
              setDlgRxsInvoice(true);
            } else {
              navTo("animal-rx-auto-form");
            }
          });
        });
      });
    }
  }

  const openFormRxsNewAuto = async (row) => {
    setDlgRxsInvoice(false);

    if (row) {
      storeRxAuto.inInvoice = row.original.inInvoice;
      Promise.all([MxAnimalRxAutoFormH1.loadFromInvoice(storeRxAuto)]).then((result) => {
        Promise.all([setStoreRxAuto(storeRxAuto)]).then((result) => {
          navTo("animal-rx-auto-form");
        });
      });
    }
  }

  const getTableColumns = () => {
    let cols = [];
    let id = -1;
    try{
    cols.push({ id: id++, Header: "ID", accessor: "_id", show: false });
    cols.push({ id: "inClient", Header: "inClient", accessor: "inClient", show: false });
    cols.push({ id: "inAnimal", Header: "inAnimal", accessor: "inAnimal", show: false });
    cols.push({ id: id++, Header: trans('field.client'), accessor: row => r_list.current.getTable().highlightText(row["txClient"]), width: '20%' });
    cols.push({ id: id++, Header: trans('field.patient'), accessor: row => r_list.current.getTable().highlightText(row["animal_txAnimal"]), width: '10%' });

    if (size.isLarge) { // PANTALLA GRANDE
      cols.push({ id: id++, Header: trans('field.date'), accessor: row => (FxTable.cellToLocalDate(row["dtRxAuto"])), width: '10%' });
      cols.push({ id: id++, Header: trans('field.rx'), accessor: row => row["txRxAuto"], width: '10%' });
      //cols.push({ id: id++, Header: trans('field.series'), accessor: row => row["txTypeSeriesRx"], width: '10%' });
      if (isSpain()) {
        cols.push({ id: id++, Header: 'Atb', accessor: row => (Boolean(row["hasAntibiotics"]) ? trans('field.yes') : trans('field.no')), width: '10%' });
        cols.push({ id: id++, Header: 'PresVet', accessor: row => (Boolean(row["hasAntibiotics"]) ? Boolean(FxStr.isNumeric(row["txCodePresvet"])) ? trans('field.yes') : trans('field.no'): ''), width: '10%' });
        cols.push({ id: "B", Header: "PresvetProcess", accessor: row => Boolean(row["txCodePresvet"].includes('PRESVET-IN-PROCESS') && !row["txCodePresvet"].includes('[')), show: false });
        cols.push({ id: "R", Header: "PresvetError", accessor: row => Boolean(row["txCodePresvet"].includes('[')), show: false });
      }
      cols.push({ id: id++, Header: trans('field.notes'), accessor: row => row["txNotes"], width: '50%' });
      cols.push({ id: "G", Header: "automatic", accessor: row => Boolean(row["inInvoice"]), show: false });

    } else { // PANTALLA PEQUEÑA
      cols.push({ id: id++, Header: trans('field.date'), accessor: row => (FxTable.cellToLocalDate(row["dtRxAuto"])), width: '10%' });
    }
    return cols;

    } catch(error) {
      FxLog.errorx('MxActivityVettR.getTableColumns', error)
    }
    return cols;
  }

  return (
    <div>
      <GxList
        moduleName={`${trans('field.activity')}: ${trans('field.rxs')}`}
        xref={r_list}
        tooltip={`${trans('field.client')} / ${trans('field.patient')}`}
        fullscreen
        url={`activity/vett/typelist/r`}
        onOpenForm={openForm}
        colorRefs={colorRefs}
        columns={getTableColumns()}
        buttonAddOptions={[
          { id: 'M', text: trans('field.manual') },
          { id: 'A', text: trans('field.automatic_0') },
        ]}
        onOptions={() => {}} // para que muestre botón de opciones.
        optionalFromTo={true}
        optionalFromToButtonToday={true}
        optionalFromToButtonLast7Days={true}
        optionalFromToButtonLast30Days={true}
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <GxIconButtonLastClient theList={r_list} />
          </div>
        }
        speechExternal
      />

      <GxDlgSelectClient xref={r_dlg_select_client} 
        includeAnimal={true}
        onSelect={(row, intermediateValues) => {
          FxLog.infox('MxActivityVettR.return.GxDlgSelectClient', '...intermediateValues:', intermediateValues);
          row.original.txType = intermediateValues.txType; // para recetas
          row.original._id = intermediateValues._id; // para recetas
          row.original.txRxAuto = intermediateValues.txRxAuto; // para recetas
          row.original.itSeriesRx = intermediateValues.itSeriesRx; // para recetas
          Promise.all([setStoreClient(storeClient)]).then((result) => {
            openForm(null, row);
          });
        }} 
      />

      <CxDlgTable
        xref={r_dlgtable_rxs_invoice}
        open={dlgRxsInvoice}
        onSelect={openFormRxsNewAuto}
        onClose={() => setDlgRxsInvoice(false)}
        url={'rx_auto/invoices'}
        filter={`n_invoice.inAnimal='${storeClient.inAnimal}'`}
        columns={[
          { id: "1", Header: trans('field.date'), accessor: row => FxTable.cellToLocalDate(row["dtInvoice"]), width: '50%' },
          { id: "2", Header: trans('field.invoice'), accessor: "txInvoice", width: '50%' },
        ]}
        title={trans('field.invoice')}
      />

    </div>
  );

}

export default MxActivityVettR;
