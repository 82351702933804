import { BsSearch } from "react-icons/bs";
import React, { useEffect, useRef, useState } from 'react';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import allLocales from '@fullcalendar/core/locales-all';
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import { makeStyles } from '@material-ui/core/styles';
import { useNav } from '../../hx/useNav';
import { useTranslation } from '../../hx/useTranslation';
import '../../../styles/FullCalendar/FullCalendar.css';
import CxSnackbar from '../../cx/CxSnackbar';
import CxIconButtonNew from '../../cx/CxIconButtonNew';
import CxDlgCalendar from '../../cx/CxDlgCalendar';
import CxDlgTable from '../../cx/CxDlgTable';
import * as FxFetch from '../../fx/FxFetch';
import * as FxLog from '../../fx/FxLog';
import * as FxDat from '../../fx/FxDat';
import * as FxTable from '../../fx/FxTable';
import { clearStore, getStoreValue, StoreCalendarEvent, StoreCalendarView, isApp } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import CxToolbar from '../../cx/CxToolbar';
import CxToolbarPopper from './../../cx/CxToolbarPopper';
import CxDlgCheckList from './../../cx/CxDlgCheckList';
import { useAccess } from '../../hx/useAccess';
import { BsCalendar } from "react-icons/bs";

const useStyles = makeStyles({
  calendar: {
    margin: 'var(--ibs_container_margin)',
    padding: '2px',
  },
});

const MxCalendarView = (props) => {
  const [{ trans }] = useTranslation();
  const [{ isFullAccess }] = useAccess(trans('field.calendar'));
  const [{ navTo }] = useNav();
  const MIN_WIDTH = 800;
  const classes = useStyles();

  const [calView, setCalView] = React.useState({ title: '', bg: '' });

  const [calEnabled, setCalEnabled] = useState(true);
  const [calCols, setCalCols] = useState([]);
  const [calColsSelected, setCalColsSelected] = useState(''); // for resource view
  const [calEvents, setCalEvents] = useState([]);

  const [dlgNewDate, setDlgNewDate] = useState(false);
  const [dlgSearch, setDlgSearch] = useState(false);

  const rf_toolbar = useRef(null);
  const rf_calendar = useRef(null);
  const rf_popper1 = useRef(null);
  const rf_popper2 = useRef(null);

  const r_dlg_calendar = useRef(null);

  const r_dlg_search = useRef(null);

  const r_snack_overlap = useRef(null);

  const r_calView = useRef('timeGridWeek');
  const r_calDate = useRef(null);

  const r_dlg_res_cols = useRef(null);

  const r_headerSize = useRef(190);

  // eslint-disable-next-line no-unused-vars
  const [storeCalendarView, setStoreCalendarView] = useSessionStorage('StoreCalendarView', StoreCalendarView);
  const [storeCalendarEvent, setStoreCalendarEvent] = useSessionStorage('StoreCalendarEvent', StoreCalendarEvent);


  const getHeaderToolbar = () => {
    return {
      center: window.innerWidth > MIN_WIDTH ? 'title' : '',
      right: window.innerWidth > MIN_WIDTH ? 'customButtonToday customButtonPrev,customButtonNext' : 'customButtonToday customButtonPrev,customButtonNext',
      left: window.innerWidth > MIN_WIDTH ? 'customButtonWeek,customButtonListWeek,customButtonDay,customButtonRes' : 'title'
    }
  }

  useEffect(() => {
    clearStore('StoreCalendarEvent', storeCalendarEvent);
    function handleResize(e) {
      r_calView.current = (window.innerWidth > MIN_WIDTH ? 'timeGridWeek' : 'timeGridDay');
      setView(r_calView.current);
      rf_calendar.current.getApi().setOption('headerToolbar', getHeaderToolbar());
      rf_calendar.current.getApi().setOption('contentHeight', window.innerHeight - r_headerSize.current);
      fetchEvents();
      r_dlg_res_cols.current.setOpen(false);
    }
    window.addEventListener('resize', handleResize);

    if (storeCalendarView.itCalendarCol) {
      // usuario viene desde un evento
      r_calView.current = (window.innerWidth > MIN_WIDTH ? storeCalendarView.selectedView : 'timeGridDay');
    } else {
      // usuario abrió cal desde el menú
      r_calView.current = (window.innerWidth > MIN_WIDTH ? 'timeGridWeek' : 'timeGridDay');
    }
    Promise.all([loadBranchSettings()]).then((result) => {
      setView(r_calView.current);
      loadCols();
    });

    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line
  }, []);

  const loadBranchSettings = async () => {
    try {
      let record = {};
      return Promise.all([FxFetch.doJsonX('cal/sett', record)]).then((result) => {
        if (result[0]) {
          storeCalendarView.boSingleCalendar = result[0].boSingleCalendar;
          storeCalendarView.txWeekDays = result[0].txWeekDays;
          storeCalendarView.txStartHour = result[0].txStartHour;
          storeCalendarView.txWorkTime1From = result[0].txWorkTime1From;
          storeCalendarView.txWorkTime1To = result[0].txWorkTime1To;
          storeCalendarView.txWorkTime2From = result[0].txWorkTime2From;
          storeCalendarView.txWorkTime2To = result[0].txWorkTime2To;
          storeCalendarView.nuIntervalMins = result[0].nuIntervalMins;
          setStoreCalendarView(storeCalendarView);
        }
      });

    } catch (error) {
      FxLog.errorx('MxCalendarView.loadBranchSettings', error);
    }
  }

  const loadCols = () => {
    try {
      let url = "cal/cols";
      let record = {};
      return Promise.all([FxFetch.doJsonX(url, record)]).then((result) => {
        if (result[0]) {
          setCalCols(result[0]);
          if (!storeCalendarView.itCalendarCol) {
            if (result[0][0]) {
              storeCalendarView.itCalendarCol = result[0][0].id;
              storeCalendarView.txTypeCalendarCol = result[0][0].title;
            } else {
              setCalEnabled(false);
            }
          }
          Promise.all([setStoreCalendarView(storeCalendarView)]).then(() => {
            rf_popper1.current.setVal(storeCalendarView.itCalendarCol);
            doDatesSet();
          });
        }
      });

    } catch (error) {
      FxLog.errorx('MxCalendarView.loadCols', error);
    }
  }

  const doResColsChoose = () => {
    let colsSelected = localStorage.getItem('MxCalendarView.colsSelected') || '';
    let calColsFinal = calCols.map((item) => (
      item = { ...item, checked: colsSelected.includes(item.id) ? '1' : '0' }
    ));
    r_dlg_res_cols.current.setList(calColsFinal);
    r_dlg_res_cols.current.setOpen(true);
  }
  const doResColsChanged = (colsSelected) => {
    localStorage.setItem('MxCalendarView.colsSelected', colsSelected);
    setCalColsSelected(colsSelected);
  }

  const doDisplayCol = (selectedCol) => {
    try {
      // usuario seleccionó col.
      storeCalendarView.itCalendarCol = selectedCol.split('|')[0];
      storeCalendarView.txTypeCalendarCol = selectedCol.split('|')[1];
      Promise.all([setStoreCalendarView(storeCalendarView)]).then((result) => {
        doDatesSet();
      });

    } catch (error) {
      FxLog.errorx('MxCalendarView.doDisplayCol', error);
    }
  }

  const doDateClick = (e) => {
    if (!calEnabled) {
      return;
    }
    if (e.resource) {
    }
    storeCalendarEvent.inCalendar = null;
    storeCalendarEvent.dtFrom = e.dateStr;

    if (r_calView.current === 'timeGridWeek' || r_calView.current === 'listWeek' || r_calView.current === 'timeGridDay') {
      // vista simple
      storeCalendarEvent.itCalendarCol = storeCalendarView.itCalendarCol;
      storeCalendarEvent.txTypeCalendarCol = storeCalendarView.txTypeCalendarCol;
    } else {
      // vista de recursos/columnas
      storeCalendarEvent.itCalendarCol = e.resource._resource.id;
      storeCalendarEvent.txTypeCalendarCol = e.resource._resource.title;
    }

    Promise.all([setStoreCalendarEvent(storeCalendarEvent)]).then((result) => {
      navTo("calendar-form");
    });
  }

  const doEventClick = (e) => {
    storeCalendarEvent.inCalendar = e.event.id;
    storeCalendarEvent.dtFrom = e.dateStr;

    if (r_calView.current === 'timeGridWeek' || r_calView.current === 'listWeek' || r_calView.current === 'timeGridDay') {
      // vista simple
      storeCalendarEvent.itCalendarCol = storeCalendarView.itCalendarCol;
      storeCalendarEvent.txTypeCalendarCol = storeCalendarView.txTypeCalendarCol;
    } else {
      // vista de recursos/columnas
      storeCalendarEvent.itCalendarCol = e.event._def.resourceIds[0];
      storeCalendarEvent.txTypeCalendarCol = e.event._def.title;
    }

    Promise.all([setStoreCalendarEvent(storeCalendarEvent)]).then((result) => {
      navTo("calendar-form");
    });
  }

  const doEventDrop = (e) => {
    try {

      //const { start, end } = e.oldEvent._instance.range;
      //console.log(start, end);

      const { start: newStart, end: newEnd } = e.event._instance.range;
      let inCalendar = e.event.id;
      let dtFrom = new Date(newStart).toISOString();
      dtFrom = `${dtFrom.substring(0, 10)} ${dtFrom.substring(11, 16)}:00`;
      let dtTo = new Date(newEnd).toISOString();
      dtTo = `${dtTo.substring(0, 10)} ${dtTo.substring(11, 16)}:00`;

      //-----------------------------------------------------------------------
      let url = "cal/m";
      let record = {};
      record['inCalendar'] = inCalendar;
      if (r_calView.current === 'timeGridWeek' || r_calView.current === 'listWeek' || r_calView.current === 'timeGridDay') {
        // vista simple
        record['itCalendarCol'] = storeCalendarView.itCalendarCol;
      } else {
        // vista de recursos/columnas
        if (e.newResource) {
          record['itCalendarCol'] = e.newResource._resource.id;
        }
      }

      record['dtFrom'] = dtFrom;
      record['dtTo'] = dtTo;
      // enviamos también los settings
      record['boSingleCalendar'] = storeCalendarView.boSingleCalendar;
      record['txWeekDays'] = storeCalendarView.txWeekDays;
      record['txStartHour'] = storeCalendarView.txStartHour;
      record['txWorkTime1From'] = storeCalendarView.txWorkTime1From;
      record['txWorkTime1To'] = storeCalendarView.txWorkTime1To;
      record['txWorkTime2From'] = storeCalendarView.txWorkTime2From;
      record['txWorkTime2To'] = storeCalendarView.txWorkTime2To;
      Promise.all([FxFetch.doJsonX(url, record)]).then((result) => {
        if (result[0]) {
        } else {
          e.revert();
          r_snack_overlap.current.setOpen(true);
        }
      });
      //-----------------------------------------------------------------------

    } catch (error) {
      FxLog.errorx("MxCalendarView.doEventDrop", error);
    }
  }

  const fetchEvents = async () => {
    let record = {};
    record['boSingleCalendar'] = storeCalendarView.boSingleCalendar;
    record['selectedDate'] = storeCalendarView.selectedDate;
    record['calView'] = r_calView.current;
    if (r_calView.current === 'timeGridWeek' || r_calView.current === 'listWeek' || r_calView.current === 'timeGridDay') {
      record['itCalendarCol'] = storeCalendarView.itCalendarCol;
    }
    setCalEvents(''); // 2024-01-20 - daq: para evitar que en la vista de recursos predespliegue con todas las columnas
    return Promise.all([FxFetch.doJsonX("cal", record)]).then((result) => {
      if (result[0]) {
        if (result[0].length > 0) {
        } else {
          FxLog.infox("MxCalendarView.fetchEvents", "NO hay eventos...");
        }
        setCalEvents(result[0]);
      } else {
        FxLog.infox("MxCalendarView.fetchEvents", "NO hay resultados!!");
      }
    });
  }

  const doButtonToday = () => {
    rf_calendar.current.getApi().today();
    doDatesSet();
  }

  const doButtonPrev = () => {
    rf_calendar.current.getApi().prev();
    doDatesSet();
  }

  const doButtonNext = () => {
    rf_calendar.current.getApi().next();
    doDatesSet();
  }

  const doButtonWeek = () => {
    setView('timeGridWeek');
    doDatesSet();
  }

  const doButtonListWeek = () => {
    setView('listWeek');
    doDatesSet();
  }

  const doButtonDay = () => {
    setView('timeGridDay');
    doDatesSet();
  }

  const doButtonRes = () => {
    setView('resourceTimeGridDay');
    doDatesSet();
  }

  const setView = (newView) => {
    rf_calendar.current.getApi().changeView(newView);
    rf_popper1.current.setVisible(newView !== 'resourceTimeGridDay');
    rf_popper2.current.setVisible(newView === 'resourceTimeGridDay');

    switch (newView) {
      case 'timeGridWeek':
        setCalView({ title: trans('field.week'), bg: 'var(--cal_color_week)' });
        break;

      case 'listWeek':
        setCalView({ title: trans('field.list'), bg: 'var(--cal_color_list)' });
        break;

      case 'timeGridDay':
        setCalView({ title: trans('field.day'), bg: 'var(--cal_color_day)' });
        break;

      case 'resourceTimeGridDay':
        let colsSelected = localStorage.getItem('MxCalendarView.colsSelected') || '';
        FxLog.infox('MxCalendarView.setView', '...colsSelected:', colsSelected);
        if (!colsSelected) {
          rf_popper2.current.setTooltipText(trans('msg.select_x', 'field.columns'));
        }
        setCalColsSelected(colsSelected);
        setCalView({ title: trans('field.resources'), bg: 'var(--cal_color_res)' });
        break;

      default: break;
    }
  }

  const doDateSelected = (d) => {
    setDlgNewDate(false);
    let _date = d || '';
    if (_date) {
      rf_calendar.current.getApi().gotoDate(_date);
      doDatesSet();
    }
  }

  const doDatesSet = async () => {
    r_calView.current = rf_calendar.current.getApi().view.type;
    // obtiene fecha actual y le le añade 1 día para que sea desde el lunes, y no domingo.
    r_calDate.current = FxDat.addDays(rf_calendar.current.getApi().view.activeStart.toISOString().split('T')[0], 1);

    // 2022-05-05
    storeCalendarView.selectedView = r_calView.current; // guarda vista, para poder volver con initialDate, luego de editar un evento.
    storeCalendarView.selectedDate = r_calDate.current; // guarda fecha, para poder volver con initialDate, luego de editar un evento.
    setStoreCalendarView(storeCalendarView);
    return fetchEvents();
  }

  const getColsToDisplay = () => {
    if (r_calView.current === 'timeGridWeek' || r_calView.current === 'listWeek' || r_calView.current === 'timeGridDay') {
      // vista simple
      return calCols;
    } else {
      // vista de recursos/columnas
      // dejamos sólo las seleccionadas
      let colsFiltered = calCols.filter(col => {
        return calColsSelected.includes(col.id);
      });
      return colsFiltered;
    };
  }

  const getHiddenDays = () => {
    let hidden = [];
    if (storeCalendarView.txWeekDays.charAt(6) === '0') hidden.push(0); // domingo
    if (storeCalendarView.txWeekDays.charAt(0) === '0') hidden.push(1); // lunes
    if (storeCalendarView.txWeekDays.charAt(1) === '0') hidden.push(2); // martes
    if (storeCalendarView.txWeekDays.charAt(2) === '0') hidden.push(3); // miércoles
    if (storeCalendarView.txWeekDays.charAt(3) === '0') hidden.push(4); // jueves
    if (storeCalendarView.txWeekDays.charAt(4) === '0') hidden.push(5); // viernes
    if (storeCalendarView.txWeekDays.charAt(5) === '0') hidden.push(6); // sábado

    return hidden;
  }

  const doSearch = (row) => {
    let _date = row.original.dtFrom.split(' ')[0];
    let _time = row.original.dtFrom.split(' ')[1].substring(0, 8);
    rf_calendar.current.getApi().gotoDate(_date);
    Promise.all([doDatesSet()]).then((result) => {
      rf_calendar.current.getApi().scrollToTime(_time);
    });

    setDlgSearch(false);
  }

  return (
    <div id='div_MxCalendarView'>
      <CxToolbar xref={rf_toolbar}
        moduleName={window.innerWidth > MIN_WIDTH ? `${trans('field.calendar')}: ${calView.title}` : `${trans('field.calendar')}`}
        //background={`${calView.bg}`}
        //help="calendar"
        gap="0px" /*quitamos gap para evitar desplazamiento de CxToolbarPopper*/
        addToolbarButtons={
          <div style={{ display: 'flex', whiteSpace: 'nowrap', margin: '0px 6px 0px 0px', gap: '4px' }}>
            <CxIconButtonNew
              onClick={() => setDlgSearch(true)} className="ibs_iconbutton_toolbar"
              icon={<BsSearch fontSize="20px" style={{ marginTop: '0px' }} />}
              tooltip={trans('field.search_x', 'field.event')}
            />
            <CxIconButtonNew
              onClick={() => setDlgNewDate(true)} className="ibs_iconbutton_toolbar"
              icon={<BsCalendar fontSize="21px" style={{ marginTop: '0px' }} />}
              tooltip={trans('msg.select_x', 'field.date')}
            />
          </div>
        }
      >
        <CxToolbarPopper xref={rf_popper1}
          width='155px'
          list={calCols}
          onSelection={doDisplayCol}
          visible={storeCalendarView.selectedView !== 'resourceTimeGridDay'}
        />
        <CxToolbarPopper xref={rf_popper2}
          width='155px'
          onCustomClick={doResColsChoose}
          value={trans('field.columns')}
          visible={storeCalendarView.selectedView === 'resourceTimeGridDay'}
          tooltipType="controlled"
        />
      </CxToolbar>

      <div className={classes.calendar}>
        <FullCalendar
          themeSystem="standard"
          ref={rf_calendar}
          locales={allLocales}
          locale={getStoreValue('StoreSession', 'txLocale').replace('_', '-')} //the initial locale "es-ES" {session.txLocale}
          plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin, resourceTimeGridPlugin]}
          schedulerLicenseKey={'CC-Attribution-NonCommercial-NoDerivatives'}
          resources={getColsToDisplay()}
          resourceOrder={'sequence'}
          customButtons={{
            customButtonWeek: { text: trans('field.week'), click: function () { doButtonWeek() }, },
            customButtonListWeek: { text: trans('field.list'), click: function () { doButtonListWeek() }, },
            customButtonDay: { text: trans('field.day'), click: function () { doButtonDay() }, },
            customButtonRes: { text: trans('field.resources'), click: function () { doButtonRes() }, },
            customButtonToday: { text: trans('field.today'), click: function () { doButtonToday() }, },
            customButtonPrev: { text: '<', click: function () { doButtonPrev() }, },
            customButtonNext: { text: '>', click: function () { doButtonNext() }, },
          }}
          slotMinTime={storeCalendarView.txStartHour || '07:00:00'} // hora inicial
          slotMaxTime='24:00:00' // hora final
          hiddenDays={getHiddenDays()}
          businessHours={[
            { daysOfWeek: [0, 1, 2, 3, 4, 5, 6], startTime: storeCalendarView.txWorkTime1From || '', endTime: storeCalendarView.txWorkTime1To || '', },
            { daysOfWeek: [0, 1, 2, 3, 4, 5, 6], startTime: storeCalendarView.txWorkTime2From || '', endTime: storeCalendarView.txWorkTime2To || '', },
          ]}
          dateClick={isFullAccess() && doDateClick}
          eventClick={doEventClick}
          events={calEvents}
          displayEventTime={r_calView.current === 'listWeek'}
          noEventsContent={""} //mensaje en la vista de listado cuando no hay eventos.
          weekends={true}
          allDaySlot={false} //no permite eventos multidía; en su lugar permitiremos repetir eventos.
          firstDay="1" //la semana comienza el lunes y no el domingo
          headerToolbar={getHeaderToolbar()}
          initialView={window.innerWidth > MIN_WIDTH ? storeCalendarView.selectedView || 'timeGridWeek' : 'timeGridDay'}
          initialDate={storeCalendarView.selectedDate || null} // 2022-05-05 para que al volver de un evento muestre la fecha donde estaba.
          contentHeight={window.innerHeight - r_headerSize.current}
          eventTimeFormat={{ // like '14:30:00'
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
          }}
          titleFormat={{ year: 'numeric', month: '2-digit', day: '2-digit' }}
          slotLabelFormat={{
            hour: '2-digit',
            minute: '2-digit',
            omitZeroMinute: false,
            meridiem: false,
            hour12: false
          }}
          slotDuration={{ minutes: storeCalendarView.nuIntervalMins||15 }}
          slotLabelInterval={{ minutes: storeCalendarView.nuIntervalMins||15 }}
          editable={isFullAccess()}
          longPressDelay={1000}
          eventDrop={doEventDrop}
          eventResize={doEventDrop}
        />
      </div>

      <CxDlgCheckList xref={r_dlg_res_cols} onOk={doResColsChanged} title={trans('field.columns')} />

      <CxDlgCalendar
        xref={r_dlg_calendar}
        open={dlgNewDate}
        onClose={(d) => doDateSelected(d)}
        title={trans('msg.select_x', 'field.date')}
      />

      <CxDlgTable
        xref={r_dlg_search}
        hasSearchBox={true}
        open={dlgSearch}
        onSelect={doSearch}
        onClose={() => setDlgSearch(false)}
        url={'cal/search'}
        tooltip={(isApp('vett') || isApp('spaw')) ? `${trans('field.client')} / ${trans('field.patient')}` : `${trans('field.client')}`}
        columns={(isApp('vett') || isApp('spaw')) ?
          [
            { id: "0", Header: "ID", accessor: "inCalendar", show: false },
            { id: "1", Header: trans('field.date'), accessor: row => FxTable.cellToLocalDateTime(row["dtFrom"]), width: '25%' },
            { id: "2", Header: trans('field.client'), accessor: row => r_dlg_search.current.getTable().highlightText(row["txClient"]), width: '50%' },
            { id: "3", Header: trans('field.patient'), accessor: row => r_dlg_search.current.getTable().highlightText(row["txAnimal"]), width: '25%' },
          ]
          :
          [
            { id: "0", Header: "ID", accessor: "inCalendar", show: false },
            { id: "1", Header: trans('field.date'), accessor: row => FxTable.cellToLocalDateTime(row["dtFrom"]), width: '25%' },
            { id: "2", Header: trans('field.client'), accessor: row => r_dlg_search.current.getTable().highlightText(row["txClient"]), width: '50%' },
          ]
        }
        title={trans('field.search')}
        height='450px'
      />

      <CxSnackbar xref={r_snack_overlap} severity="error" value={trans('msg.time_overlap')} />

    </div>
  );

}

export default MxCalendarView;
