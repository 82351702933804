/* eslint-disable */
const getApp = () => {
  const theStore = sessionStorage.getItem('StoreSession')||null;
  if (theStore) {
    const json = JSON.parse(theStore);
    return json['txApp']||'';
  } else {
    return '';
  }
};
const trans = (key) => {
  let ar = JSON.parse(sessionStorage.getItem('trans')||null);
  if (ar && ar[key]) {
    return ar[key].replaceAll(' ', '_').replaceAll('-', '_');
  } else {
    return key;
  }
}

var plugin_submenu = {
  // @Required @Unique
  // plugin name
  name: 'custom_plugin_submenu',

  // @Required
  // data display
  display: 'submenu',

  // @Required
  // add function - It is called only once when the plugin is first run.
  // This function generates HTML to append and register the event.
  // arguments - (core : core object, targetElement : clicked button element)
  add: function (core, targetElement) {

    // @Required
    // Registering a namespace for caching as a plugin name in the context object
    const context = core.context;
    context.customSubmenu = {
      targetButton: targetElement,
      currentSpan: null
    };

    // Generate submenu HTML
    // Always bind "core" when calling a plugin function
    let listDiv = this.setSubmenu(core);

    // You must bind "core" object when registering an event.
    /** add event listeners */
    listDiv.querySelector('a[name="invo01"]').addEventListener('click', this.onClickInvo01.bind(core));
    listDiv.querySelector('a[name="invo02"]').addEventListener('click', this.onClickInvo02.bind(core));
    listDiv.querySelector('a[name="invo03"]').addEventListener('click', this.onClickInvo03.bind(core));
    listDiv.querySelector('a[name="invo04"]').addEventListener('click', this.onClickInvo04.bind(core));
    listDiv.querySelector('a[name="invo05"]').addEventListener('click', this.onClickInvo05.bind(core));
    listDiv.querySelector('a[name="invo06"]').addEventListener('click', this.onClickInvo06.bind(core));
    listDiv.querySelector('a[name="invo07"]').addEventListener('click', this.onClickInvo07.bind(core));
    listDiv.querySelector('a[name="invo08"]').addEventListener('click', this.onClickInvo08.bind(core));
    listDiv.querySelector('a[name="invo09"]').addEventListener('click', this.onClickInvo09.bind(core));
    listDiv.querySelector('a[name="invo10"]').addEventListener('click', this.onClickInvo10.bind(core));
    listDiv.querySelector('a[name="invo11"]').addEventListener('click', this.onClickInvo11.bind(core));
    listDiv.querySelector('a[name="invo12"]').addEventListener('click', this.onClickInvo12.bind(core));
    listDiv.querySelector('a[name="invo13"]').addEventListener('click', this.onClickInvo13.bind(core));
    listDiv.querySelector('a[name="invo14"]').addEventListener('click', this.onClickInvo14.bind(core));
    listDiv.querySelector('a[name="invo15"]').addEventListener('click', this.onClickInvo15.bind(core));
    listDiv.querySelector('a[name="invo16"]').addEventListener('click', this.onClickInvo16.bind(core));
    listDiv.querySelector('a[name="invo17"]').addEventListener('click', this.onClickInvo17.bind(core));
    listDiv.querySelector('a[name="invo18"]').addEventListener('click', this.onClickInvo18.bind(core));
    listDiv.querySelector('a[name="invo19"]').addEventListener('click', this.onClickInvo19.bind(core));
    listDiv.querySelector('a[name="invo20"]').addEventListener('click', this.onClickInvo20.bind(core));
    listDiv.querySelector('a[name="invo21"]').addEventListener('click', this.onClickInvo21.bind(core));
    listDiv.querySelector('a[name="invo22"]').addEventListener('click', this.onClickInvo22.bind(core));
    listDiv.querySelector('a[name="invo23"]').addEventListener('click', this.onClickInvo23.bind(core));
    listDiv.querySelector('a[name="invo24"]').addEventListener('click', this.onClickInvo24.bind(core));
    listDiv.querySelector('a[name="invo25"]').addEventListener('click', this.onClickInvo25.bind(core));
    listDiv.querySelector('a[name="invo26"]').addEventListener('click', this.onClickInvo26.bind(core));
    listDiv.querySelector('a[name="invo27"]').addEventListener('click', this.onClickInvo27.bind(core));
    listDiv.querySelector('a[name="invo28"]').addEventListener('click', this.onClickInvo28.bind(core));
    listDiv.querySelector('a[name="invo29"]').addEventListener('click', this.onClickInvo29.bind(core));
    listDiv.querySelector('a[name="invo30"]').addEventListener('click', this.onClickInvo30.bind(core));
    listDiv.querySelector('a[name="invo31"]').addEventListener('click', this.onClickInvo31.bind(core));
    listDiv.querySelector('a[name="invo32"]').addEventListener('click', this.onClickInvo32.bind(core));
    listDiv.querySelector('a[name="invo33"]').addEventListener('click', this.onClickInvo33.bind(core));
    //opti
    const opti01 = listDiv.querySelector('a[name="opti01"]'); if (opti01) opti01.addEventListener('click', this.onClickOpti01.bind(core));
    const opti02 = listDiv.querySelector('a[name="opti02"]'); if (opti02) opti02.addEventListener('click', this.onClickOpti02.bind(core));
    //vett
    const vett01 = listDiv.querySelector('a[name="vett01"]'); if (vett01) vett01.addEventListener('click', this.onClickVett01.bind(core));
    const vett02 = listDiv.querySelector('a[name="vett02"]'); if (vett02) vett02.addEventListener('click', this.onClickVett02.bind(core));
    const vett03 = listDiv.querySelector('a[name="vett03"]'); if (vett03) vett03.addEventListener('click', this.onClickVett03.bind(core));
    const vett04 = listDiv.querySelector('a[name="vett04"]'); if (vett04) vett04.addEventListener('click', this.onClickVett04.bind(core));
    const vett05 = listDiv.querySelector('a[name="vett05"]'); if (vett05) vett05.addEventListener('click', this.onClickVett05.bind(core));
    const vett06 = listDiv.querySelector('a[name="vett06"]'); if (vett06) vett06.addEventListener('click', this.onClickVett06.bind(core));
    const vett07 = listDiv.querySelector('a[name="vett07"]'); if (vett07) vett07.addEventListener('click', this.onClickVett07.bind(core));
    const vett08 = listDiv.querySelector('a[name="vett08"]'); if (vett08) vett08.addEventListener('click', this.onClickVett08.bind(core));
    const vett09 = listDiv.querySelector('a[name="vett09"]'); if (vett09) vett09.addEventListener('click', this.onClickVett09.bind(core));
    const vett10 = listDiv.querySelector('a[name="vett10"]'); if (vett10) vett10.addEventListener('click', this.onClickVett10.bind(core));
    const vett11 = listDiv.querySelector('a[name="vett11"]'); if (vett11) vett11.addEventListener('click', this.onClickVett11.bind(core));
    const vett12 = listDiv.querySelector('a[name="vett12"]'); if (vett12) vett12.addEventListener('click', this.onClickVett12.bind(core));
    const vett13 = listDiv.querySelector('a[name="vett13"]'); if (vett13) vett13.addEventListener('click', this.onClickVett13.bind(core));
    const vett14 = listDiv.querySelector('a[name="vett14"]'); if (vett14) vett14.addEventListener('click', this.onClickVett14.bind(core));
    const vett15 = listDiv.querySelector('a[name="vett15"]'); if (vett15) vett15.addEventListener('click', this.onClickVett15.bind(core));
    const vett16 = listDiv.querySelector('a[name="vett16"]'); if (vett16) vett16.addEventListener('click', this.onClickVett16.bind(core));
    const vett17 = listDiv.querySelector('a[name="vett17"]'); if (vett17) vett17.addEventListener('click', this.onClickVett17.bind(core));
    const vett18 = listDiv.querySelector('a[name="vett18"]'); if (vett18) vett18.addEventListener('click', this.onClickVett18.bind(core));
    const vett19 = listDiv.querySelector('a[name="vett19"]'); if (vett19) vett19.addEventListener('click', this.onClickVett19.bind(core));
    const vett20 = listDiv.querySelector('a[name="vett20"]'); if (vett20) vett20.addEventListener('click', this.onClickVett20.bind(core));
    const vett21 = listDiv.querySelector('a[name="vett21"]'); if (vett21) vett21.addEventListener('click', this.onClickVett21.bind(core));
    const vett22 = listDiv.querySelector('a[name="vett22"]'); if (vett22) vett22.addEventListener('click', this.onClickVett22.bind(core));
    const vett23 = listDiv.querySelector('a[name="vett23"]'); if (vett23) vett23.addEventListener('click', this.onClickVett23.bind(core));
    const vett24 = listDiv.querySelector('a[name="vett24"]'); if (vett24) vett24.addEventListener('click', this.onClickVett24.bind(core));
    const vett25 = listDiv.querySelector('a[name="vett25"]'); if (vett25) vett25.addEventListener('click', this.onClickVett25.bind(core));

    // @Required
    // You must add the "submenu" element using the "core.initMenuTarget" method.
    /** append target button menu */
    core.initMenuTarget(this.name, targetElement, listDiv);
  },

  setSubmenu: function (core) {
    let sessionApp = getApp();
    //console.log("app => " + sessionApp);
    let opti01 = '',  opti02 = '';
    let vett01 = '',  vett02 = '',  vett03 = '',  vett04 = '',  vett05 = '',  vett06 = '',  vett07 = '',  vett08 = '',  vett09 = '',  vett10 = '',
        vett11 = '',  vett12 = '',  vett13 = '',  vett14 = '',  vett15 = '',  vett16 = '',  vett17 = '',  vett18 = '',  vett19 = '',  vett20 = '',  
        vett21 = '',  vett22 = '',  vett23 = '',  vett24 = '',  vett25 = '';
    if (sessionApp.includes('opti')) {
      opti01 = '<li><a style="padding: 3px; color:blue" name="opti01">' + trans('field.professional') + '_' + trans('field.name') + '</a></li>';
      opti02 = '<li><a style="padding: 3px; color:blue" name="opti02">' + trans('field.professional') + '_' + trans('field.license') + '</a></li>';
    }
    if (sessionApp.includes('vett')) {
      vett01 = '<li><a style="padding: 3px; color:blue" name="vett01">' + trans('field.patient') + '_' + trans('field.name') + '</a></li>';
      vett02 = '<li><a style="padding: 3px; color:blue" name="vett02">' + trans('field.patient') + '_' + trans('field.number') + '</a></li>';
      vett03 = '<li><a style="padding: 3px; color:blue" name="vett03">' + trans('field.patient') + '_' + trans('field.number_animals_s') + '</a></li>';
      vett04 = '<li><a style="padding: 3px; color:blue" name="vett04">' + trans('field.patient') + '_' + trans('field.chip') + '</a></li>';
      vett05 = '<li><a style="padding: 3px; color:blue" name="vett05">' + trans('field.patient') + '_' + trans('field.passport') + '</a></li>';
      vett06 = '<li><a style="padding: 3px; color:blue" name="vett06">' + trans('field.patient') + '_' + trans('field.from') + '</a></li>';
      vett07 = '<li><a style="padding: 3px; color:blue" name="vett07">' + trans('field.patient') + '_' + trans('field.to') + '</a></li>';
      vett08 = '<li><a style="padding: 3px; color:blue" name="vett08">' + trans('field.patient') + '_' + trans('field.cns_last') + '</a></li>';
      vett09 = '<li><a style="padding: 3px; color:blue" name="vett09">' + trans('field.patient') + '_' + trans('field.vacc_last') + '</a></li>';
      vett10 = '<li><a style="padding: 3px; color:blue" name="vett10">' + trans('field.patient') + '_' + trans('field.reference') + '</a></li>';
      vett11 = '<li><a style="padding: 3px; color:blue" name="vett11">' + trans('field.patient') + '_' + trans('field.reason') + '</a></li>';
      vett12 = '<li><a style="padding: 3px; color:blue" name="vett12">' + trans('field.patient') + '_' + trans('field.birth') + '</a></li>';
      vett13 = '<li><a style="padding: 3px; color:blue" name="vett13">' + trans('field.patient') + '_' + trans('field.gender') + '</a></li>';
      vett14 = '<li><a style="padding: 3px; color:blue" name="vett14">' + trans('field.patient') + '_' + trans('field.species') + '</a></li>';
      vett15 = '<li><a style="padding: 3px; color:blue" name="vett15">' + trans('field.patient') + '_' + trans('field.breed') + '</a></li>';
      vett16 = '<li><a style="padding: 3px; color:blue" name="vett16">' + trans('field.patient') + '_' + trans('field.coat') + '</a></li>';
      vett17 = '<li><a style="padding: 3px; color:blue" name="vett17">' + trans('field.patient') + '_' + trans('field.sterilization') + '</a></li>';
      vett18 = '<li><a style="padding: 3px; color:blue" name="vett18">' + trans('field.patient') + '_' + trans('field.reproduction') + '</a></li>';
      vett19 = '<li><a style="padding: 3px; color:blue" name="vett19">' + trans('field.patient') + '_' + trans('field.pedigree') + '</a></li>';
      vett20 = '<li><a style="padding: 3px; color:blue" name="vett20">' + trans('field.patient') + '_' + trans('field.nature') + '</a></li>';
      vett21 = '<li><a style="padding: 3px; color:blue" name="vett21">' + trans('field.patient') + '_' + trans('field.habitat') + '</a></li>';
      vett22 = '<li><a style="padding: 3px; color:blue" name="vett22">' + trans('field.patient') + '_' + trans('field.feeding') + '</a></li>';
      vett23 = '<li><a style="padding: 3px; color:blue" name="vett23">' + trans('field.patient') + '_' + trans('field.alert') + '</a></li>';
      vett24 = '<li><a style="padding: 3px; color:blue" name="vett24">' + trans('field.patient') + '_' + trans('field.weight') + '</a></li>';
      vett25 = '<li><a style="padding: 3px; color:blue" name="vett25">' + trans('field.patient') + '_' + trans('field.rate') + '</a></li>';
    }

    const listDiv = core.util.createElement('DIV');

    // @Required
    // A "se-submenu" class is required for the top level element.
    listDiv.className = 'se-menu-container se-submenu se-list-layer';
    listDiv.innerHTML = '' +
      '<div style="width: 200px; height: 300px; margin: 5px; cursor: pointer; font-family: Arial; font-size: 13px; color: #000;">' +
      '<ul style="width: 200px; height: 300px; overflow: hidden; overflow-y: scroll;" >' +
      '<li><a style="padding: 3px; color:black" name="invo01">' + trans('field.date') + '</a></li>' +
      '<li><a style="padding: 3px; color:black" name="invo02">' + trans('field.time') + '</a></li>' +
      '<li><a style="padding: 3px; color:brown" name="invo03">' + trans('field.branch_s') + '_' + trans('field.company') + '</a></li>' +
      '<li><a style="padding: 3px; color:brown" name="invo04">' + trans('field.branch_s') + '_' + trans('field.address') + '</a></li>' +
      '<li><a style="padding: 3px; color:brown" name="invo05">' + trans('field.branch_s') + '_' + trans('field.city') + '</a></li>' +
      '<li><a style="padding: 3px; color:brown" name="invo06">' + trans('field.branch_s') + '_' + trans('field.postal_code') + '</a></li>' +
      '<li><a style="padding: 3px; color:brown" name="invo07">' + trans('field.branch_s') + '_' + trans('field.state') + '</a></li>' +
      '<li><a style="padding: 3px; color:brown" name="invo08">' + trans('field.branch_s') + '_' + trans('field.email') + '</a></li>' +
      '<li><a style="padding: 3px; color:brown" name="invo09">' + trans('field.branch_s') + '_' + trans('field.phone1') + '</a></li>' +
      '<li><a style="padding: 3px; color:fuchsia" name="invo10">' + trans('field.employee') + '_' + trans('field.name') + '</a></li>' +
      '<li><a style="padding: 3px; color:fuchsia" name="invo11">' + trans('field.employee') + '_' + trans('field.license') + '</a></li>' +
      '<li><a style="padding: 3px; color:green" name="invo12">' + trans('field.client') + '_' + trans('field.name') + '</a></li>' +
      '<li><a style="padding: 3px; color:green" name="invo13">' + trans('field.client') + '_' + trans('field.contact') + '</a></li>' +
      '<li><a style="padding: 3px; color:green" name="invo14">' + trans('field.client') + '_' + trans('field.ic') + '</a></li>' +
      '<li><a style="padding: 3px; color:green" name="invo15">' + trans('field.client') + '_' + trans('field.code') + '</a></li>' +
      '<li><a style="padding: 3px; color:green" name="invo16">' + trans('field.client') + '_' + trans('field.address') + '</a></li>' +
      '<li><a style="padding: 3px; color:green" name="invo17">' + trans('field.client') + '_' + trans('field.city') + '</a></li>' +
      '<li><a style="padding: 3px; color:green" name="invo18">' + trans('field.client') + '_' + trans('field.state') + '</a></li>' +
      '<li><a style="padding: 3px; color:green" name="invo19">' + trans('field.client') + '_' + trans('field.postal_code') + '</a></li>' +
      '<li><a style="padding: 3px; color:green" name="invo20">' + trans('field.client') + '_' + trans('field.country') + '</a></li>' +
      '<li><a style="padding: 3px; color:green" name="invo21">' + trans('field.client') + '_' + trans('field.email') + '</a></li>' +
      '<li><a style="padding: 3px; color:green" name="invo22">' + trans('field.client') + '_' + trans('field.web') + '</a></li>' +
      '<li><a style="padding: 3px; color:green" name="invo23">' + trans('field.client') + '_' + trans('field.facebook') + '</a></li>' +
      '<li><a style="padding: 3px; color:green" name="invo24">' + trans('field.client') + '_' + trans('field.phone1') + '</a></li>' +
      '<li><a style="padding: 3px; color:green" name="invo25">' + trans('field.client') + '_' + trans('field.phone2') + '</a></li>' +
      '<li><a style="padding: 3px; color:green" name="invo26">' + trans('field.client') + '_' + trans('field.mobile') + '</a></li>' +
      '<li><a style="padding: 3px; color:green" name="invo27">' + trans('field.client') + '_' + trans('field.bank') + '</a></li>' +
      '<li><a style="padding: 3px; color:green" name="invo28">' + trans('field.client') + '_' + trans('field.account') + '</a></li>' +
      '<li><a style="padding: 3px; color:green" name="invo29">' + trans('field.client') + '_' + trans('field.holder') + '</a></li>' +
      '<li><a style="padding: 3px; color:green" name="invo30">' + trans('field.client') + '_' + trans('field.issued') + '</a></li>' +
      '<li><a style="padding: 3px; color:green" name="invo31">' + trans('field.client') + '_' + trans('field.paid') + '</a></li>' +
      '<li><a style="padding: 3px; color:green" name="invo32">' + trans('field.client') + '_' + trans('field.balance') + '</a></li>' +
      '<li><a style="padding: 3px; color:green" name="invo33">' + trans('field.client') + '_' + trans('field.balance') +"_Ad" + '</a></li>' +
      opti01 + opti02 +
      vett01 + vett02 + vett03 + vett04 + vett05 + vett06 + vett07 + vett08 + vett09 + vett10 +
      vett11 + vett12 + vett13 + vett14 + vett15 + vett16 + vett17 + vett18 + vett19 + vett20 +
      vett21 + vett22 + vett23 + vett24 + vett25 +
      '</ul>' +
      '</div>';

    return listDiv;
  },

  // @Override core
  // Plugins with active methods load immediately when the editor loads.
  // Called each time the selection is moved.
  active: function (element) {
    // If no tag matches, the "element" argument is called with a null value.
    if (!element) {
      this.util.removeClass(this.context.customSubmenu.targetButton, 'active');
      this.context.customSubmenu.currentSpan = null;
    } else if (this.util.hasClass(element, 'se-custom-tag')) {
      this.util.addClass(this.context.customSubmenu.targetButton, 'active');
      this.context.customSubmenu.currentSpan = element;
      return true;
    }

    return false;
  },

  onClickInvo01: function () { this.functions.insertHTML('<span class="se-custom-tag">${date}</span>', true); this.submenuOff(); },
  onClickInvo02: function () { this.functions.insertHTML('<span class="se-custom-tag">${time}</span>', true); this.submenuOff(); },
  onClickInvo03: function () { this.functions.insertHTML('<span class="se-custom-tag">${branch_txCompany}</span>', true); this.submenuOff(); },
  onClickInvo04: function () { this.functions.insertHTML('<span class="se-custom-tag">${branch_txAddress}</span>', true); this.submenuOff(); },
  onClickInvo05: function () { this.functions.insertHTML('<span class="se-custom-tag">${branch_txCity}</span>', true); this.submenuOff(); },
  onClickInvo06: function () { this.functions.insertHTML('<span class="se-custom-tag">${branch_txPostalCode}</span>', true); this.submenuOff(); },
  onClickInvo07: function () { this.functions.insertHTML('<span class="se-custom-tag">${branch_txState}</span>', true); this.submenuOff(); },
  onClickInvo08: function () { this.functions.insertHTML('<span class="se-custom-tag">${branch_txEmail}</span>', true); this.submenuOff(); },
  onClickInvo09: function () { this.functions.insertHTML('<span class="se-custom-tag">${branch_txPhone1}</span>', true); this.submenuOff(); },
  onClickInvo10: function () { this.functions.insertHTML('<span class="se-custom-tag">${txEmployee}</span>', true); this.submenuOff(); },
  onClickInvo11: function () { this.functions.insertHTML('<span class="se-custom-tag">${txLicenseE}</span>', true); this.submenuOff(); },
  onClickInvo12: function () { this.functions.insertHTML('<span class="se-custom-tag">${txClient}</span>', true); this.submenuOff(); },
  onClickInvo13: function () { this.functions.insertHTML('<span class="se-custom-tag">${txContact}</span>', true); this.submenuOff(); },
  onClickInvo14: function () { this.functions.insertHTML('<span class="se-custom-tag">${txIc}</span>', true); this.submenuOff(); },
  onClickInvo15: function () { this.functions.insertHTML('<span class="se-custom-tag">${txCode}</span>', true); this.submenuOff(); },
  onClickInvo16: function () { this.functions.insertHTML('<span class="se-custom-tag">${txAddress}</span>', true); this.submenuOff(); },
  onClickInvo17: function () { this.functions.insertHTML('<span class="se-custom-tag">${txCity}</span>', true); this.submenuOff(); },
  onClickInvo18: function () { this.functions.insertHTML('<span class="se-custom-tag">${txState}</span>', true); this.submenuOff(); },
  onClickInvo19: function () { this.functions.insertHTML('<span class="se-custom-tag">${txPostalCode}</span>', true); this.submenuOff(); },
  onClickInvo20: function () { this.functions.insertHTML('<span class="se-custom-tag">${txCountry}</span>', true); this.submenuOff(); },
  onClickInvo21: function () { this.functions.insertHTML('<span class="se-custom-tag">${txEmail}</span>', true); this.submenuOff(); },
  onClickInvo22: function () { this.functions.insertHTML('<span class="se-custom-tag">${txWeb}</span>', true); this.submenuOff(); },
  onClickInvo23: function () { this.functions.insertHTML('<span class="se-custom-tag">${txFacebook}</span>', true); this.submenuOff(); },
  onClickInvo24: function () { this.functions.insertHTML('<span class="se-custom-tag">${txPhone1}</span>', true); this.submenuOff(); },
  onClickInvo25: function () { this.functions.insertHTML('<span class="se-custom-tag">${txPhone2}</span>', true); this.submenuOff(); },
  onClickInvo26: function () { this.functions.insertHTML('<span class="se-custom-tag">${txMobile}</span>', true); this.submenuOff(); },
  onClickInvo27: function () { this.functions.insertHTML('<span class="se-custom-tag">${txBank}</span>', true); this.submenuOff(); },
  onClickInvo28: function () { this.functions.insertHTML('<span class="se-custom-tag">${txAccount}</span>', true); this.submenuOff(); },
  onClickInvo29: function () { this.functions.insertHTML('<span class="se-custom-tag">${txHolder}</span>', true); this.submenuOff(); },
  onClickInvo30: function () { this.functions.insertHTML('<span class="se-custom-tag">${nuTotalIssued}</span>', true); this.submenuOff(); },
  onClickInvo31: function () { this.functions.insertHTML('<span class="se-custom-tag">${nuTotalPaid}</span>', true); this.submenuOff(); },
  onClickInvo32: function () { this.functions.insertHTML('<span class="se-custom-tag">${nuTotalBalance}</span>', true); this.submenuOff(); },
  onClickInvo33: function () { this.functions.insertHTML('<span class="se-custom-tag">${nuTotalBalanceAd}</span>', true); this.submenuOff(); },

  onClickOpti01: function () { this.functions.insertHTML('<span class="se-custom-tag">${txProf}</span>', true); this.submenuOff(); },
  onClickOpti02: function () { this.functions.insertHTML('<span class="se-custom-tag">${txLicense}</span>', true); this.submenuOff(); },
  
  onClickVett01: function () { this.functions.insertHTML('<span class="se-custom-tag">${txAnimal}</span>', true); this.submenuOff(); },
  onClickVett02: function () { this.functions.insertHTML('<span class="se-custom-tag">${txNumber}</span>', true); this.submenuOff(); },
  onClickVett03: function () { this.functions.insertHTML('<span class="se-custom-tag">${txNumberAnimals}</span>', true); this.submenuOff(); },
  onClickVett04: function () { this.functions.insertHTML('<span class="se-custom-tag">${txChip}</span>', true); this.submenuOff(); },
  onClickVett05: function () { this.functions.insertHTML('<span class="se-custom-tag">${txPassport}</span>', true); this.submenuOff(); },
  onClickVett06: function () { this.functions.insertHTML('<span class="se-custom-tag">${dtFrom}</span>', true); this.submenuOff(); },
  onClickVett07: function () { this.functions.insertHTML('<span class="se-custom-tag">${dtTo}</span>', true); this.submenuOff(); },
  onClickVett08: function () { this.functions.insertHTML('<span class="se-custom-tag">${dtLastCns}</span>', true); this.submenuOff(); },
  onClickVett09: function () { this.functions.insertHTML('<span class="se-custom-tag">${dtLastVac}</span>', true); this.submenuOff(); },
  onClickVett10: function () { this.functions.insertHTML('<span class="se-custom-tag">${txReference}</span>', true); this.submenuOff(); },
  onClickVett11: function () { this.functions.insertHTML('<span class="se-custom-tag">${txReason}</span>', true); this.submenuOff(); },
  onClickVett12: function () { this.functions.insertHTML('<span class="se-custom-tag">${dtBirth}</span>', true); this.submenuOff(); },
  onClickVett13: function () { this.functions.insertHTML('<span class="se-custom-tag">${boGender}</span>', true); this.submenuOff(); },
  onClickVett14: function () { this.functions.insertHTML('<span class="se-custom-tag">${txTypeAnimalSpecies}</span>', true); this.submenuOff(); },
  onClickVett15: function () { this.functions.insertHTML('<span class="se-custom-tag">${txTypeAnimalBreed}</span>', true); this.submenuOff(); },
  onClickVett16: function () { this.functions.insertHTML('<span class="se-custom-tag">${txTypeAnimalCoat}</span>', true); this.submenuOff(); },
  onClickVett17: function () { this.functions.insertHTML('<span class="se-custom-tag">${boSterilization}</span>', true); this.submenuOff(); },
  onClickVett18: function () { this.functions.insertHTML('<span class="se-custom-tag">${boReproduction}</span>', true); this.submenuOff(); },
  onClickVett19: function () { this.functions.insertHTML('<span class="se-custom-tag">${boPedigree}</span>', true); this.submenuOff(); },
  onClickVett20: function () { this.functions.insertHTML('<span class="se-custom-tag">${txNature}</span>', true); this.submenuOff(); },
  onClickVett21: function () { this.functions.insertHTML('<span class="se-custom-tag">${txHabitat}</span>', true); this.submenuOff(); },
  onClickVett22: function () { this.functions.insertHTML('<span class="se-custom-tag">${txFeeding}</span>', true); this.submenuOff(); },
  onClickVett23: function () { this.functions.insertHTML('<span class="se-custom-tag">${txAlert}</span>', true); this.submenuOff(); },
  onClickVett24: function () { this.functions.insertHTML('<span class="se-custom-tag">${nuWeight}</span>', true); this.submenuOff(); },
  onClickVett25: function () { this.functions.insertHTML('<span class="se-custom-tag">${txTypeRate}</span>', true); this.submenuOff(); },

};

export { plugin_submenu };
