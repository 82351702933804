import React, { useState, useImperativeHandle } from "react";
import { useTranslation } from '../hx/useTranslation';
import * as FxFetch from '../fx/FxFetch';
import * as FxLog from '../fx/FxLog';
import * as FxNum from '../fx/FxNum';
import * as FxInet from '../fx/FxInet';
import { useSessionStorage } from '../hx/useSessionStorage';
import CxStatic from '../cx/CxStatic';
import CxDlgPin from '../cx/CxDlgPin';
import CxButton from '../cx/CxButton';
import CxSnackbar from '../cx/CxSnackbar';
import { makeStyles } from '@material-ui/core/styles';
import { StoreLongTask } from "../gx/GxStore";


const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'flex-start',
    gap: 'var(--ibs_container_gap)',
    margin: 'var(--ibs_container_margin)',
  },
  button_frame: {
    margin: '20px 0 0 0',
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'flex-start',
    gap: '12px',
    width: '100%',
  },
}));

const GxLongTask = (props) => {
  const [{ trans }] = useTranslation();
  const classes = useStyles(props);
  
  // eslint-disable-next-line
  const [storeLongTask, setStoreLongTask] = useSessionStorage('StoreLongTask', StoreLongTask);
  
  const SEC = 1000; // un segundo en milisegundos
  const timer = React.useRef(null);
  
  const [progress, setProgress] = useState('');
  const [showButtonExecute, setShowButtonExecute] = useState(true);
  const [caption, setCaption] = useState(props.caption||trans('field.execute'));

  const r_progress = React.useRef('');
  const r_dlg_pin = React.useRef(null);

  const r_snack_error = React.useRef(null);

  useImperativeHandle(props.xref, () => {
    return {
      setCaption
    };
  });

  React.useEffect(() => {
    formLoad();

    return () => {
      runTimer(false);
    }
    // eslint-disable-next-line
  }, []);

  const formLoad = async () => {
    FxLog.infox("GxLongTask.formLoad", "loading...");
  }

  const runTimer = (boStart) => {
    if (boStart) { //starting up timer...
      timer.current = setInterval(() => {
        if (FxInet.isOnline()) {
          doProgressCheck();
        }
      }, 0.5 * SEC);

    } else {
      if (timer.current) { //cleaning up timer...
        clearInterval(timer.current);
      }
    }
  }

  const doProcessExecute = () => {
    try {
      Promise.all([FxFetch.doJsonX(storeLongTask._url, storeLongTask._record)]).then((result) => {
        if (result[0]) {
          FxLog.infox('GxLongTask.doProcessExecute', '...result[0].theValue:', result[0].theValue);
          if (result[0].theValue === 'OK') {
            runTimer(true);
          } else {
            // manejo de errores devueltos por el servidor ANTES de llamar a tsk.executeTask(). Ex: LSettSlsNewYear.execute()
            r_snack_error.current.setValAndOpen(result[0].theValue);
          }
        }
      });

    } catch (error) {
      FxLog.errorx("GxLongTask.doProcessExecute", error);
    }
  }

  const doProcessCancel = () => {
    try {
      let record = {};
      record['txTaskName'] = storeLongTask._record.txTaskName;
      Promise.all([FxFetch.doJsonX('long_task/cancel', record)]).then((result) => {
        if (result[0]) {
          let msg = trans("msg.operation_cancelled");
          Promise.all([setProgress(msg)]).then((result) => {
            if (r_progress.current) r_progress.current.setLabel(msg);
          });
          if (result[0].theValue === 'OK') {
            doProcessClose();
          }
        }
      });

    } catch (error) {
      FxLog.errorx("GxLongTask.doProcessCancel", error);
    }
  }

  const doProgressCheck = () => {
    try {
      let record = {};
      record['txTaskName'] = storeLongTask._record.txTaskName;
      Promise.all([FxFetch.doJsonX('long_task/get_progress', record)]).then((result) => {
        if (result[0]) {
          FxLog.infox("GxLongTask.doProgressCheck", "...result[0]:", result[0]);
          let txProgressText = result[0].txProgressText;
          let txTaskMessage = result[0].txTaskMessage;
          let nuTaskPercentage = FxNum.toInt(result[0].nuTaskPercentage);
          let txResultError = result[0].txResultError;

          let msg = null;
          if (txResultError) {
            msg = {text: txResultError, fontColor: 'var(--ibs_color_red)'};
          } else {
            let theProgress = txTaskMessage ? ((txProgressText ? txProgressText + ' -> ' : '') + txTaskMessage + ' ' + nuTaskPercentage + ' %') : '';
            msg = {text: theProgress, fontColor: 'var(--ibs_color_black)'};
          }
          FxLog.infox("GxLongTask.doProgressCheck", "...msg:", msg);
          Promise.all([setProgress(msg.text)]).then((result) => {
            if (r_progress.current) r_progress.current.setLabel(msg.text, msg.fontColor);
          });
          if (nuTaskPercentage === 100 || txResultError) {
            doProcessClose();
          }
        }
      });

    } catch (error) {
      FxLog.errorx("GxLongTask.doProgressCheck", error);
    }
  }

  const isProcessing = () => { return Boolean(progress.startsWith(trans("msg.operation_in_progress"))) }

  const doProcessClose = () => {
    setProgress('');
    setShowButtonExecute(false);
    runTimer(false);
  }


  return (
    <div>
      <div className={classes.container}>
        <div className={classes.button_frame}>
          {storeLongTask._warning && <CxStatic label={storeLongTask._warning} border='red' />}
          {<div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-end', alignItems: 'center', gap: '5px' }}>
            <CxStatic xref={r_progress} height='30px' />
            {isProcessing() && storeLongTask._canCancel && <CxButton label={trans('field.cancel')} style={{ width: '100px' }} onClick={() => doProcessCancel()} />}
          </div>}
          {!isProcessing() && showButtonExecute && <CxButton label={caption} style={{ width: 'var(--ibs_control_width_lg)' }} onClick={() => r_dlg_pin.current.setOpen(true)} />}
        </div>
      </div>

      <CxDlgPin xref={r_dlg_pin} onOk={doProcessExecute} />
      <CxSnackbar xref={r_snack_error} severity="error" />

    </div>
  );
}

export default GxLongTask;
