import React, { useImperativeHandle } from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
import '../../styles/Dialog/Dialog.css';
import '../../styles/main.css';
import CxButton from "./CxButton";
import * as FxStr from '../fx/FxStr';
import { useTranslation } from '../hx/useTranslation';
// eslint-disable-next-line
import * as FxLog from '../fx/FxLog';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles({
  dialogPaper: {
    color: props => props.color || 'var(--ibs_color_black)',
    background: props => props.background || 'var(--ibs_color_white)',
    minHeight: props => props.height,
    maxHeight: props => props.height,
    //position: props => props.isLargeWindow ? "absolute" : "", /* centra ventana en el parent e independientemente de formato móvil o desktop */
    //left: props => props.isLargeWindow ? "50%" : "", /* centra ventana en el parent e independientemente de formato móvil o desktop */
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    width: props => props.width || 'var(--ibs_control_width_sm)',
    height: props => props.height,
    "&.MuiDialogContent-root:first-child": {
      padding: '10px',
    },
  },
});

const CxDlgAlert = props => {
  const [{ trans }] = useTranslation();
  const classes = useStyles(props);
  // eslint-disable-next-line
  const { onCancel, onClose, onOk, onYes, onNo, autoClose, onAutoClose, ...rest } = props;
  const timer = React.useRef(null);
  const r_milliseconds = React.useRef(null);

  const [open, setOpen] = React.useState(false);
  const [text, setText] = React.useState(rest.text || trans('msg.please_wait'));
  const [icon, setIcon] = React.useState(rest.icon || '');
  const [progressMsg, setProgressMsg] = React.useState('');
  const [textColor, setTextColor] = React.useState(rest.textColor || "var(--ibs_color_blue)");
  const [hasButtonClose, setHasButtonClose] = React.useState(props.hasButtonClose);
  const [width, setWidth] = React.useState(props.width);
  const [progressBarPerc, setProgressBarPerc] = React.useState(0);

  React.useEffect(() => {
    FxLog.infox("CxDlgAlert.useEffect", "useEffect...");

    return () => {
      FxLog.infox("CxDlgAlert.cleaning up timer");
      clearInterval(timer.current);
    }
    // eslint-disable-next-line
  }, []);

  useImperativeHandle(props.xref, () => {
    return {
      getType, setAutoClose, setAutoCloseMilliseconds, setProgressMsg, setOpen, setText, setTextColor, setHasButtonClose, clear, setIcon, setWidth, 
      setProgressBarPerc
    };
  });
  const getType = () => { return 'CxDlgAlert' };
  const setAutoCloseMilliseconds = (ms) => { r_milliseconds.current = ms };

  /** vuelve a los valores predeterminados */
  const clear = () => {
    setText(trans('msg.please_wait'));
    setProgressMsg('');
    setTextColor("var(--ibs_color_blue)");
    setHasButtonClose(false);
  };

  const doClose = () => {
    setOpen(false);
    setAutoClose(false);
    setProgressBarPerc(0);
    if (onClose) {
      onClose();
    }
  }

  const setAutoClose = (boRun) => {
    try {
      if (boRun) {
        timer.current = setInterval(() => {
          FxLog.infox("CxDlgAlert.setAutoClose", "timer is executing !");
          if (onAutoClose !== undefined) {
            onAutoClose();
          }
          doClose();
        }, r_milliseconds.current||5000); //milisegundos

      } else {
        clearInterval(timer.current);
      }

    } catch (error) {
      FxLog.errorx("CxDlgAlert.setAutoClose", error);
    }
  };

  return (
    <div>
      <Dialog classes={{ paper: classes.dialogPaper }} hideBackdrop={false} open={open} >
        <DialogContent classes={{ root: classes.dialogContent }}>
          {icon}
          <p style={{
            color: textColor,
            fontSize:  props.fontSize || '14px',
            textTransform: FxStr.choose(props.fontCase||'UC', 'MIX|UC|LC', '', 'uppercase', 'lowercase'),
            width: width || 'var(--ibs_control_width_sm)',
            wordWrap: 'break-word',
            textAlign: 'center',
            whiteSpace: 'pre-wrap' /* preserva saltos de carro */ 
          }} >{text}</p>
          <p>{progressMsg}</p>
          {progressBarPerc>0 && <div style={{ height: '4px', width: '200px', backgroundColor: 'var(--ibs_color_gray_light)'}}>
            <LinearProgress variant="determinate" value={progressBarPerc} />
          </div>}
          {onCancel && progressMsg && <CxButton label={trans('field.cancel')} onClick={onCancel} style={{ width: '200px' }} />}
          {onOk && <CxButton label={trans('field.ok')} onClick={onOk} style={{ width: '200px' }} />}
          {onNo && <CxButton label={trans('field.no')} onClick={onNo} style={{ width: '200px' }} />}
          {onYes && <CxButton label={trans('field.yes')} onClick={onYes} style={{ width: '200px' }} />}
          {hasButtonClose && <CxButton label={trans('field.close')} onClick={doClose} style={{ width: '200px' }} />}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CxDlgAlert;
