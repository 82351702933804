// eslint-disable-next-line no-unused-vars
import * as FxLog from './FxLog';
import { getStoreValue, isApp } from "../gx/GxStore";


const editionInvo = {
  /* FREE */
  CLI:        0,
  EMP:        0,
  SUP:        0,
  PSS:        0,
  CAL:        0,
  /* STARTER */
  SLS:        1,
  BCH:        1, /** sucursales */
  OPT:        1, /** opciones */
  USR:        1, /** usuarios */
  GRP:        1, /** grupos */
  /* JUNIOR */
  CLI_img:    3,
  SLS_unl:    3,
  CSH:        3,
  COM:        3,
  HIS:        3,
  /* PROFESIONAL */
  MKT:        4, /** comunicación masiva */
  COM_wha:    4, /** whatsapp */
  PUR:        4,
  STO:        4,
  STO_lot:    4, /** lotes y caducidades */
  STA_bas:    4, /** estadísticas básicas */
  BLG:        4, /** facturación masiva */
  PUR_ord:    4, /** pedidos automáticos */
  PUR_inv:    4, /** importación automática de albaranes de proveedor */
  SLS_rat:    4, /** tasas */
  PSS_cmp:    4, /** pss componentes */
  STA_adv:    4, /** estadísticas avanzadas */
  REF:        4, /** referentes */
  MME:        4, /** multimedia */
  CLI_mme:    4, /** multimedia de clientes */
  XXX_sig:    4, /** digital signature */
  /* PREMIUM */
  PLN:        6, /** planes */
  DDB:        6, /** domiciliación bancaria */
  ANL:        6, /** analítica financiera */
};

const editionOpti = {
  /* FREE */
  CLI:        0,
  EMP:        0,
  SUP:        0,
  PSS:        0,
  CAL:        0,
  /* STARTER */
  SLS:        1,
  BCH:        1, /** sucursales */
  OPT:        1, /** opciones */
  USR:        1, /** usuarios */
  GRP:        1, /** grupos */
  /* JUNIOR */
  SLS_unl:    3,
  CLI_rpl:    3,
  CLI_img:    3,
  CSH:        3,
  COM:        3,
  HIS:        3,
  /* PROFESIONAL */
  MKT:        4, /** comunicación masiva */
  COM_wha:    4, /** whatsapp */
  LAB:        4, /** laboratorio */
  LAB_ord:    4, /** encargos (lab orders) */
  PUR:        4,
  STO:        4,
  STO_lot:    4, /** lotes y caducidades */
  STA_bas:    4, /** estadísticas básicas */
  BLG:        4, /** facturación masiva */
  PUR_ord:    4, /** pedidos automáticos */
  PUR_inv:    4, /** importación automática de albaranes de proveedor */
  SLS_rat:    4, /** tasas */
  PSS_cmp:    4, /** pss componentes */
  STA_adv:    4, /** estadísticas avanzadas */
  REF:        4, /** referentes */
  MME:        4, /** multimedia */
  CLI_mme:    4, /** multimedia de clientes */
  XXX_sig:    4, /** digital signature */
  /* PREMIUM */
  PLN:        6, /** planes */
  DDB:        6, /** domiciliación bancaria */
  ANL:        6, /** analítica financiera */
};

const editionVett = {
  /* FREE */
  CLI:        0,
  EMP:        0,
  SUP:        0,
  PSS:        0,
  CAL:        0,
  /* STARTER */
  SLS:        1,
  BCH:        1, /** sucursales */
  OPT:        1, /** opciones */
  USR:        1, /** usuarios */
  GRP:        1, /** grupos */
  /* JUNIOR */
  SLS_unl:    3,
  CLI_gro:    3,
  CLI_hos:    3,
  CLI_img:    3,
  CSH:        3,
  COM:        3,
  HIS:        3,
  /* PROFESIONAL */
  MKT:        4, /** comunicación masiva */
  COM_wha:    4, /** whatsapp */
  WAI:        4, /** sala de espera */
  CLI_rxs:    4, /** recetas */
  PUR:        4,
  STO:        4,
  STO_lot:    4, /** lotes y caducidades */
  STA_bas:    4, /** estadísticas básicas */
  BLG:        4, /** facturación masiva */
  PUR_ord:    4, /** pedidos automáticos */
  PUR_inv:    4, /** importación automática de albaranes de proveedor */
  SLS_rat:    4, /** tasas */
  PSS_cmp:    4, /** pss componentes */
  STA_adv:    4, /** estadísticas avanzadas */
  REF:        4, /** referentes */
  MME:        4, /** multimedia */
  CLI_mme:    4, /** multimedia de clientes */
  XXX_sig:    4, /** digital signature */
  /* PREMIUM */
  PLN:        6, /** planes */
  DDB:        6, /** domiciliación bancaria */
  ANL:        6, /** analítica financiera */
};

const editionSpaw = {
  /* FREE */
  CLI:        0,
  EMP:        0,
  SUP:        0,
  PSS:        0,
  CAL:        0,
  /* STARTER */
  SLS:        1,
  BCH:        1, /** sucursales */
  OPT:        1, /** opciones */
  USR:        1, /** usuarios */
  GRP:        1, /** grupos */
  /* JUNIOR */
  SLS_unl:    3,
  CLI_gro:    3,
  CLI_img:    3,
  CSH:        3,
  COM:        3,
  HIS:        3,
  /* PROFESIONAL */
  MKT:        4, /** comunicación masiva */
  COM_wha:    4, /** whatsapp */
  WAI:        4, /** sala de espera */
  PUR:        4,
  STO:        4,
  STO_lot:    4, /** lotes y caducidades */
  STA_bas:    4, /** estadísticas básicas */
  BLG:        4, /** facturación masiva */
  PUR_ord:    4, /** pedidos automáticos */
  PUR_inv:    4, /** importación automática de albaranes de proveedor */
  SLS_rat:    4, /** tasas */
  PSS_cmp:    4, /** pss componentes */
  STA_adv:    4, /** estadísticas avanzadas */
  REF:        4, /** referentes */
  MME:        4, /** multimedia */
  CLI_mme:    4, /** multimedia de clientes */
  XXX_sig:    4, /** digital signature */
  /* PREMIUM */
  PLN:        6, /** planes */
  DDB:        6, /** domiciliación bancaria */
  ANL:        6, /** analítica financiera */
};


export const getEdition = () => {
  const edition = getStoreValue('StoreSession', 'txEdition')||''.toUpperCase();
  return edition;
}

export const getEditionNumber = () => {
  const edition = getEdition();
  let edNumber = 0;
  switch (edition) {
    case 'PM': edNumber = 6; break;
    case 'PF': edNumber = 4; break;
    case 'JR': edNumber = 3; break;
    case 'ST': edNumber = 1; break;
    case 'FR': edNumber = 0; break;
    default: break;
  }
  return edNumber;
}

export const isInEdition = (key) => {
  let edNumber = getEditionNumber();
  let rv = null;
  if (isApp('invo')) rv = Boolean(edNumber >= editionInvo[key]); 
  if (isApp('opti')) rv = Boolean(edNumber >= editionOpti[key]); 
  if (isApp('vett')) rv = Boolean(edNumber >= editionVett[key]); 
  if (isApp('spaw')) rv = Boolean(edNumber >= editionSpaw[key]); 
  return rv;
}

export const isPremiumNeeded = () => {
  let txEdition = getStoreValue('StoreSession', 'txEdition');
  let nuSalesLastMonth = getStoreValue('StoreCyStats', 'nuSalesLastMonth');
  if (nuSalesLastMonth && nuSalesLastMonth > 1000 && txEdition!=='PM') {
    return true;
  } else {
    return false;
  }
}
