import React, { useImperativeHandle } from "react";
import '../../styles/main.css';
import CxSnackbar from './CxSnackbar';
import { useTranslation } from '../hx/useTranslation';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../fx/FxLog';


const CxSpeech = props => {
  const [{ trans }] = useTranslation();
  const { id, setVal, onSpeechFinish } = props;

  const r_active_id = React.useRef(null); //sirve para evitar que si hay varios inputs con reconocimiento de voz, escriba a todos juntos en lugar que al que corresponde.
  const r_snack_speak_now = React.useRef(null);
  const r_snack_browser_does_not_support = React.useRef(null);

  const {
    transcript: srecon_transcript,
    listening: srecon_listening,
    //resetTranscript: srecon_resetTranscript,
    browserSupportsSpeechRecognition: srecon_browserSupportsSpeechRecognition
  } = useSpeechRecognition();


  React.useEffect(() => {
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (r_active_id.current===id) {
      Promise.all([setVal(srecon_transcript)]).then((result) => {
        if (onSpeechFinish) {
          onSpeechFinish();
        }
      });
    }
    // eslint-disable-next-line
  }, [srecon_transcript]);

  React.useEffect(() => {
    if (!srecon_listening) {
      r_active_id.current = '';
    }
    // eslint-disable-next-line
  }, [srecon_listening]);

  useImperativeHandle(props.xref, () => {
    return {
      getType, start, 
    };
  });
  const getType = () => { return 'CxSpeech' };

  const start = (activeId) => {
    r_active_id.current = activeId;
    SpeechRecognition.startListening({ continuous: false });
    if (srecon_browserSupportsSpeechRecognition) {
      r_snack_speak_now.current.setOpen(true);
    } else {
      r_snack_browser_does_not_support.current.setOpen(true);
    }
  };

  return (
    <>
      <CxSnackbar xref={r_snack_speak_now} severity="warning" value={trans('msg.speak_now')} />
      <CxSnackbar xref={r_snack_browser_does_not_support} severity="error" value={trans('msg.browser_doesnt_support_x', 'field.voice_recognition')} />
    </>
  );

};

export default CxSpeech;
