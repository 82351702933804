import { MdOutlinePets } from "react-icons/md"; 
import React from 'react';
import GxForm from '../../gx/GxForm';
import { useTranslation } from '../../hx/useTranslation';
import CxInput from '../../cx/CxInput';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import * as FxDat from '../../fx/FxDat';
import CxSnackbar from '../../cx/CxSnackbar';
import CxChooser from '../../cx/CxChooser';
import CxDate from '../../cx/CxDate';
import CxSearcher from '../../cx/CxSearcher';
import CxTime from '../../cx/CxTime';
import CxIconButtonNew from '../../cx/CxIconButtonNew';
//import CxHidden from '../../cx/CxHidden';
import { useNav } from '../../hx/useNav';
import CxSwitch from '../../cx/CxSwitch';
import { StoreWaiting, isApp, StoreClient, clearStore } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import { useAccess } from '../../hx/useAccess';


const MxWaitingForm = (props) => {
  const [{ trans }] = useTranslation();
  const [{ isFullAccess }] = useAccess(trans('field.waiting_room'));
  const [{ navTo }] = useNav();
  const r_form = React.useRef(null);

  const rf_txTypeStatusWaiting = React.useRef(null);
  const rf_issue = React.useRef(null);
  const rf_alert = React.useRef(null);

  const rf_boPresent = React.useRef(null);

  const rf_date = React.useRef(null);
  const rf_time = React.useRef(null);

  const rf_txClient = React.useRef(null);
  const rf_txAnimal = React.useRef(null);
  const rf_txEmployee = React.useRef(null);

  const [storeWaiting, setStoreWaiting] = useSessionStorage('StoreWaiting', StoreWaiting);
  const [storeClient, setStoreClient] = useSessionStorage('StoreClient', StoreClient);

  const r_snack_ask_save = React.useRef(null);
  const r_snack_error = React.useRef(null);


  React.useEffect(() => {
    clearStore('StoreClient', storeClient);
    // eslint-disable-next-line
  }, []);

  const formLoad = async (data) => {
    if (data === null) { 
      // NUEVO REGISTRO
      rf_date.current.setValToday();
      rf_time.current.setValNow();
      rf_txTypeStatusWaiting.current.setKeyAndVal('t_status_waiting', 'txTypeStatusWaiting', 'itStatusWaiting', '000001.SRV');
      
    } else {
      // REGISTRO EXISTENTE
      // guardamos cliente y paciente en store
      storeClient.inClient = data[0].inClient;
      storeClient.txClient = data[0].txClient;
      if ((isApp('vett') || isApp('spaw'))) {
        storeClient.inAnimal = data[0].inAnimal;
        storeClient.txAnimal = data[0].txAnimal;
        // filtro de animales para el paciente seleccionado
        rf_txAnimal.current.setFilter(`n_animal.inClient='${data[0].inClient}'`);
      }
      setStoreClient(storeClient);
    }
  }

  const formSave = async (lastSaveRv) => {
    storeWaiting.inWaiting = lastSaveRv.theValue;
    Promise.all([setStoreWaiting(storeWaiting)]).then((result) => {});
    // guardamos cliente y paciente en store
    storeClient.inClient = rf_txClient.current.getKey();
    storeClient.txClient = rf_txClient.current.getVal();
    if ((isApp('vett') || isApp('spaw'))) {
      storeClient.inAnimal = rf_txAnimal.current.getKey();
      storeClient.txAnimal = rf_txAnimal.current.getVal();
    }
    setStoreClient(storeClient);
  }

  const formDelete = async (rv) => {
  }

  const clientChanged = async (row) => {
    if (row !== null) {
      // inyecta filtros en el combo paciente
      let inClient = row.original.inClient;
      if (isApp('vett')) {
        rf_txAnimal.current.setFilter(`n_animal.inClient='${inClient}'`);
        rf_txAnimal.current.clear();
      }
    }
  }

  const openFormAnimal = () => {
    if (!storeClient.inAnimal) {
      r_snack_error.current.setValAndOpen(trans('msg.save_x_before', 'field.patient'));
    } else {
      storeClient.inClient = rf_txClient.current.getKey();
      storeClient.txClient = rf_txClient.current.getVal();
      storeClient.inAnimal = rf_txAnimal.current.getKey();
      storeClient.txAnimal = rf_txAnimal.current.getVal();
      setStoreClient(storeClient);
      navTo("animal-form");
    }
  }

  return (
    <div>
      <GxForm
        moduleName={`${trans('field.waiting_room')}`}
        xref={r_form}
        url="waiting"
        filterIdField='inWaiting' 
        filterIdValue={storeWaiting.inWaiting} 
        onFormLoad={formLoad}
        onFormSave={isFullAccess() && formSave}
        onFormDelete={isFullAccess() && formDelete} 
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {(isApp('vett') || isApp('spaw')) && <CxIconButtonNew icon={<MdOutlinePets fontSize={23} />} classType="ibs_iconbutton_toolbar" onClick={openFormAnimal} tooltip={trans('field.patient')} />}
          </div>
        }
      >

        <CxDate xref={rf_date} label={`${trans('field.date')}`} dbfield={`n_waiting.dtIssue`} required />
        <CxTime xref={rf_time} label={`${trans('field.time')}`} dbfield={`n_waiting.dtIssue`} required />

        <CxChooser xref={rf_txTypeStatusWaiting} label={trans('field.status')} 
          dbfield="t_status_waiting.txTypeStatusWaiting" dbfieldkey={`n_waiting.itStatusWaiting`}
          table="t_status_waiting"
          col_id="itStatusWaiting"
          col_tx="txTypeStatusWaiting"
          required
        />

        <CxSwitch xref={rf_boPresent} label={trans('field.present')} dbfield="n_waiting.boPresent" />
        <CxInput xref={rf_alert} label={trans('field.alert')} dbfield={`n_waiting.txAlert`} maxLength={200} redText />

        <CxSearcher xref={rf_txClient} label={trans('field.client')} 
          dbfield={`n_waiting.txClient`} dbfieldkey={`n_waiting.inClient`}
          onClear={() => {if (rf_txAnimal.current) rf_txAnimal.current.clear();}}
          onSelection={clientChanged}
          url="clients/searcher"
          col_id="inClient"
          col_tx="txClient"
          colorRefs={ [ 'table_cell_red|' + trans('field.debtor') ] }
          tooltip={`${trans('field.client')}`}
          columns={[
            { id: "0", Header: "ID", accessor: "inClient", show: false },
            { id: "1", Header: trans('field.client'), accessor: row => rf_txClient.current.getTable().highlightText(row["txClient"]), width: '100%' },
            { id: "R", Header: "balance", accessor: row => Boolean((row["theBalance"]) < 0 ? true : false), show: false },
          ]}
        />

        {isApp('vett') &&
          <CxSearcher xref={rf_txAnimal}
            dbfield="n_waiting.txAnimal" dbfieldkey="n_waiting.inAnimal"
            label={trans('field.patient')}
            url="animals" col_id="inAnimal" col_tx="txAnimal"
            canOpenDialog={() => { return rf_txClient.current.getKey() !== ''; }}
            tooltip={`${trans('field.patient')}`}
            columns={[
              { id: "0", Header: "ID", accessor: "inAnimal", show: false },
              { id: "1", Header: trans('field.patient'), accessor: row => rf_txAnimal.current.getTable().highlightText(row["txAnimal"]), width: '100%' },
              { id: "A", Header: "inactive", accessor: row => FxDat.isExpired(row["dtTo"]), show: false },
            ]}
            colorRefs={[
              'table_cell_gray|' + trans('field.inactive'),
            ]}
          />
        }

        <CxSearcher xref={rf_txEmployee} label={trans('field.employee')}
          dbfield="n_employee.txEmployee" dbfieldkey="n_waiting.inEmployee"
          url="employees" col_id="inEmployee" col_tx="txEmployee"
          columns={[
            { id: "0", Header: "ID", accessor: "inEmployee", show: false },
            { id: "1", Header: trans('field.employee'), accessor: row => rf_txEmployee.current.getTable().highlightText(row["txEmployee"]), width: '100%' },
          ]}
        />

        <CxInput xref={rf_issue} label={trans('field.issue')} dbfield={`n_waiting.txIssue`} minRows={4} maxLength={255} />

      </GxForm>

      <CxSnackbar xref={r_snack_ask_save} severity="error" value={trans('msg.save_before')} />
      <CxSnackbar xref={r_snack_error} severity="error" />

    </div>
  );

}

export default MxWaitingForm;
