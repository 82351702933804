import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useNav } from '../../hx/useNav';
import { useTranslation } from '../../hx/useTranslation';
import '../../../styles/main.css';
import CxDate from '../../cx/CxDate';
import CxChooser from '../../cx/CxChooser';
import CxMultiCheck from '../../cx/CxMultiCheck';
import CxIconButton from '../../cx/CxIconButton';
import CxToolbar from '../../cx/CxToolbar';
import CxSearcher from '../../cx/CxSearcher';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import * as FxDat from '../../fx/FxDat';
import { StoreCash, getStoreValue } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import CxSnackbar from '../../cx/CxSnackbar';
import GxIconButtonLastClient from '../../gx/GxIconButtonLastClient';


const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'flex-start',
    gap: 'var(--ibs_container_gap)',
    margin: 'var(--ibs_container_margin)',
  },
}));

const MxCashFilter = (props) => {
  const [{ trans }] = useTranslation();
  const classes = useStyles(props);
  const [{ navTo }] = useNav();
  const rf_dtFrom = React.useRef(null);
  const rf_dtTo = React.useRef(null);
  const rf_txTypeCash = React.useRef(null);
  const rf_txClient = React.useRef(null);
  const rf_txTypePayments = React.useRef(null);

  const [storeCash, setStoreCash] = useSessionStorage('StoreCash', StoreCash);

  let r_snack_error = React.useRef(null);

  React.useEffect(() => {
    formLoad();
    // eslint-disable-next-line
  }, []);

  const formLoad = async () => {
    rf_dtFrom.current.setVal(storeCash.dtCashItemFrom || FxDat.today());
    rf_dtTo.current.setVal(storeCash.dtCashItemTo || FxDat.today());
    rf_txTypeCash.current.setKey(storeCash.itCash || getStoreValue('StoreSession', 'itCash'));
    rf_txTypeCash.current.setVal(storeCash.txTypeCash || getStoreValue('StoreSession', 'txTypeCash'));
    rf_txClient.current.setKey(storeCash.inClient);
    rf_txClient.current.setVal(storeCash.txClient);
    rf_txTypePayments.current.setVal(storeCash.arPayments);
  }

  const goNext = async () => {
    FxLog.infox("MxCashFilter.goNext", "..." + rf_dtFrom.current.getVal() + ' - ' + rf_dtTo.current.getVal());

    storeCash.dtCashItemFrom = rf_dtFrom.current.getVal();
    storeCash.dtCashItemTo = rf_dtTo.current.getVal();
    storeCash.itCash = rf_txTypeCash.current.getKey();
    storeCash.txTypeCash = rf_txTypeCash.current.getVal();
    storeCash.inClient = rf_txClient.current.getKey();
    storeCash.txClient = rf_txClient.current.getVal();
    storeCash.arPayments = rf_txTypePayments.current.getVal();
    setStoreCash(storeCash);

    navTo("cash-list");
  }

  return (
    <div>
      <CxToolbar
        //help="cash"
        moduleName={`${trans('field.cash_reg')}: ${trans('field.filter')}`}
        addToolbarButtons={
          <>
            <GxIconButtonLastClient theInput={rf_txClient} />
            <CxIconButton type="chevron_right" classType="ibs_iconbutton_toolbar" onClick={goNext} tooltip={trans('field.next')} />
          </>
        }
      />

      <div className={classes.container}>
        <CxDate xref={rf_dtFrom} label={trans('field.from')} required />
        <CxDate xref={rf_dtTo} label={trans('field.to')} required />

        <CxChooser xref={rf_txTypeCash}
          label={trans('field.cash_reg')}
          dbfield="t_cash.txTypeCash" dbfieldkey="n_cash_item.itCash"
          table="t_cash"
          col_id="itCash"
          col_tx="txTypeCash"
          readOnly={false}
          required
        />

        <CxSearcher xref={rf_txClient} label={trans('field.client')} dbfield={`n_client.txClient`} dbfieldkey={`n_cash_item.inClient`}
          id="n_client"
          url="clients/searcher"
          col_id="inClient"
          col_tx="txClient"
          colorRefs={['table_cell_red|' + trans('field.debtor')]}
          tooltip={`${trans('field.client')}`}
          columns={[
            { id: "0", Header: "ID", accessor: "inClient", show: false },
            { id: "1", Header: trans('field.client'), accessor: row => rf_txClient.current.getTable().highlightText(row["txClient"]), width: '100%' },
            { id: "R", Header: "balance", accessor: row => Boolean((row["theBalance"]) < 0 ? true : false), show: false },
          ]}
          readOnly={false}
        />

        <CxMultiCheck mode='checkbox' xref={rf_txTypePayments} label={trans('field.payments')} minRows={4} itemWidth="150px"
          tableName="t_payment" idField="itPayment" txField="txTypePayment"
        />

      </div>

      <CxSnackbar xref={r_snack_error} severity="error" />

    </div>
  );

}

export default MxCashFilter;
