import React, { useImperativeHandle } from "react";
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../fx/FxLog';
import CxTooltip from './CxTooltip';


const CxButton = props => {
  const { label, onClick, width, ...rest } = props;
  const [visible, setVisible] = React.useState(rest.visible || true);
  
  const rf_tooltip = React.useRef(null);
  
  React.useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const doClick = (e) => {
    e.preventDefault();
    props.onClick(e);
  };

  useImperativeHandle(props.xref, () => {
    return {
      getType, setVisible, getTooltip
    };
  });
  const getType = () => { return 'CxButton' };
  const getTooltip = () => { return rf_tooltip.current };

  return (
    <div>
      {visible && 
        <CxTooltip xref={rf_tooltip} text={rest.tooltip ? rest.tooltip : null}>
          <button className={rest.className || "ibs_button"} style={{ width: width||'100px' }} onClick={(e) => doClick(e)} {...rest} >
            {props.label}
          </button>
        </CxTooltip>
      }
    </div>
  );

};

export default CxButton;
