import React, { useImperativeHandle } from "react";
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../fx/FxLog';
import { makeStyles } from '@material-ui/core/styles';
import { useNav } from '../hx/useNav';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ArrowBack from '@material-ui/icons/ArrowBack';
import HelpOutline from '@material-ui/icons/HelpOutline';
import { getStoreValue } from "../gx/GxStore";

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: '2px',
    zIndex: 5,
  },
  toolbar: {
    height: '30px',
    padding: '0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    gap: '0px',
    background: props => props.background||'var(--ibs_color_gray_light)',
    boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px',
    /* boxShadow: 'rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px', */
    /* boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px', */
    "&.MuiToolbar-regular": {
      minHeight: '0px',
      padding: '0px 0px 5px 6px',
    },
  },
  frame_text: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
    height: '30px',
    padding: '0px 10px 12px 6px',
    [theme.breakpoints.up('sm')]: {
      padding: '0px 10px 12px 7px',
    },
  },
  text: {
    textAlign: 'left',
    /* fontFamily: 'var(--ibs_control_label_font_family)', */
    fontSize: '10px',
    /* lineHeight: '12px', */
    color: props => props.color||'var(--ibs_color_back)', 
    [theme.breakpoints.up('sm')]: {
      fontSize: '14px',
      /* paddingLeft: '0px', */
      /* lineHeight: '20px', */
    },
  },
  buttonBack: {
    margin: '5px -5px 0px 0px',
    /* backgroundColor: "#EFD26E", */
    color: 'var(--ibs_color_cy_blue)',
    height: '30px',
    width: '30px',
    position: "relative",
    "&:hover": {
      backgroundColor: 'var(--ibs_color_white)'
    }
  },
  buttonHelp: {
    margin: '5px -5px 0px 0px',
    backgroundColor: "transparent",
    color: 'var(--ibs_color_cy_blue)',
    height: '30px',
    width: '30px',
    position: "relative",
    "&:hover": {
      backgroundColor: 'var(--ibs_color_white)'
    }
  },
  toolbox: {
    padding: '5px 7px 0 10px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    gap: props => { return props.gap ||'0px' },
  },
}));

const CxToolbar = props => {
  const classes = useStyles(props);
  const { help } = props;
  const [{ navBack, navCount }] = useNav(false);

  React.useEffect(() => {
    //FxLog.infox('CxToolbar.useEffect', '...props', props);
    // eslint-disable-next-line
  }, []);

  useImperativeHandle(props.xref, () => {
    return {
      getType, 
    };
  });
  const getType = () => { return 'CxToolbar' };

  const doBackCheck = () => {
    if (props.canGoBack !== undefined) {
      if (props.canGoBack()) {
        doBack();
      }
    } else {
      doBack();
    }
  }

  const doBack = () => {
    if (props.onGoBack !== undefined) {
      Promise.all([props.onGoBack()]).then((result) => {
        FxLog.infox('CxToolbar.doBack', '...', );
        navBack();
      });
    } else {
      navBack();
    }
  }

  return (
    <div className={classes.container}>
      <Toolbar className={classes.toolbar}>
        {navCount() > 1 &&
          <IconButton edge="start" className={classes.buttonBack} onClick={doBackCheck} tabIndex='-1'>
            <ArrowBack />
          </IconButton>
        }
        {navCount() <= 1 && help &&
          <a href={`https://iberical.com/ibericalhelp/${getStoreValue('StoreSession', 'txLocale').substring(0,2)}_${getStoreValue('StoreSession', 'txApp')}.php#section_mods_${help}`} target="_blank" rel="noreferrer" >
            <IconButton edge="start" className={classes.buttonHelp} tabIndex='-1'>
              <HelpOutline />
            </IconButton>
          </a>
        }

        <div className={classes.frame_text}>
          <p className={classes.text}>{(props.moduleName || '').toUpperCase()}&nbsp;</p>
        </div>

        <span className={classes.toolbox}>
          {props.addToolbarButtons}
          {props.children}
        </span>

      </Toolbar>
    </div>

  );

};

export default CxToolbar;
