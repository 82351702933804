import React from 'react';
import i18n from '../../i18n';
import '../../styles/main.css';
import CxInput from '../cx/CxInput';
import CxSnackbar from '../cx/CxSnackbar';
import CxButton from '../cx/CxButton';
import * as FxLog from '../fx/FxLog';
import packageJson from '../../../package.json';
import { StoreSession, clearStore, loadSession } from "../gx/GxStore";
import { useSessionStorage } from '../hx/useSessionStorage';
import * as FxFetch from '../fx/FxFetch';
import * as FxInet from '../fx/FxInet';
import * as FxDat from '../fx/FxDat';
import CxSwitch from '../cx/CxSwitch';
//import { Navigate } from "react-router-dom";
import { useTranslation } from '../hx/useTranslation';
import { useAccess } from '../hx/useAccess';
import { useWindowSize } from '../hx/useWindowSize';
import { useNav } from '../hx/useNav';

const MxLoginCP = () => {
  const size = useWindowSize();
  const [{ storeTransInSession }] = useTranslation();
  const [{ storeAccessInSession }] = useAccess();
  const [{ navTo }] = useNav();

  const rf_txIdi = React.useRef(null);
  const rf_txUsername = React.useRef(null);
  const rf_txPassword = React.useRef(null);
  const rf_rem = React.useRef(false);

  const [localIp, setLocalIp] = React.useState(null);

  const r_snack_loading = React.useRef(null);
  const r_snack_error = React.useRef(null);

  // eslint-disable-next-line
  const [storeSession, setStoreSession] = useSessionStorage('StoreSession', StoreSession);

  const [disabled, setDisabled] = React.useState(false);


  // eslint-disable-next-line
  const [isSystemUpdated, setIsSystemUpdated] = React.useState(Boolean(localStorage.getItem('IBS.msg_updated') === '1'));


  React.useEffect(() => {
    localStorage.setItem('IBS.msg_updated', "0");

    let idi = localStorage.getItem('MxLoginCP.idi');
    let usr = localStorage.getItem('MxLoginCP.username');
    let pwd = localStorage.getItem('MxLoginCP.password');
    let rem = localStorage.getItem('MxLoginCP.remember');

    // si hay parámetros en la url, los usa
    // ex: http://localhost:3000/login?idi=es.iberical_test_opti&username=master&password=bubastis
    const queryParameters = new URLSearchParams(window.location.search)
    if (queryParameters.get("idi")) {
      idi = queryParameters.get("idi");
      usr = queryParameters.get("username");
      pwd = queryParameters.get("password");
      rem = null;
    }

    rf_txIdi.current.setVal(idi || '');
    rf_rem.current.setVal(rem);
    rf_txUsername.current.setVal(usr);
    rf_txPassword.current.setVal(pwd);

    clearStore('StoreSession', storeSession);

    Promise.all([FxInet.getIP()]).then((result) => {
      setLocalIp(result[0]||'');
    });

    // eslint-disable-next-line
  }, []);

  // Verifica si se puede acceder al programa.
  const login = () => {
    //-----------------------------------------------------------------------
    //let txBrowserLang = i18n.language;
    //-----------------------------------------------------------------------
    let params = "";
    params += "txIp=" + localIp;
    params += "&txDevice=" + (size.isLarge ? 'Desktop/Tablet' : 'Mobile');
    params += "&dtLogin=" + FxDat.getDateToday();
    params += "&txIdi=" + rf_txIdi.current.getVal().trim();
    params += "&inBranch=" + (localStorage.getItem('MxLoginCP.branch') || '');
    params += "&txUsername=" + rf_txUsername.current.getVal().trim();
    params += "&txPassword=" + rf_txPassword.current.getVal().trim();
    setDisabled(true);
    r_snack_loading.current.setOpen(true);
    Promise.all([FxFetch.doUrl("cp/login", params)]).then((result) => {
      if (result[0] === null) {
        setDisabled(false);
        r_snack_loading.current.setOpen(false);
        r_snack_error.current.setVal(i18n.t('msg.connection_refused'));
        r_snack_error.current.setOpen(true);
      } else {
        if (result[0].theValue === 'OK') {
          // ACCESO ACEPTADO *********************************************************************
          localStorage.setItem('MxLoginCP.idi', rf_txIdi.current.getVal().trim());
          if (rf_rem.current.isChecked()) {
            localStorage.setItem('MxLoginCP.username', rf_txUsername.current.getVal().trim());
            localStorage.setItem('MxLoginCP.password', rf_txPassword.current.getVal().trim());
            localStorage.setItem('MxLoginCP.remember', rf_rem.current.getVal());
          } else {
            localStorage.setItem('MxLoginCP.username', '');
            localStorage.setItem('MxLoginCP.password', '');
            localStorage.setItem('MxLoginCP.remember', '');
          }
          // INICIALIZACIÓN GENERAL
          Promise.all([
            loadSession(result[0]),
            loadRbl(),
            loadAccess(),
          ]).then((result) => {
            navTo("appointment-list-cp");
          });
          // *************************************************************************************
        } else {
          setDisabled(false);
          r_snack_loading.current.setOpen(false);
          // ACCESO RECHAZADO ********************************************************************
          if (result[0].theValue === 'rv_user_not_related_to_client') {
            // el usuario no está relacionado a un ciente
            r_snack_error.current.setVal(i18n.t('msg.user_not_related_to_client'));
            r_snack_error.current.setOpen(true);
          }
          if (result[0].theValue === 'rv_cannot_add_db') {
            // no puede conectar a la base de datos; servidor caído?
            r_snack_error.current.setVal(i18n.t('msg.cannot_add_db'));
            r_snack_error.current.setOpen(true);
          }
          if (result[0].theValue === 'rv_cannot_connect_to_db') {
            // no puede conectar a la base de datos; servidor caído?
            r_snack_error.current.setVal(i18n.t('msg.cannot_connect_to_db'));
            r_snack_error.current.setOpen(true);
          }
          if (result[0].theValue === 'rv_incorrect_idi') {
            // el idi pasado en el login o el de la tabla n_branch es incorrecto.
            r_snack_error.current.setVal(i18n.t('msg.incorrect_idi'));
            r_snack_error.current.setOpen(true);
          }
          if (result[0].theValue === 'rv_incomplete_login_credentials') {
            // no se ha completado idi, usuario o password.
            r_snack_error.current.setVal(i18n.t('msg.incomplete_login_credentials'));
            r_snack_error.current.setOpen(true);
          }
          if (result[0].theValue === 'rv_subscription_not_current') {
            r_snack_error.current.setVal(i18n.t('msg.subscription_not_current'));
            r_snack_error.current.setOpen(true);
          }
          if (result[0].theValue === 'rv_application_db_not_active') {
            r_snack_error.current.setVal(i18n.t('msg.application_db_not_active'));
            r_snack_error.current.setOpen(true);
          }
          if (result[0].theValue === 'rv_incorrect_user_pwd') {
            // idi, usuario o pwd incorrectos.
            r_snack_error.current.setVal(i18n.t('msg.check_usr_pwd'));
            r_snack_error.current.setOpen(true);
          }
          if (result[0].theValue === 'rv_user_access_failed') {
            r_snack_error.current.setVal(i18n.t('msg.permission_denied'));
            r_snack_error.current.setOpen(true);
          }
          if (result[0].theValue === 'rv_outdated_db') {
            r_snack_error.current.setVal(i18n.t('msg.must_update_iberical'));
            r_snack_error.current.setOpen(true);
          }
          if (result[0].theValue === 'rv_invalid_locale') {
            r_snack_error.current.setVal(i18n.t('msg.invalid_locale'));
            r_snack_error.current.setOpen(true);
          }
         // *************************************************************************************
        }
      }
    });
  }

  const loadRbl = () => {
    try {
      let record = {};
      return Promise.all([FxFetch.doJsonX('cp/login/get_rbl', record)]).then((result) => {
        if (result[0]) {
          storeTransInSession(result[0]);
        }
      });

    } catch (error) {
      FxLog.errorx('MxLoginCP.loadRbl', error);
    }
  }

  const loadAccess = () => {
    try {
      let record = {};
      return Promise.all([FxFetch.doJsonX('cp/login/get_access', record)]).then((result) => {
        if (result[0]) {
          storeAccessInSession(result[0]);
        }
      });

    } catch (error) {
      FxLog.errorx('MxLoginCP.loadAccess', error);
    }
  }

  return (
    <div style={{ width: "var(--ibs_control_width_md)", margin: "10% auto" }}>
      <div >
        <h2 style={{ margin: '10px 0 0 0', color: 'var(--ibs_color_cy_yellow)', fontFamily: 'EBGaramondRegular' }}>{`${i18n.t('field.client_portal')}`.toUpperCase()}</h2>
        <h3 style={{ margin: '0px', color: 'var(--ibs_color_cy_yellow)', fontFamily: 'Arial', fontSize: '14px' }}>{`${packageJson.version}`}</h3>
        <hr style={{ width: '200px', border: '1px solid var(--ibs_color_cy_blue)' }} />
        {isSystemUpdated && <h3 style={{ marginTop: '10px', color: 'var(--ibs_color_red)', fontFamily: 'Arial', fontSize: '12px' }}>{i18n.t('msg.device_updated').toUpperCase()}</h3>}
      </div>
      <br />

      <div id='div_MxLoginCP_controls' style={{
        display: 'flex',
        flexFlow: 'column wrap',
        justifyContent: 'center',
        gap: '10px',
      }}>
        <CxInput xref={rf_txIdi} label={i18n.t('field.idi')} width='var(--ibs_control_width_md)' lowerCase disabled={disabled} />
        <CxInput xref={rf_txUsername} label={i18n.t('field.email')} width='var(--ibs_control_width_md)' lowerCase disabled={disabled} />
        <CxInput xref={rf_txPassword} type="password" label={i18n.t('field.password')} width='var(--ibs_control_width_md)' lowerCase disabled={disabled} />
        <CxSwitch xref={rf_rem} label={i18n.t('field.remember')} width='var(--ibs_control_width_md)' disabled={disabled} />
      </div>

      <CxButton label={i18n.t('msg.login')} onClick={login} disabled={disabled} style={{ width: '200px', marginTop: '30px' }} />

      <CxSnackbar xref={r_snack_error} severity="error" autoHideDuration={2500} />
      <CxSnackbar xref={r_snack_loading} severity="info" value={i18n.t('msg.loading') + '...'} autoHideDuration={10000} />
    </div>
  );
}

export default MxLoginCP;
