import { RiFileHistoryLine } from "react-icons/ri"; 
import React from 'react';
import { useTranslation } from '../../hx/useTranslation';
import '../../../styles/main.css';
import CxToolbar from '../../cx/CxToolbar';
import CxIconButtonNew from '../../cx/CxIconButtonNew';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import GxLongTask from '../../gx/GxLongTask';
import GxPrint from '../../gx/GxPrint';
import { StoreLongTask } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';


const MxMarketingOptionTsk = (props) => {
  const [{ trans }] = useTranslation();

  const [storeLongTask, ] = useSessionStorage('StoreLongTask', StoreLongTask);
  const listed = storeLongTask._record.arListedId.split('\n').length-1;
  let r_print = React.useRef(null);

  const doPrintX = async (option) => {
    let filter = {};
    filter['reportFormat'] = `PDF`;
    if (option === 'E') {
      filter['reportName'] = 'ibs_stats_mkt_email';
    }
    if (option === 'S') {
      filter['reportName'] = 'ibs_stats_mkt_sms';
    }
    let record = {};
    r_print.current.setScale(1.5);
    r_print.current.setUrl('clients/report/mkt');
    //----------------
    Promise.all([
      r_print.current.setFilter(filter),
      r_print.current.setRecord(record),
      //r_print.current.setInClient(inClient),
    ]).then((result) => {
      r_print.current.doPrint();
    });
  }

  return (
    <div>
      <CxToolbar 
        moduleName={`${trans('field.marketing')}: ${storeLongTask._record.op==='E' ? trans('field.send_by_x', 'field.email') : trans('field.send_by_x', 'field.sms')}`} 
        background={storeLongTask._record.op==='E' ? 'var(--ibs_color_red_light)' : 'var(--ibs_color_yellow_light)'}
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <CxIconButtonNew icon={<RiFileHistoryLine fontSize={24} />} classType="ibs_iconbutton_toolbar" onClick={() => doPrintX(storeLongTask._record.op)} tooltip={trans('rpt.mkt_email')} />
          </div>
        }
      />
      
      <GxLongTask caption={`${trans('field.send')} ${listed} ${storeLongTask._record.op==='E' ? trans('field.emails') : trans('field.sms')}`} />

      <GxPrint xref={r_print} />
    </div>
  );

}

export default MxMarketingOptionTsk;
