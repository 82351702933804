// Funciones para traducción.
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../fx/FxLog';
import * as FxFetch from '../fx/FxFetch';
import { useTranslation } from './useTranslation';

export const useAccess = (modName) => {
  let mod = modName;
  let ar = JSON.parse(sessionStorage.getItem('access') || null);
  const [{ trans }] = useTranslation();

  const _storeInSession = (ar) => {
    sessionStorage.setItem('access', JSON.stringify(ar));
  }

  const _getItem = (key) => {
    if (ar[key]) {
      return ar[key];
    } else {
      FxLog.errorx('FxTrans.get', 'NO HAY ACCESO PARA: ' + key + '\nSe intentará crear.');
      createAccess(key);
      return key;
    }
  }

  const createAccess = (key) => {
    Promise.all([FxFetch.doJsonX("sett/sec/groups/set_new_access", {"txSecAccess":key})]).then((result) => {
      if (result[0]) {
        if (result[0].theStatus === 'error') {
          //FxLog.errorx('FxTrans.get', 'NO se ha podido crear el acceso. txSecAccess:' + key);
        }
        if (result[0].theStatus === 'OK') {
          //FxLog.errorx('FxTrans.get', 'SÍ se ha podido crear el acceso. txSecAccess:' + key);
        }
      }
    });
  }

  const _isMatchingLang = () => {
    if (ar[trans('field.clients')]) {
      return true;
    } else {
      return false;
    }
  }

  const _isFullAccess = () => {
    if (_getItem(mod) === 'W') {
      return true;
    } else {
      return false;
    }
  }

  return [
    {
      storeAccessInSession: _storeInSession,
      access: _getItem,
      isFullAccess: _isFullAccess,
      isMatchingLang: _isMatchingLang,
    },
  ];
};
