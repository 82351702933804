import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useNav } from '../../hx/useNav';
import { useTranslation } from '../../hx/useTranslation';
import '../../../styles/main.css';
import CxDate from '../../cx/CxDate';
import CxChooser from '../../cx/CxChooser';
import CxSearcher from '../../cx/CxSearcher';
import CxInput from '../../cx/CxInput';
import CxNumber from '../../cx/CxNumber';
import CxIconButton from '../../cx/CxIconButton';
import CxToolbar from '../../cx/CxToolbar';
import CxState from '../../cx/CxState';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import { StoreMassBilling } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import CxMultiCheck from '../../cx/CxMultiCheck';
import * as FxDat from '../../fx/FxDat';
import CxSnackbar from '../../cx/CxSnackbar';
import GxIconButtonLastClient from '../../gx/GxIconButtonLastClient';


const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'flex-start',
    gap: 'var(--ibs_container_gap)',
    margin: 'var(--ibs_container_margin)',
  },
}));

const MxMassBillingFilter = (props) => {
  const [{ trans }] = useTranslation();
  const classes = useStyles(props);
  const [{ navTo }] = useNav();
  const rf_dtFrom = React.useRef(null);
  const rf_dtTo = React.useRef(null);
  const rf_txClient = React.useRef(null);
  const rf_txEmployee = React.useRef(null);
  const rf_txBranch = React.useRef(null);
  const rf_txInvoice = React.useRef(null);
  const rf_nuTotalFinalFrom = React.useRef(null);
  const rf_nuTotalFinalTo = React.useRef(null);
  const rf_nuTotalTaxFrom = React.useRef(null);
  const rf_nuTotalTaxTo = React.useRef(null);
  const rf_boPaid = React.useRef(null);
  const rf_txTypePayments = React.useRef(null);
  const rf_txTypeSeries = React.useRef(null);
  const rf_txTypeCash = React.useRef(null);

  const [storeMassBilling, setStoreMassBilling] = useSessionStorage('StoreMassBilling', StoreMassBilling);

  let r_snack_error = React.useRef(null);

  React.useEffect(() => {
    formLoad();
    // eslint-disable-next-line
  }, []);

  const formLoad = async () => {
    let theDateFrom = FxDat.addDays(FxDat.today(), -30);
    let theDateTo = FxDat.today();

    rf_dtFrom.current.setVal(storeMassBilling.dtInvoiceFrom || theDateFrom);
    rf_dtTo.current.setVal(storeMassBilling.dtInvoiceTo || theDateTo);
    rf_txClient.current.setKey(storeMassBilling.inClient);
    rf_txClient.current.setVal(storeMassBilling.txClient);
    rf_txEmployee.current.setKey(storeMassBilling.inEmployee);
    rf_txEmployee.current.setVal(storeMassBilling.txEmployee);
    rf_txBranch.current.setKey(storeMassBilling.inBranch);
    rf_txBranch.current.setVal(storeMassBilling.txBranch);
    rf_txInvoice.current.setVal(storeMassBilling.txInvoice);
    rf_nuTotalFinalFrom.current.setVal(storeMassBilling.nuTotalFinalFrom);
    rf_nuTotalFinalTo.current.setVal(storeMassBilling.nuTotalFinalTo);
    rf_nuTotalTaxFrom.current.setVal(storeMassBilling.nuTotalTaxFrom);
    rf_nuTotalTaxTo.current.setVal(storeMassBilling.nuTotalTaxTo);
    rf_boPaid.current.setVal(storeMassBilling.boPaid);
    rf_txTypePayments.current.setVal(storeMassBilling.arPayments);
    rf_txTypeSeries.current.setVal(storeMassBilling.arSeries);
    rf_txTypeCash.current.setVal(storeMassBilling.arCash);
  }

  const goNext = async () => {
    storeMassBilling.dtInvoiceFrom = rf_dtFrom.current.getVal();
    storeMassBilling.dtInvoiceTo = rf_dtTo.current.getVal();
    storeMassBilling.inClient = rf_txClient.current.getKey();
    storeMassBilling.txClient = rf_txClient.current.getVal();
    storeMassBilling.inEmployee = rf_txEmployee.current.getKey();
    storeMassBilling.txEmployee = rf_txEmployee.current.getVal();
    storeMassBilling.inBranch = rf_txBranch.current.getKey();
    storeMassBilling.txBranch = rf_txBranch.current.getVal();
    storeMassBilling.txInvoice = rf_txInvoice.current.getVal();
    storeMassBilling.nuTotalFinalFrom = rf_nuTotalFinalFrom.current.isEmpty() ? '' : rf_nuTotalFinalFrom.current.getVal();
    storeMassBilling.nuTotalFinalTo = rf_nuTotalFinalTo.current.isEmpty() ? '' : rf_nuTotalFinalTo.current.getVal();
    storeMassBilling.nuTotalTaxFrom = rf_nuTotalTaxFrom.current.isEmpty() ? '' : rf_nuTotalTaxFrom.current.getVal();
    storeMassBilling.nuTotalTaxTo = rf_nuTotalTaxTo.current.isEmpty() ? '' : rf_nuTotalTaxTo.current.getVal();
    storeMassBilling.boPaid = rf_boPaid.current.getVal();
    storeMassBilling.arPayments = rf_txTypePayments.current.getVal();
    storeMassBilling.arSeries = rf_txTypeSeries.current.getVal();
    storeMassBilling.arCash = rf_txTypeCash.current.getVal();
    setStoreMassBilling(storeMassBilling);

    navTo("mass-billing-list");
  }

  return (
    <div>
      <CxToolbar
        moduleName={`${trans('field.mass_billing')}: ${trans('field.filter')}`}
        addToolbarButtons={
          <>
            <GxIconButtonLastClient theInput={rf_txClient} />
            <CxIconButton type="chevron_right" classType="ibs_iconbutton_toolbar" onClick={goNext} tooltip={trans('field.next')} />
          </>
        }
      />

      <div className={classes.container}>
        <CxDate xref={rf_dtFrom} label={trans('field.from')} />
        <CxDate xref={rf_dtTo} label={trans('field.to')} />

        <CxSearcher xref={rf_txClient} label={trans('field.client')} dbfield={`n_client.txClient`} dbfieldkey={`n_cash_item.inClient`}
          id="n_client"
          url="clients/searcher"
          col_id="inClient"
          col_tx="txClient"
          colorRefs={['table_cell_red|' + trans('field.debtor')]}
          tooltip={`${trans('field.client')}`}
          columns={[
            { id: "0", Header: "ID", accessor: "inClient", show: false },
            { id: "1", Header: trans('field.client'), accessor: row => rf_txClient.current.getTable().highlightText(row["txClient"]), width: '100%' },
            { id: "R", Header: "balance", accessor: row => Boolean((row["theBalance"]) < 0 ? true : false), show: false },
          ]}
          readOnly={false}
        />

        <CxChooser xref={rf_txEmployee} label={trans('field.employee')}
          id="n_employee"
          table="n_employee"
          col_id="inEmployee"
          col_tx="txEmployee"
        />

        <CxChooser xref={rf_txBranch}
          label={trans('field.branch')}
          id="n_branch"
          table="n_branch"
          col_id="inBranch"
          col_tx="txBranch"
          extra_filter_1={"boActive='1'"}
        />

        <CxInput xref={rf_txInvoice} label={trans('field.invoice')} />

        <CxNumber xref={rf_nuTotalFinalFrom} label={`${trans('field.total')}-${trans('field.from')}`} decimals={2} decimalsfixed="true" />
        <CxNumber xref={rf_nuTotalFinalTo} label={`${trans('field.total')}-${trans('field.to')}`} decimals={2} decimalsfixed="true" />

        <CxNumber xref={rf_nuTotalTaxFrom} label={`${trans('field.tax')}-${trans('field.from')}`} decimals={2} decimalsfixed="true" />
        <CxNumber xref={rf_nuTotalTaxTo} label={`${trans('field.tax')}-${trans('field.to')}`} decimals={2} decimalsfixed="true" />

        <CxState mode='checkbox' xref={rf_boPaid} label={trans('field.paid')} />

        <CxMultiCheck mode='checkbox' xref={rf_txTypePayments} label={trans('field.payments')} minRows={4} itemWidth="150px"
          tableName="t_payment" idField="itPayment" txField="txTypePayment"
        />

        <CxMultiCheck mode='checkbox' xref={rf_txTypeCash} label={trans('field.cash_reg')} minRows={4} itemWidth="150px"
          tableName="t_cash" idField="itCash" txField="txTypeCash"
        />

        <CxMultiCheck mode='checkbox' xref={rf_txTypeSeries} label={trans('field.series')} minRows={4} itemWidth="150px"
          tableName="t_series" idField="itSeries" txField="txTypeSeries"
        />

      </div>

      <CxSnackbar xref={r_snack_error} severity="error" />

    </div>
  );

}

export default MxMassBillingFilter;
