import React, { useImperativeHandle } from "react";
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../fx/FxLog';
import * as FxStr from '../fx/FxStr';

const CxStatic = props => {
  const [myLabel, setMyLabel] = React.useState(props.label);
  const [fontColor, setFontColor] = React.useState(props.fontcolor || 'var(--ibs_color_black)');
  const [fontSize, ] = React.useState(props.fontsize || '14px');
  const [textAlign, setTextAlign] = React.useState(props.textAlign || 'left');
  
  React.useEffect(() => {

    // eslint-disable-next-line
  }, []);

  useImperativeHandle(props.xref, () => {
    return {
      getType, setLabel, getLabel, clear, setChanged, setFontColor, setTextAlign
    };
  });
  const getType = () => { return 'CxStatic' };
  const getLabel = () => { return myLabel||'' };
  const clear = () => { setMyLabel(''); }
  const setChanged = () => {} //dummy para GxForm.resetChangedHelper()
  const setLabel = (newValue, newColor) => { 
    setMyLabel(newValue); 
    setFontColor(newColor || 'var(--ibs_color_black)');
  }

  return (
    <div style={{
      width: props.width || '100%',
      margin: props.margin || '0px 0 0 0px',
      padding: FxStr.choose(props.border||'default', 'none|red|default', '0px 2px 0 2px', '0px 4px 0 4px', '0px 4px 0 4px'),
      border: FxStr.choose(props.border||'default', 'none|red|default', '0px', '1px solid var(--ibs_color_red)', '1px solid var(--ibs_color_gray_light)'),
      fontSize: fontSize,
      color: fontColor,
      background: 'var(--ibs_color_white)',
      borderRadius: '4px',
      height: props.height,
      textAlign: textAlign
    }}>
      <div style={{ 
        display: 'flex', 
        flexDirection: 'row', 
        lineHeight: fontSize, 
        float: props.alignRight ? 'right' : 'left', 
        whiteSpace: 'pre-wrap' /* preserva saltos de carro */ 
      }}>
        {myLabel || ''}
        {props.children}
      </div>
    </div>
  );

};

export default CxStatic;
