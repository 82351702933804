import React from 'react';
import { useNav } from '../../hx/useNav';
import { useTranslation } from '../../hx/useTranslation';
import * as FxLog from '../../fx/FxLog';
import * as FxTable from '../../fx/FxTable';
import * as FxFetch from '../../fx/FxFetch';
import GxList from '../../gx/GxList';
import CxIconButton from '../../cx/CxIconButton';
import CxDialog from '../../cx/CxDialog';
import CxNumber from '../../cx/CxNumber';
import CxSearcher from '../../cx/CxSearcher';
import CxSnackbar from '../../cx/CxSnackbar';
import { useWindowSize } from '../../hx/useWindowSize';
import { clearStore, StorePs } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';


const MxPsList = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navTo }] = useNav();

  const r_list = React.useRef(null);

  const rf_nuPerc = React.useRef(null);
  const rf_txTypePsGroup = React.useRef(null);
  const rf_txTypePsSubgroup = React.useRef(null);

  const r_snack_required = React.useRef(null);
  const r_snack_op_successful = React.useRef(null);

  const size = useWindowSize();

  const [, updateState] = React.useState();
  const forceUIUpdate = React.useCallback(() => updateState({}), []);

  const [storePs, setStorePs] = useSessionStorage('StorePs', StorePs);

  const [dlgPriceChange, setDlgPriceChange] = React.useState(false);

  React.useEffect(() => {
    clearStore('StorePs', storePs);
    // eslint-disable-next-line
  }, []);

  const hasColumnPsAlias = () => { return Boolean(localStorage.getItem('MxPsList.addcol_txPsAlias')==='1')};
  const hasColumnPsCode = () => { return Boolean(localStorage.getItem('MxPsList.addcol_txPsCode')==='1')};
  const hasColumnReference = () => { return Boolean(localStorage.getItem('MxPsList.addcol_txReference')==='1')};

  const openForm = async (op, row) => {
    if (row === null) {
      storePs.inPs = null;
    } else {
      storePs.inPs = row.original.inPs;
    }
    setStorePs(storePs);
    navTo("ps-form");
  }

  const openPrintLabelFilter = async (op, row) => {
    navTo("ps-list-label-filter");
  }

  const doPriceChange = (val) => {
    try {
      // chequea requeridos
      if (rf_nuPerc.current.isEmpty()) {
        r_snack_required.current.setVal(trans('field.required') + ": " + rf_nuPerc.current.getLabel());
        r_snack_required.current.setOpen(true);
        return;
      }

      let record = {};
      record['nuPerc'] = rf_nuPerc.current.getVal();
      record['itPsGroup'] = rf_txTypePsGroup.current.getKey();
      record['itPsSubgroup'] = rf_txTypePsSubgroup.current.getKey();
      Promise.all([FxFetch.doJsonX('pss/change_prices', record)]).then((result) => {
        if (result[0]) {
          if (result[0].theValue === 'OK') {
            r_snack_op_successful.current.setOpen(true);
            r_list.current.redisplay();
          }
        }
      });

    } catch (error) {
      FxLog.errorx("MxPsList.doPriceChange", error);
    }
  }

  const choose = (val) => {
    let rv = null;
    switch (val) {
      case '0': rv = trans('field.product'); break;
      case '1': rv = trans('field.service'); break;
      default: break;
    }
    return rv;
  }

  const groupCleared = () => {
    rf_txTypePsSubgroup.current.clear();
    rf_txTypePsSubgroup.current.setFilter(`undefined='${undefined}'`);
  }
  const groupSelected = (row) => {
    rf_txTypePsSubgroup.current.clear();
  }
  const subgroupOpen = () => {
    let itPsGroup = rf_txTypePsGroup.current.getKey();
    rf_txTypePsSubgroup.current.setFilter(`t_ps_subgroup.itPsGroup='${itPsGroup}'`);
    return true; // para que abra la ventana de búsqueda.
  }

  const getTableColumns = () => {
    let cols = [];
    let id = -1;
    cols.push({ id: id++, Header: "ID", accessor: "inPs", show: false });
    if (size.isLarge) { // PANTALLA GRANDE
      cols.push({ id: id++, Header: trans('field.ps'), accessor: row => r_list.current.getTable().highlightText(row["txPs"]), width: '25%' });
      cols.push({ id: id++, Header: `${trans('field.alias')}-${trans('field.invoicing')}`, accessor: row => r_list.current.getTable().highlightText(row["txPsAlias"]), width: '25%', show: hasColumnPsAlias() });
      cols.push({ id: id++, Header: trans('field.code'), accessor: row => r_list.current.getTable().highlightText(row["txPsCode"]), width: '15%', show: hasColumnPsCode() });
      cols.push({ id: id++, Header: trans('field.reference'), accessor: row => r_list.current.getTable().highlightText(row["txReference"]), width: '15%', show: hasColumnReference() });
      cols.push({ id: id++, Header: trans('field.nature'), accessor: row => (choose(row["nuNature"])), width: '10%' });
      cols.push({ id: id++, Header: trans('field.group'), accessor: row => r_list.current.getTable().highlightText(row["txTypePsGroup"]), width: '20%' });
      cols.push({ id: id++, Header: trans('field.subgroup'), accessor: row => r_list.current.getTable().highlightText(row["txTypePsSubgroup"]), width: '20%' });
      cols.push({ id: id++, Header: trans('field.total'), accessor: row => (FxTable.cellTo2r00(row["theTotalPrice"])), width: '10%', align: 'right' });
    } else { // PANTALLA PEQUEÑA
      cols.push({ id: id++, Header: trans('field.ps'), accessor: row => r_list.current.getTable().highlightText(row["txPs"]), width: '50%' });
      cols.push({ id: id++, Header: `${trans('field.alias')}-${trans('field.invoicing')}`, accessor: row => r_list.current.getTable().highlightText(row["txPsAlias"]), width: '25%', show: hasColumnPsAlias() });
      cols.push({ id: id++, Header: trans('field.code'), accessor: row => r_list.current.getTable().highlightText(row["txPsCode"]), width: '25%', show: hasColumnPsCode() });
      cols.push({ id: id++, Header: trans('field.reference'), accessor: row => r_list.current.getTable().highlightText(row["txReference"]), width: '25%', show: hasColumnReference() });
      cols.push({ id: id++, Header: trans('field.total'), accessor: row => (FxTable.cellTo2r00(row["theTotalPrice"])), width: '25%', align: 'right' });
    }
    cols.push({ id: 'W', Header: "_composite", accessor: row => Boolean(row["boComposite"] === '1'), show: false });
    cols.push({ id: 'G', Header: "_frequency", accessor: row => Boolean(row["nuFrequency"] > 0), show: false });
    return cols;
  }

  const getTableOptionalCols = () => {
    let cols = [];
    cols.push({id: 'txPsAlias', name: `${trans('field.alias')}-${trans('field.invoicing')}`, checked: hasColumnPsAlias() ? '1' : '0'}); 
    cols.push({id: 'txPsCode', name: trans('field.code'), checked: hasColumnPsCode() ? '1' : '0'}); 
    cols.push({id: 'txReference', name: trans('field.reference'), checked: hasColumnReference() ? '1' : '0'}); 
    return cols;
  }

  const optionsSelected = (options) => {
    localStorage.setItem('MxPsList.addcol_txPsAlias', options.includes('txPsAlias') ? '1' : '0');
    localStorage.setItem('MxPsList.addcol_txPsCode', options.includes('txPsCode') ? '1' : '0');
    localStorage.setItem('MxPsList.addcol_txReference', options.includes('txReference') ? '1' : '0');
    r_list.current.getTable().setTooltipText(getTooltip());
    r_list.current.getTable().clearSearch();
    forceUIUpdate();
  }

  const getTooltip = () => {
    let tip = `${trans('field.ps')} / ${trans('field.group')} / ${trans('field.subgroup')}`;
    if (hasColumnPsAlias()) tip = tip + `${' / ' + trans('field.alias')}`;
    if (hasColumnPsCode()) tip = tip + `${' / ' + trans('field.code')}`;
    if (hasColumnReference()) tip = tip + `${' / ' + trans('field.reference')}`;
    
    return tip;
  }

  return (
    <div>
      <GxList
        moduleName={`${trans('field.pss')}`}
        xref={r_list}
        id="MxPsList"
        //title={trans('field.pss')}
        tooltip={getTooltip()}
        fullscreen
        showtip
        //help="pss"
        url="pss"
        onOpenForm={openForm}
        colorRefs={ [ 'table_cell_brown|' + trans('field.composite'), 'table_cell_green|' + trans('field.frequency') ] }
        columns={getTableColumns()}
        optionalColumns={getTableOptionalCols()}
        optionalSorting={[
          { id: 'txPs', type: trans('field.ps') },
          { id: 'txPsAlias', type: `${trans('field.alias')}-${trans('field.invoicing')}` },
          { id: 'txTypePsGroup', type: trans('field.group') },
          { id: 'txTypePsSubgroup', type: trans('field.subgroup') },
          { id: 'nuPrice', type: trans('field.price') },
        ]}
        onOptions={optionsSelected}
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <CxIconButton type="price_change" classType="ibs_iconbutton_toolbar" onClick={() => setDlgPriceChange(true)} tooltip={trans('field.price_change')} />
            <CxIconButton type="price_label" classType="ibs_iconbutton_toolbar" onClick={openPrintLabelFilter} tooltip={trans('field.price_labels')} />
          </div>
        }
        speechExternal
      />

      <CxDialog title={trans('field.price_change')} open={dlgPriceChange} onOk={doPriceChange} onCancel={() => setDlgPriceChange(false)} height='350px'>

        <CxNumber xref={rf_nuPerc}
          label={`${trans('field.perc')}%`}
          decimals={2}
          decimalsfixed="true"
          width='var(--ibs_control_width_md)'
          required
        />

        <CxSearcher xref={rf_txTypePsGroup}
          label={trans('field.group')}
          onClear={groupCleared}
          onSelection={groupSelected}
          url="sett/ps_group"
          col_id="itPsGroup"
          col_tx="txTypePsGroup"
          columns={[
            { id: "0", Header: "ID", accessor: "itPsGroup", show: false },
            { id: "1", Header: trans('field.ps'), accessor: row => rf_txTypePsGroup.current.getTable().highlightText(row["txTypePsGroup"]), width: '100%' },
          ]}
          width='var(--ibs_control_width_md)'
        />

        <CxSearcher xref={rf_txTypePsSubgroup}
          label={trans('field.subgroup')}
          canOpenDialog={subgroupOpen}
          url="sett/ps_subgroup"
          col_id="itPsSubgroup"
          col_tx="txTypePsSubgroup"
          columns={[
            { id: "0", Header: "ID", accessor: "itPsSubgroup", show: false },
            { id: "1", Header: trans('field.ps'), accessor: row => rf_txTypePsSubgroup.current.getTable().highlightText(row["txTypePsSubgroup"]), width: '100%' },
          ]}
          width='var(--ibs_control_width_md)'
        />

      </CxDialog>

      <CxSnackbar xref={r_snack_required} severity="error" />
      <CxSnackbar xref={r_snack_op_successful} severity="success" value={trans('msg.operation_successful')} />

    </div>
  );

}

export default MxPsList;
