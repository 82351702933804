// Funciones para traducción.
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../fx/FxLog';
import * as FxFetch from '../fx/FxFetch';
import Resizer from "react-image-file-resizer";

export const useImageIO = (modName) => {
  const base64Prefix = 'data:image/png;base64,';

  const _load = (url, inXXX) => {
    let record = {};
    if (inXXX) {
      if (inXXX !== null) {
        record['inXXX'] = inXXX;
      }
      return Promise.all([FxFetch.doJsonX(`${url}/get_image`, record)]).then((result) => {
        return `${base64Prefix}${result[0].obFile}`;
      });
    }
  };

  const _save = (url, inXXX, strBase64) => {
    let record = {};
    record['inXXX'] = inXXX;
    record['base64'] = strBase64 ? strBase64.replace(base64Prefix, '') : null;
    return Promise.all([FxFetch.doJsonX(`${url}/set_image`, record)]).then((result) => {
      return result[0];
    });
  };

  const _delete = (url, inXXX) => {
    let record = {};
    record['inXXX'] = inXXX;
    return Promise.all([FxFetch.doJsonX(`${url}/delete_image`, record)]).then((result) => {
      return result[0];
    });
  };

  const _resize = (file) => {
    return new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        800, // máximo ancho que admitimos, para no petar la db
        600, // máximo alto que admitimos, para no petar la db
        "PNG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    })
  }

  const _createBlank = (width, height) => {
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    
    const ctx = canvas.getContext('2d');
    ctx.fillStyle = 'rgba(0, 0, 0, 0)';
    ctx.fillRect(0, 0, width, height);
  
    const img = new Image(width, height);
    img.src = canvas.toDataURL();
  
    return img.src;
  }

  return [
    {
      imageLoad: _load,
      imageSave: _save,
      imageDelete: _delete,
      imageResize: _resize,
      imageCreateBlank: _createBlank,
    },
  ];
};
