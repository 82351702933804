import { BiSend } from "react-icons/bi";
import { BiTrash } from "react-icons/bi";
import React from 'react';
import GxList from '../../gx/GxList';
import CxIconButtonNew from '../../cx/CxIconButtonNew';
import CxSnackbar from '../../cx/CxSnackbar';
import { useTranslation } from '../../hx/useTranslation';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import * as FxFetch from '../../fx/FxFetch';
import { useNav } from '../../hx/useNav';
import { useWindowSize } from '../../hx/useWindowSize';
import * as FxTable from '../../fx/FxTable';
import * as FxInet from '../../fx/FxInet';
import { clearStore, StoreComm } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import { isApp } from '../../gx/GxStore';
import GxIconButtonLastClient from '../../gx/GxIconButtonLastClient';


const MxCommList = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navTo }] = useNav();
  const r_list = React.useRef(null);
  const timer = React.useRef(null);
  const size = useWindowSize();

  const [, updateState] = React.useState();
  const forceUIUpdate = React.useCallback(() => updateState({}), []);

  let r_snack_error = React.useRef(null);
  let r_snack_success = React.useRef(null);

  const [storeComm, setStoreComm] = useSessionStorage('StoreComm', StoreComm);
  const [lockDelete, setLockDelete] = React.useState(true);
  const [lockSend, setLockSend] = React.useState(true);

  const hasColumnMobile = () => { return Boolean(localStorage.getItem('MxCommList.addcol_txMobile') === '1') };
  const hasColumnEmail = () => { return Boolean(localStorage.getItem('MxCommList.addcol_txEmail') === '1') };
  // SÓLO EN VETT/SPAW
  const hasColumnAnimal = () => { return Boolean(localStorage.getItem('MxCommList.addcol_txAnimal') === '1') }; // VETT, SPAW
  const hasColumnAnimalNumber = () => { return Boolean(localStorage.getItem('MxCommList.addcol_txNumber') === '1') }; // VETT, SPAW
  const hasColumnAnimalChip = () => { return Boolean(localStorage.getItem('MxCommList.addcol_txChip') === '1') }; // VETT, SPAW


  React.useEffect(() => {
    clearStore('StoreComm', storeComm);

    timer.current = setInterval(() => {
      // refresca listado para mostrar los procesados en verde.
      if (FxInet.isOnline()) {
        r_list.current.refresh();
      }
    }, 10000); //milisegundos

    return () => {
      clearInterval(timer.current);
    }
    // eslint-disable-next-line
  }, []);

  const openForm = async (op, row) => {
    if (row === null) {
      storeComm.inComm = null;
    } else {
      storeComm.inComm = row.original.inComm;
    }
    setStoreComm(storeComm);
    navTo("comm-form");
  }

  const doDelete = (row) => {
    let record = {};
    record['idValue'] = row.inComm;
    Promise.all([FxFetch.doJsonX('comms/d', record)]).then((result) => {
      if (result[0]) {
        if (result[0].theValue === 'OK') {
          r_list.current.refresh();
        }
      }
    });
  }

  const doDismissThisOne = (inComm) => {
    // requiere que el comm cambie status.
    let record = {};
    record['idValue'] = inComm;
    Promise.all([FxFetch.doJsonX('comms/popup/confirmation', record)]).then((result) => {
      if (result[0]) {
      }
    });
  }

  const getWhatsappNumber = (txMobile) => {
    let record = {};
    record['txMobile'] = txMobile;
    return Promise.all([FxFetch.doJsonX('comms/get_whatsapp_number', record)]).then((result) => {
      if (result[0]) {
        return result[0].theValue;
      }
    });
  }

  const doSend = (row) => {
    let inComm = row["inComm"];
    let inClient = row["inClient"];
    let txClient = row["txClient"];
    let oriEmail = row["oriEmail"];
    let oriMobile = row["oriMobile"];
    let txContent = row["txContent"];
    if (row["boPopup"] === '1') {
      // ventana + wa
      if (oriMobile) {
        doDismissThisOne(inComm);
        Promise.all([getWhatsappNumber(oriMobile)]).then((result) => {
          oriMobile = result[0];
          let url = `https://web.whatsapp.com/send?phone=${oriMobile}&text=${txContent}`;
          const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
          if (newWindow) newWindow.opener = null;
        });
      } else {
        r_snack_error.current.setVal(trans('msg.no_x_mobile_in_file', 'field.client'));
        r_snack_error.current.setOpen(true);
      }

    } else {
      let record = {};
      record['inComm'] = inComm;
      record['inClient'] = inClient;
      record['txClient'] = txClient;
      record['txEmail'] = oriEmail;
      record['txMobile'] = oriMobile;
      record['txContent'] = txContent;
      // es sms
      if (oriMobile) {
        Promise.all([FxFetch.doJsonX('comms/send_now/sms', record)]).then((result) => {
          if (result[0]) {
            if (result[0].theStatus === 'error') {
              r_snack_error.current.setVal(trans('msg.operation_failed'));
              r_snack_error.current.setOpen(true);
            }
            if (result[0].theStatus === 'OK') {
              r_snack_success.current.setOpen(true);
            }
          }
        });
      }
      // es email
      if (oriEmail) {
        Promise.all([FxFetch.doJsonX('comms/send_now/email', record)]).then((result) => {
          if (result[0]) {
            if (result[0].theStatus === 'error') {
              r_snack_error.current.setVal(trans('msg.operation_failed'));
              r_snack_error.current.setOpen(true);
            }
            if (result[0].theStatus === 'OK') {
              r_snack_success.current.setOpen(true);
            }
          }
        });
      }
    }
    r_list.current.refresh();
  }

  const getTableColumns = () => {
    let cols = [];
    let id = -1;
    cols.push({ id: id++, Header: "ID", accessor: "inComm", show: false });
    cols.push({ id: "inClient", Header: "inClient", accessor: "inClient", show: false });
    if (size.isLarge) { // PANTALLA GRANDE
      cols.push({ id: id++, Header: trans('field.date'), accessor: row => r_list.current.getTable().highlightDate(row["dtFrom"]), width: '10%' });
      cols.push({ id: id++, Header: trans('field.time'), accessor: row => FxTable.cellToLocalTime(row["dtFrom"]), width: '5%' });
      cols.push({ id: id++, Header: trans('field.type'), accessor: "txTypeComm", width: '15%' });
      cols.push({ id: id++, Header: trans('field.client'), accessor: row => r_list.current.getTable().highlightText(row["txClient"]), width: '30%' });
      if ((isApp('vett') || isApp('spaw'))) {
        cols.push({ id: id++, Header: trans('field.patient'), accessor: row => r_list.current.getTable().highlightText(row["animal_txAnimal"]), width: '15%', show: hasColumnAnimal() });
        cols.push({ id: "txNumber", Header: trans('field.number'), accessor: row => r_list.current.getTable().highlightText(row["animal_txNumber"]), width: '15%', show: hasColumnAnimalNumber() });
        cols.push({ id: "txChip", Header: trans('field.chip'), accessor: row => r_list.current.getTable().highlightText(row["animal_txChip"]), width: '15%', show: hasColumnAnimalChip() });
      } else {
      }
      cols.push({ id: id++, Header: trans('field.message'), accessor: row => r_list.current.getTable().highlightText(row["txContent"]), width: '40%' });
      cols.push({ id: "txEmail", Header: trans('field.email'), accessor: row => r_list.current.getTable().highlightText(row["oriEmail"]), width: '15%', show: hasColumnEmail() });
      cols.push({ id: "txMobile", Header: trans('field.mobile'), accessor: row => r_list.current.getTable().highlightText(row["oriMobile"]), width: '15%', show: hasColumnMobile() });
      cols.push({
        id: "BTN_DEL", iconID: lockDelete ? "lock" : "unlock", iconTooltip: trans('field.column_lock_unlock'), onIconClick: () => setLockDelete(!lockDelete),
        accessor: row => { return <CxIconButtonNew icon={<BiTrash fontSize={24} />} classType="ibs_iconbutton_toolbar" data={row} onClick={doDelete} disabled={lockDelete} tooltip={trans('field.delete')} /> },
        align: 'center', width: '33px'
      });

    } else { // PANTALLA PEQUEÑA
      cols.push({ id: id++, Header: trans('field.date'), accessor: row => (r_list.current.getTable().highlightDate(row["dtFrom"])), width: '30%' });
      cols.push({ id: id++, Header: trans('field.client'), accessor: row => r_list.current.getTable().highlightText(row["txClient"]), width: '35%' });
      cols.push({ id: id++, Header: trans('field.message'), accessor: row => r_list.current.getTable().highlightText(row["txContent"]), width: '35%' });
    }
    cols.push({ id: 'B', Header: "popup", accessor: row => Boolean(row["boPopup"] === '1'), show: false });
    cols.push({ id: 'P', Header: "email", accessor: row => Boolean(row["txEmail"]), show: false });
    cols.push({ id: 'Y', Header: "sms", accessor: row => Boolean(row["txSms"]), show: false });
    cols.push({ id: 'A', Header: "disabled", accessor: row => Boolean(row["boPopup"] === '0' && !row["txEmail"] && !row["txSms"]), show: false });
    cols.push({ id: 'G', Header: "informed", accessor: row => Boolean(row["dtInformed"]), show: false });

    cols.push({
      id: "BTN_SND", iconID: lockSend ? "lock" : "unlock", iconTooltip: trans('field.column_lock_unlock'), onIconClick: () => setLockSend(!lockSend),
      accessor: row => { return !Boolean(row["dtInformed"]) && <CxIconButtonNew icon={<BiSend fontSize={24} />} classType="ibs_iconbutton_toolbar" data={row} onClick={doSend} disabled={lockSend} tooltip={trans('field.send')} /> },
      align: 'center', width: '33px'
    });

    return cols;
  }

  const getTableOptionalCols = () => {
    let cols = [];
    cols.push({ id: 'txEmail', name: trans('field.email'), checked: hasColumnEmail() ? '1' : '0' });
    cols.push({ id: 'txMobile', name: trans('field.mobile'), checked: hasColumnMobile() ? '1' : '0' });
    if ((isApp('vett') || isApp('spaw'))) {
      cols.push({ id: 'txAnimal', name: trans('field.patient') + '-' + trans('field.patient'), checked: hasColumnAnimal() ? '1' : '0' });
      cols.push({ id: 'txNumber', name: trans('field.patient') + '-' + trans('field.number'), checked: hasColumnAnimalNumber() ? '1' : '0' });
      cols.push({ id: 'txChip', name: trans('field.patient') + '-' + trans('field.chip'), checked: hasColumnAnimalChip() ? '1' : '0' });
    } else {
    }
    return cols;
  }

  const optionsSelected = (options) => {
    localStorage.setItem('MxCommList.addcol_txEmail', options.includes('txEmail') ? '1' : '0');
    localStorage.setItem('MxCommList.addcol_txMobile', options.includes('txMobile') ? '1' : '0');
    localStorage.setItem('MxCommList.addcol_txAnimal', options.includes('txAnimal') ? '1' : '0');
    localStorage.setItem('MxCommList.addcol_txNumber', options.includes('txNumber') ? '1' : '0');
    localStorage.setItem('MxCommList.addcol_txChip', options.includes('txChip') ? '1' : '0');
    r_list.current.getTable().setTooltipText(getTooltip());
    r_list.current.getTable().clearSearch();
    forceUIUpdate();
  }

  const getTooltip = () => {
    let tip = `${trans('field.date')} / ${trans('field.client')} / ${trans('field.message')}`;
    if ((isApp('vett') || isApp('spaw'))) {
      if (hasColumnAnimal()) tip = tip + `${' / ' + trans('field.patient')}`;
      if (hasColumnAnimalNumber()) tip = tip + `${' / ' + trans('field.number')}`;
      if (hasColumnAnimalChip()) tip = tip + `${' / ' + trans('field.chip')}`;
    } else {
    }
    if (hasColumnEmail()) tip = tip + `${' / ' + trans('field.email')}`;
    if (hasColumnMobile()) tip = tip + `${' / ' + trans('field.mobile')}`;

    return tip;
  }

  return (
    <div>
      <GxList
        moduleName={trans('field.comms')}
        xref={r_list}
        id="MxCommList"
        tooltip={getTooltip()}
        fullscreen
        showtip
        //help="comms"
        url="comms"
        onOpenForm={openForm}
        colorRefs={[
          'table_cell_green|' + trans('field.informed'),
          'table_cell_blue|' + trans('field.popup_window'), 
          'table_cell_pink|' + trans('field.email'), 
          'table_cell_yellow|' + trans('field.sms'), 
          'table_cell_gray|' + trans('field.no_send_method'),
        ]}
        columns={getTableColumns()}
        onOptions={optionsSelected}
        optionalColumns={getTableOptionalCols()}
        optionalSorting={[
          { id: 'txClient asc', type: `${trans('field.client')} (${trans('field.order_asc')})` },
          { id: 'dtFrom desc', type: `${trans('field.date')} (${trans('field.order_desc')})` },
        ]}
        optionalFromTo={true}
        optionalFromToButtonToday={true}
        optionalFromToButtonNext7Days={true}
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <GxIconButtonLastClient theList={r_list} />
          </div>
        }
        speechExternal
      />

      <CxSnackbar xref={r_snack_error} severity="error" />
      <CxSnackbar xref={r_snack_success} severity="success" value={trans('msg.operation_successful')} />

    </div>
  );

}

export default MxCommList;
