import React, { useRef } from 'react';
import GxForm from '../../gx/GxForm';
import GxDlgSelectClient from '../../gx/GxDlgSelectClient';
import { useTranslation } from '../../hx/useTranslation';
import { useNav } from '../../hx/useNav';
import CxHidden from '../../cx/CxHidden';
import CxInput from '../../cx/CxInput';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import * as FxFetch from '../../fx/FxFetch';
import '../../../styles/main.css';
import { StoreSettSecUser } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import CxSnackbar from '../../cx/CxSnackbar';
import CxChooser from '../../cx/CxChooser';
import CxCombo from '../../cx/CxCombo';
import { useAccess } from '../../hx/useAccess';


const MxSettSecUserForm = (props) => {
  const [{ trans }] = useTranslation();
  const [{ isFullAccess }] = useAccess(trans('field.security'));
  const [{ navBack }] = useNav();

  const [isMaster, setIsMaster] = React.useState(false);

  const [isEmployee, setIsEmployee] = React.useState(true);
  const [userLabel, setUserLabel] = React.useState(trans('field.user'));

  let r_form = React.useRef(null);
  let r_dlg_select_client = React.useRef(null);

  let rf_txUser = React.useRef(null);
  let rf_txType = React.useRef(null);
  let rf_txSecGroup = React.useRef(null);

  const r_snack_error = React.useRef(null);
  let r_error_message = React.useRef(null);

  const [storeSettSecUser, setStoreSettSecUser] = useSessionStorage('StoreSettSecUser', StoreSettSecUser);


  const formLoad = async (data) => {
    if (data === null) { //nuevo registro
    } else { // registro existente
      setIsMaster(Boolean(rf_txUser.current.getVal() === 'master'));
      if (rf_txType.current.getKey()==='C') {
        setIsEmployee(false);
      }
    }
  }
  const formSave = async (lastSaveRv) => {

    if (lastSaveRv.theStatus === 'error') {
      if (lastSaveRv.theValue.startsWith("rv_")) {
        // errores al guardar
        if (lastSaveRv.theValue.startsWith("rv_name_exists")) {
          r_snack_error.current.setVal(trans('msg.existing_x','field.name'));
        }
        if (lastSaveRv.theValue.startsWith("rv_not_an_email")) {
          r_snack_error.current.setVal(trans('msg.user_must_be_an_email'));
        }
        r_snack_error.current.setOpen(true);
      }
    } else {
      // se guardó exitosamente
      storeSettSecUser.inUser = lastSaveRv.theValue;
      Promise.all([setStoreSettSecUser(storeSettSecUser)]).then((result) => {
        let id = rf_txType.current.getKey();
        if (id === 'E') {
          navBack();
        }
        if (id === 'C') {
          if (lastSaveRv.theStatus === 'OK_user_not_linked_to_client') {
            r_dlg_select_client.current.setOpen(true);
          } else {
            navBack();
          }
        }
      });
    }
  }
  const formDelete = async (rv) => {
  }

  const onSelectionType = async (val) => {
    try {
      let [id, /* text */] = val.split('|');
      if (id === 'E') {
        Promise.all([setIsEmployee(true)]).then((result) => {
          rf_txUser.current.clear();
          rf_txSecGroup.current.clear();
          setUserLabel(trans('field.user'));
        });
      }
      if (id === 'C') {
        setIsEmployee(false);
        rf_txUser.current.clear();
        setUserLabel(trans('field.email'));
      }

    } catch (error) {
      FxLog.errorx("MxSettSecUserForm.onSelectionType", error);
    }
  }

  return (
    <div>
      <GxForm
        moduleName={trans('field.user')}
        xref={r_form}
        url="sett/sec/users"
        filterIdField='inUser'
        filterIdValue={storeSettSecUser.inUser}
        onFormLoad={formLoad}
        onFormSave={isFullAccess() && formSave}
        onFormDelete={!isMaster && isFullAccess() && formDelete}
      >
        <CxHidden xref={useRef(null)} dbfield="n_user.inUser" value={storeSettSecUser.inUser || ''} />

        <CxCombo xref={rf_txType}
          label={`${trans('field.type')}`}
          dbfield='n_user.txType'
          list={[
            { id: 'E', type: trans('field.employee') },
            { id: 'C', type: trans('field.client') },
          ]}
          disabled={isMaster}
          onSelection={onSelectionType}
          clearDisabled
          required
          autoSelectFirst
        />

        <CxInput xref={rf_txUser} label={userLabel} dbfield="n_user.txUser" lowerCase required maxLength={50} disabled={isMaster} /* onGetValue={getValueUser} */ />
        <CxInput xref={useRef(null)} label={trans('field.password')} dbfield="n_user.txPassword" lowerCase type="password" required maxLength={50} />

        {isEmployee && <CxChooser xref={rf_txSecGroup}
          label={trans('field.group')}
          dbfield="n_sec_group.txSecGroup" dbfieldkey="n_user.inSecGroup"
          id="n_sec_group"
          table="n_sec_group"
          col_id="inSecGroup"
          col_tx="txSecGroup"
          readOnly={false}
          required
          disabled={isMaster}
        />}

        <CxInput xref={useRef(null)} label={trans('field.notes')} dbfield="n_user.txNotes" minRows={4} />

      </GxForm>

      <GxDlgSelectClient xref={r_dlg_select_client} // change owner
        includeAnimal={false} 
        onSelect={(row, intermediateValues) => {
          if (row.original.inClient) {
            let record = {};
            record['inUser'] = storeSettSecUser.inUser || '';
            record['inClient'] = row.original.inClient;
            Promise.all([FxFetch.doJsonX('sett/sec/users/link_to_client', record)]).then((result) => {
              if (result[0]) {
                if (result[0].theValue === 'OK') {
                  navBack();
                }
              }
            });
          }
        }} 
      />

      <CxSnackbar xref={r_snack_error} severity="error" value={r_error_message.current} />

    </div>
  );

}

export default MxSettSecUserForm;
