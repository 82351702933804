import React, { useImperativeHandle } from "react";
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../fx/FxLog';
import { createTheme, MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';

const theme = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "12px",
        color: 'var(--ibs_color_white)',
        backgroundColor: 'var(--ibs_color_gray)'
      }
    }
  }
});

const TooltipBasic = withStyles({
  tooltip: {
    color: 'var(--ibs_color_white)',
    backgroundColor: 'var(--ibs_color_black)',
  }
})(Tooltip);

const TooltipAlert = withStyles({
  tooltip: {
    color: 'var(--ibs_color_white)',
    backgroundColor: 'var(--ibs_color_red)',
  }
})(Tooltip);

const TooltipTextOnly = withStyles({
  tooltip: {
    color: "black",
    backgroundColor: "transparent"
  }
})(Tooltip);

const TooltipControlled1 = withStyles({
  tooltip: {
    color: 'var(--ibs_color_black_dark)',
    backgroundColor: 'var(--ibs_color_yellow_light)',
    border: "2px solid var(--ibs_color_yellow)",
    fontSize: '16px',
  },
  arrow: {
    "&:before": {
      border: "1px solid var(--ibs_color_yellow)",
    },
    color: 'var(--ibs_color_yellow)',
  },
})(Tooltip);

const getTooltip = (props, tooltipType, text, tooltipIsOpen, setTooltipIsOpen) => {
  switch (tooltipType) {
    case 'alert':
      return <TooltipAlert TransitionComponent={Zoom} title={text || ''} arrow>{props.children}</TooltipAlert>;
    case 'textonly':
      return <TooltipTextOnly TransitionComponent={Zoom} title={text || ''} arrow>{props.children}</TooltipTextOnly>;
    case 'controlled':
      return <TooltipControlled1
        TransitionComponent={Zoom}
        title={text || ''}
        open={tooltipIsOpen}
        onOpen={null}
        onClose={null}
        arrow>
        {props.children}
      </TooltipControlled1>;

    default: return <TooltipBasic
      TransitionComponent={Zoom}
      title={text || ''}
      enterTouchDelay={10000} /** no muestra tooltips en movile */
      arrow>
      {props.children}
    </TooltipBasic>;
  }
}

const CxTooltip = props => {
  const timer = React.useRef(null);
  const [tooltipIsOpen, setTooltipIsOpen] = React.useState(false);
  const [text, setText] = React.useState(props.text);
  const [tooltipType, setTooltipType] = React.useState(props.tooltipType);
  const [millis, setMillis] = React.useState(2000);

  React.useEffect(() => {
    FxLog.infox("CxTooltip.useEffect", "...props:", props);

    return () => {
      FxLog.infox("CxDlgAlert.cleaning up timer");
      if (tooltipType === 'controlled') {
        clearInterval(timer.current);
      }
    }
    // eslint-disable-next-line
  }, []);

  useImperativeHandle(props.xref, () => {
    return {
      getType, setTooltipText, getTooltipText, setTooltipType, setMillis
    };
  });
  const getType = () => { return 'CxTooltip' };
  const getTooltipText = () => { return text };
  const setTooltipText = (text) => {
    FxLog.infox('CxTooltip.setTooltipText', '...text:', text);
    setText(text);
    setTooltipIsOpen(true);
    setAutoClose(true);
  };

  const setAutoClose = (boRun) => {
    try {
      if (boRun) {
        if (tooltipType === 'controlled') {
          timer.current = setInterval(() => {
            setText(props.text);
            setTooltipType(null);
          }, millis); //milisegundos
        }

      } else {
        if (tooltipType === 'controlled') {
          clearInterval(timer.current);
        }
      }

    } catch (error) {
      FxLog.errorx("CxTooltip.setAutoClose", error);
    }
  };

  return (
    <MuiThemeProvider theme={theme}>
      {getTooltip(props, tooltipType, text, tooltipIsOpen, setTooltipIsOpen)}
    </MuiThemeProvider>
  );

};

export default CxTooltip;
