import { CgRemoveR } from "react-icons/cg";
import { CgAddR } from "react-icons/cg";
import React, { useImperativeHandle } from "react";
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../fx/FxLog';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from '../hx/useTranslation';
import CxTooltip from '../cx/CxTooltip';


const useStyles = makeStyles({
  root: {
    "& .MuiAccordionSummary-root": {
      minHeight: '40px',
      borderRadius: '3px',
      padding: '0 10px 0 10px',
    },
    "& .MuiIconButton-root": {
      //padding: '0 10px 0 10px', /* deformaba el fondo del ícono cuando hover */
    },
    '& .MuiAccordionSummary-root:hover, .MuiButtonBase-root:hover': {
      //cursor: props => {return props.defaultExpanded ? 'default' : 'pointer'}, /* perdía mano señaladora cuando un control estaba dentro del accordion */
    },
    /*
    '& .MuiTypography-root:hover': {
      color: 'var(--ibs_color_red)',
    },
    */
  },
  accordion: {
    width: '100%',
    padding: '0px 0px 0px 0px',
  },
  accordion_summary: {
    height: '20px',
    backgroundColor: props => {
      if (props.background) {
        return props.background;
      } else {
        return 'var(--ibs_color_cy_blue)';
      }
    },
    "&.Mui-focused": {
      backgroundColor: props => {
        if (props.background) {
          return props.background;
        } else {
          return 'var(--ibs_color_black)';
        }
      },
      '& .MuiTypography-root': {
        //color: 'var(--ibs_color_black)',
      }
    }
  },
  accordion_typo: {
    fontSize: '14px',
    /* textShadow: '1px 1px 1px black', */
    //fontWeight: 'bold',
    color: props => props.color||'var(--ibs_color_white)',
  },
  accordion_details: {
    /* backgroundColor: 'var(--ibs_color_blue_light)', */
    /* border: '1px solid var(--ibs_color_yellow_light)', */
    padding: '10px 0px 0px 0px',
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'flex-start',
    gap: '10px',
  },
  hideBorder: {
    '&.MuiExpansionPanel-root:before': {
      display: 'none',
    },
  },
});

const CxAccordion = props => {
  const [{ trans }] = useTranslation();
  const { hasTooltip, defaultExpanded } = props;
  const classes = useStyles(props);

  const [isExpanded, setExpanded] = React.useState(defaultExpanded||false);
  const [fontWeight, setFontWeight] = React.useState('normal');

  React.useEffect(() => {
    // eslint-disable-next-line
  }, []);

  useImperativeHandle(props.xref, () => {
    return {
      getType, setFontWeight,
    };
  });
  const getType = () => { return 'CxAccordion' };

  const getIcon = () => {
    if (defaultExpanded) {
      return undefined;
    } else {
      if (isExpanded) {
        return <CgRemoveR fontSize="21px" style={{ color: props.color||'var(--ibs_color_white)' }} />;
      } else {
        return <CgAddR fontSize="22px" style={{ color: props.color||'var(--ibs_color_white)' }} />;
      }
    }
  }


  return (
    <div className={classes.accordion}>
      <Accordion tabIndex={-1} className={classes.root}
        style={{ boxShadow: props.shadow || "none" }}
        expanded={defaultExpanded || isExpanded}
        onChange={() => setExpanded(!isExpanded)}
      >
        <CxTooltip text={hasTooltip ? `${trans('field.open')}/${trans('field.close')}` : null}>
          <AccordionSummary tabIndex={-1} className={classes.accordion_summary} expandIcon={getIcon()} >
            <Typography className={classes.accordion_typo} style={{ fontWeight: fontWeight }} >{`${props.title}`}</Typography>
          </AccordionSummary>
        </CxTooltip>
        <AccordionDetails className={classes.accordion_details}>
          {props.children}
        </AccordionDetails>
      </Accordion>
    </div>
  );

};

export default CxAccordion;
