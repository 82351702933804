import React, { useImperativeHandle } from "react";
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../fx/FxLog';
import * as FxFetch from '../fx/FxFetch';
import CxIconButton from '../cx/CxIconButton';
import CxSnackbar from '../cx/CxSnackbar';
import { useTranslation } from '../hx/useTranslation';


const GxIconButtonLastClient = props => {
  const { theList, theTable, theInput, onClientChanged, ...rest } = props;
  const [{ trans }] = useTranslation();
  const [visible, setVisible] = React.useState(rest.visible || true);

  let r_snack_error = React.useRef(null);
  
  
  React.useEffect(() => {
    // eslint-disable-next-line
  }, []);

  useImperativeHandle(props.xref, () => {
    return {
      getType, setVisible
    };
  });
  const getType = () => { return 'GxIconButtonLastClient' };

  const doLastClientSearch = () => {
    let lastInClient = (localStorage.getItem('MxClientForm.last_client') || '');
    if (lastInClient) {
      Promise.all([FxFetch.doJsonX("general/r", { table: 'n_client', field: 'txClient', where: `inClient='${lastInClient}'` } )]).then((result) => {
        if (result[0]) {
          let lastTxClient = result[0].theValue;
          if (theList) {
            theList.current.getTable().setSearchForced(lastTxClient);
          }
          if (theTable) {
            theTable.current.setSearchForced(lastTxClient);
          }
          if (theInput) {
            if (!theInput.current.isDisabled()) {
              theInput.current.setKey(lastInClient);
              theInput.current.setVal(lastTxClient);
              if (onClientChanged) {
                onClientChanged(lastInClient, lastTxClient);
              }
            }
          }
        }
      });
    } else {
      r_snack_error.current.setVal(trans('msg.no_x_file_opened','field.client'));
      r_snack_error.current.setOpen(true);
    }
  }

  return (
    <div>
      {visible && 
        <CxIconButton type="last_client_search" classType="ibs_iconbutton_toolbar" onClick={doLastClientSearch} tooltip={trans('field.search_last_x', 'field.client')} />
      }
      <CxSnackbar xref={r_snack_error} severity="error" />
    </div>
  );

};

export default GxIconButtonLastClient;
