import { MdOutlinePets } from "react-icons/md"; 
import { TbCertificate } from "react-icons/tb"; 
import React, { useRef } from 'react';
import GxForm from '../../gx/GxForm';
import { useTranslation } from '../../hx/useTranslation';
import { useNav } from '../../hx/useNav';
import CxHidden from '../../cx/CxHidden';
import CxSearcher from '../../cx/CxSearcher';
import CxDate from '../../cx/CxDate';
import CxInput from '../../cx/CxInput';
import CxStatic from '../../cx/CxStatic';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import * as FxDat from '../../fx/FxDat';
import { useSessionStorage } from '../../hx/useSessionStorage';
import CxSnackbar from '../../cx/CxSnackbar';
import { clearStore, StoreComm, StoreClient, StoreVacc, getStoreValue, getStoreValueBo } from "../../gx/GxStore";
import CxIconButton from '../../cx/CxIconButton';
import CxIconButtonNew from '../../cx/CxIconButtonNew';
import { useAccess } from '../../hx/useAccess';
import GxPrint from '../../gx/GxPrint';
import GxSlsAuxTable from '../../gx/GxSlsAuxTable';


const MxAnimalVaccForm = (props) => {
  const [{ trans }] = useTranslation();
  const [{ isFullAccess }] = useAccess(trans('field.clients'));
  const [{ navTo, navStack }] = useNav();

  const [, updateState] = React.useState();
  const forceUIUpdate = React.useCallback(() => updateState({}), []);

  const [storeClient, ] = useSessionStorage('StoreClient', StoreClient);
  const [storeComm, setStoreComm] = useSessionStorage('StoreComm', StoreComm);
  const [storeVacc, setStoreVacc] = useSessionStorage('StoreVacc', StoreVacc);

  let r_form = React.useRef(null);
  let r_sls_aux_table = React.useRef(null);
  let r_print = React.useRef(null);

  const rf_ps = React.useRef(null);
  const rf_date = React.useRef(null);
  const rf_next = React.useRef(null);
  let rf_txEmployee = React.useRef(null);
  
  const r_snack_ask_save = React.useRef(null);
  const r_snack_error = React.useRef(null);
  const r_snack_ps_doesnt_exist = React.useRef(null);


  React.useEffect(() => {
    clearStore('StoreComm', storeComm);
    // eslint-disable-next-line
  }, []);

  const formLoad = async (data) => {
    if (data===null) { //nuevo registro
      r_form.current.getRef("n_vacc.dtFrom").setValToday();
      if (getStoreValueBo('StoreSession', 'boAutoSelEmployee')) {
        rf_txEmployee.current.setKey(getStoreValue('StoreSession', 'inEmployee'));
        rf_txEmployee.current.setVal(getStoreValue('StoreSession', 'txEmployee'));
      }
    }
  }
  const formSave = async (lastSaveRv) => {
    if (lastSaveRv.theValue.startsWith("rv_")) {
      // errores al guardar
      if (lastSaveRv.theValue.startsWith("rv_ps_doesnt_exist")) {
        r_snack_ps_doesnt_exist.current.setOpen(true);
      }
    } else {
      // se guardó exitosamente
      storeVacc.inVacc = lastSaveRv.theValue;
      Promise.all([setStoreVacc(storeVacc)]).then((result) => {
        r_sls_aux_table.current.setStrRelatedId(storeVacc.inVacc);
        forceUIUpdate();
      });
      //navBack(); // 2024-07-02 - daq: xq puede querer añadir ps o un recordatorio
    }

  }
  const formDelete = async (rv) => {
  }

  const openCommForm = () => {
    if (storeVacc.inVacc) {
      storeComm.inComm = null;
      storeComm.inClient = storeClient.inClient;
      storeComm.inAnimal = storeClient.inAnimal;
      storeComm.inVacc = storeVacc.inVacc;
      storeComm.dtFrom = rf_next.current.getVal();
      setStoreComm(storeComm);
      navTo("comm-form");
    } else {
      r_snack_ask_save.current.setOpen(true);
    }
  }

  const psChanged = async (row) => {
    if (row !== null) {
      let nuFrequency = row.original.nuFrequency;
      let next = FxDat.addDays(rf_date.current.getVal(), nuFrequency);
      rf_next.current.setVal(next);
    }
  }

  const doPrintX = async (option) => {
    let inVacc = storeVacc.inVacc;
    let filter = {};
    filter['reportFormat'] = `PDF`;
    filter['inVacc'] = inVacc;
    let record = {};
    r_print.current.setScale(1.5);
    r_print.current.setUrl('vacc/report/certificate');
    //----------------
    Promise.all([
      r_print.current.setFilter(filter),
      r_print.current.setRecord(record),
    ]).then((result) => {
      r_print.current.doPrint();
    });
  }

  return (
    <div>
      <GxForm 
        moduleName={`${trans('field.patient')}: ${trans('field.vacc')}`}
        xref={r_form}
        url="vaccs" 
        filterIdField='inVacc' 
        filterIdValue={storeVacc.inVacc} 
        onFormLoad={formLoad} 
        onFormSave={isFullAccess() && formSave} 
        onFormDelete={isFullAccess() && formDelete} 
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {/* botón de creación de comm */}
            {storeVacc.inVacc && <CxIconButtonNew icon={<TbCertificate fontSize="24px" />} classType="ibs_iconbutton_toolbar" onClick={() => doPrintX()} tooltip={trans('field.vacc_certificate')} />}
            {storeVacc.inVacc && isFullAccess() && <CxIconButton type="comms" classType="ibs_iconbutton_toolbar" onClick={openCommForm} tooltip={trans('field.comm')} />}
            {storeVacc.inVacc && navStack()[navStack().length-2]==='activity-vett-v-list' && <CxIconButtonNew icon={<MdOutlinePets fontSize={23} />} classType="ibs_iconbutton_toolbar" onClick={() => navTo("animal-form")} tooltip={trans('field.patient')} />}
          </div>
        }
        >
        <CxHidden xref={useRef(null)} dbfield="n_vacc.inClient" value={storeClient.inClient || ''} />
        <CxHidden xref={useRef(null)} dbfield="n_vacc.inAnimal" value={storeClient.inAnimal || ''} />

        <CxStatic label={`${storeClient.txClient} / ${storeClient.txAnimal}`} border='none' fontcolor='var(--ibs_color_blue)' fontsize='12px' />

        <CxSearcher xref={rf_ps} label={trans('field.ps')}
          dbfield="n_ps.txPs" dbfieldkey={`n_vacc.inPs`}
          filter={'n_ps.nuFrequency > 0'}
          onSelection={psChanged}
          url="pss"
          col_id="inPs"
          col_tx="txPs"
          tooltip={`${trans('field.ps')}`}
          columns={[
            { id: "0", Header: "ID", accessor: "inPs", show: false },
            { id: "1", Header: trans('field.ps'), accessor: row => rf_ps.current.getTable().highlightText(row["txPs"]), width: '100%' },
            { id: "2", Header: trans('field.frequency'), accessor: "nuFrequency", show: false },
          ]}
          bottomLegend={trans('msg.shows_x_with_x','field.pss','field.frequency')}
          required
        />

        <CxDate xref={rf_date} label={trans('field.date')} dbfield="n_vacc.dtFrom"/>
        <CxDate xref={rf_next} label={trans('field.next')} dbfield="n_vacc.dtNext"/>
        <CxInput xref={useRef(null)} label={trans('field.stamp')} dbfield="n_vacc.txStamp" maxLength={50}/>
        <CxInput xref={useRef(null)} label={trans('field.remarks')} dbfield="n_vacc.txRemarks" maxLength={255}/>

        <CxSearcher xref={rf_txEmployee} label={trans('field.employee')}
          dbfield="n_employee.txEmployee" dbfieldkey="n_vacc.inEmployee"
          url="employees" col_id="inEmployee" col_tx="txEmployee"
          columns={[
            { id: "0", Header: "ID", accessor: "inEmployee", show: false },
            { id: "1", Header: trans('field.employee'), accessor: row => rf_txEmployee.current.getTable().highlightText(row["txEmployee"]), width: '100%' },
          ]}
        />
        
      </GxForm>

      <GxSlsAuxTable xref={r_sls_aux_table} strRelatedField={'inVacc'} strRelatedId={storeVacc.inVacc} /* rf_txEmployee={rf_txEmployee} */ />

      <GxPrint xref={r_print} />
      
      <CxSnackbar xref={r_snack_ask_save} severity="error" value={trans('msg.save_before')} />
      <CxSnackbar xref={r_snack_ps_doesnt_exist} severity="error" value={trans('msg.x_doesnt_exist_1','field.product')} />
      <CxSnackbar xref={r_snack_error} severity="error" />
      
    </div>
  );

}

export default MxAnimalVaccForm;
