import React from 'react';
import { useTranslation } from '../../hx/useTranslation';
import CxSearcher from '../../cx/CxSearcher';
import CxSnackbar from '../../cx/CxSnackbar';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import * as FxFetch from '../../fx/FxFetch';
import '../../../styles/main.css';
import GxForm from '../../gx/GxForm';
import CxIconButton from '../../cx/CxIconButton';
//import { useNav } from '../../hx/useNav';


const MxSettClientMergeForm = (props) => {
  const [{ trans }] = useTranslation();
  //const [{ navBack }] = useNav();
  const r_snack_required = React.useRef(null);
  const r_snack_same_item = React.useRef(null);
  const r_snack_op_in_progress = React.useRef(null);
  const r_snack_op_successful = React.useRef(null);
  let r_form = React.useRef(null);

  const rf_txClientToKeep = React.useRef(null);
  const rf_txClientToDelete = React.useRef(null);


  React.useEffect(() => {
    // eslint-disable-next-line
  }, []);
  
  const doMerge = () => {
    // chequea requeridos
    if (rf_txClientToKeep.current.isEmpty()) {
      r_snack_required.current.setVal(trans('field.required') + ": " + rf_txClientToKeep.current.getLabel());
      r_snack_required.current.setOpen(true);
      return;
    }
    if (rf_txClientToDelete.current.isEmpty()) {
      r_snack_required.current.setVal(trans('field.required') + ": " + rf_txClientToDelete.current.getLabel());
      r_snack_required.current.setOpen(true);
      return;
    }
    if (rf_txClientToKeep.current.getKey()===rf_txClientToDelete.current.getKey()) {
      r_snack_same_item.current.setOpen(true);
      return;
    }
    
    let inClient_toKeep = rf_txClientToKeep.current.getKey();
    let inClient_toDelete = rf_txClientToDelete.current.getKey();

    let record = {};
    record['inClient_toKeep'] = inClient_toKeep;
    record['inClient_toDelete'] = inClient_toDelete;
    r_snack_op_in_progress.current.setOpen(true);
    Promise.all([FxFetch.doJsonX(`/sett/client/merge/do`, record)]).then((result) => {
      rf_txClientToKeep.current.clear();
      rf_txClientToDelete.current.clear();
      r_snack_op_in_progress.current.setOpen(false);
      r_snack_op_successful.current.setOpen(true);
    });
  }

  return (
    <div>
      <GxForm
        moduleName={`${trans('field.merge_x', 'field.clients')}`}
        xref={r_form}
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <CxIconButton type="issue" classType="ibs_iconbutton_toolbar" onClick={doMerge} tooltip={trans('field.merge_duplicates')} />
          </div>
        }
      >

      <CxSearcher xref={rf_txClientToKeep} label={trans('field.keep')}
        url="clients/searcher"
        col_id="inClient"
        col_tx="txClient"
        colorRefs={ [ 'table_cell_red|' + trans('field.debtor') ] }
        tooltip={`${trans('field.client')}`}
        columns={[
          { id: "0", Header: "ID", accessor: "inClient", show: false },
          { id: "1", Header: trans('field.client'), accessor: row => rf_txClientToKeep.current.getTable().highlightText(row["txClient"]), width: '100%' },
          { id: "R", Header: "balance", accessor: row => Boolean((row["theBalance"]) < 0 ? true : false), show: false },
        ]}
        readOnly={false}
        width='var(--ibs_control_width_md)'
        required
      />

      <CxSearcher xref={rf_txClientToDelete} label={trans('field.delete')}
        url="clients/searcher"
        col_id="inClient"
        col_tx="txClient"
        colorRefs={ [ 'table_cell_red|' + trans('field.debtor') ] }
        tooltip={`${trans('field.client')}`}
        columns={[
          { id: "0", Header: "ID", accessor: "inClient", show: false },
          { id: "1", Header: trans('field.client'), accessor: row => rf_txClientToDelete.current.getTable().highlightText(row["txClient"]), width: '100%' },
          { id: "R", Header: "balance", accessor: row => Boolean((row["theBalance"]) < 0 ? true : false), show: false },
        ]}
        readOnly={false}
        width='var(--ibs_control_width_md)'
        required
      />

      </GxForm>

      <CxSnackbar xref={r_snack_required} severity="error" />
      <CxSnackbar xref={r_snack_same_item} severity="error" value={trans('msg.same_item')} />
      <CxSnackbar xref={r_snack_op_in_progress} severity="info" value={trans('msg.operation_in_progress')} autoHideDuration={10000} />
      <CxSnackbar xref={r_snack_op_successful} severity="success" value={trans('msg.operation_successful')} />
    </div>
  );

}

export default MxSettClientMergeForm;
