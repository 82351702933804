import React from 'react';
import { useTranslation } from '../../hx/useTranslation';
import * as FxLog from '../../fx/FxLog';
import GxList from '../../gx/GxList';
import { useWindowSize } from '../../hx/useWindowSize';
import CxIconButton from '../../cx/CxIconButton';
import * as FxFetch from '../../fx/FxFetch';


const MxSettAnimalRestoreList = (props) => {
  const [{ trans }] = useTranslation();
  const size = useWindowSize();

  const r_list = React.useRef(null);

  React.useEffect(() => {
    formLoad();
    // eslint-disable-next-line
  }, []);

  const formLoad = async () => {
  }

  const restoreItem = (row) => {
    let record = {};
    record['inAnimal'] = row.inAnimal;
    record['inClient'] = row.inClient;
    Promise.all([FxFetch.doJsonX('sett/animal/restore/do', record)]).then((result) => {
      if (result[0].theValue==='OK') {
        r_list.current.refresh();
      }
    });
  }

  const getTableColumns = () => {
    let cols = [];
    try{
      let id = -1;
      cols.push({ id: id++, Header: "ID", accessor: "inAnimal", show: false });
      cols.push({ id: id++, Header: "_inClient", accessor: "inAnimal", width: '0%', show: false });
      if (size.isLarge) { // PANTALLA GRANDE
        cols.push({ id: id++, Header: trans('field.patient'), accessor: row => r_list.current.getTable().highlightText(row["txAnimal"]), width: '20%', cursor: 'notallowed' });
        cols.push({ id: id++, Header: trans('field.client'), accessor: row => r_list.current.getTable().highlightText(row["txClient"]), width: '20%', cursor: 'notallowed' });
        cols.push({ id: id++, Header: trans('field.species'), accessor: "txTypeAnimalSpecies", width: '20%', cursor: 'notallowed' });
        cols.push({ id: id++, Header: trans('field.breed'), accessor: "txTypeAnimalBreed", width: '20%', cursor: 'notallowed' });
        cols.push({ id: id++, Header: trans('field.gender'), accessor: row => row["boGender"] === '0' ? trans('field.animal_female') : trans('field.animal_male'), width: '20%', cursor: 'notallowed' });
        cols.push({ id: id++, accessor: row => { return <CxIconButton type="restore" classType="ibs_iconbutton_table" data={row} onClick={restoreItem} tooltip={trans('field.restore')} />}, align: 'center', width: '5%' });

      } else { // PANTALLA PEQUEÑA
        cols.push({ id: id++, Header: trans('field.patient'), accessor: row => r_list.current.getTable().highlightText(row["txAnimal"]), width: '30%' });
        cols.push({ id: id++, Header: trans('field.client'), accessor: row => r_list.current.getTable().highlightText(row["txClient"]), width: '70%' });
        cols.push({ id: id++, accessor: row => { return <CxIconButton type="restore" classType="ibs_iconbutton_table" data={row} onClick={restoreItem} tooltip={trans('field.restore')} />}, align: 'center', width: '10%' });
      }

    } catch(error) {
      FxLog.errorx('MxSettAnimalRestoreList.getTableColumns', error)
    }
    return cols;
  }

  return (
    <div>
      <GxList
        moduleName={trans('field.deactivated_x').replace('%s',trans('field.patients'))}
        xref={r_list}
        tooltip={`${trans('field.patient')} / ${trans('field.client')}`}
        fullscreen
        url="sett/animal/restore"
        columns={getTableColumns()}
        hasButtonAdd={false}
      />

    </div>

  );

}

export default MxSettAnimalRestoreList;
