import React, { useImperativeHandle } from "react";
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../fx/FxLog';
import '../../styles/main.css';
import CxDialog from './CxDialog';
import CxTable from './CxTable';



const CxDlgTable = props => {
  let r_table = React.useRef(null);

  const { onSelect, onClose, open, filter, group, ...rest } = props;

  const rowSelected = async (op, row) => {
    onSelect(row);
  }

  useImperativeHandle(props.xref, () => {
    return {
      getType, getTable,
    };
  });
  const getType = () => { return 'CxDlgTable' };
  const getTable = () => { return r_table.current };

  return (
    <div>

      <CxDialog
        //xref={r_dlg}
        title={rest.title}
        open={open}
        onClose={() => onClose()}
        height={rest.height || '450px'}
        >

        <CxTable
          xref={r_table}
          origin='CxDlgTable'
          hasSearchBox={rest.hasSearchBox}
          tooltip={rest.tooltip}
          url={rest.url}
          filter={filter || ''}
          group={group || ''}
          columns={rest.columns}
          onOpenForm={rowSelected}
        />
      </CxDialog>

    </div>
  );
};

export default CxDlgTable;
