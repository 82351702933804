import React from 'react';
import { useTranslation } from '../../hx/useTranslation';
import * as FxLog from '../../fx/FxLog';
import * as FxDat from '../../fx/FxDat';
import * as FxStr from '../../fx/FxStr';
import GxList from '../../gx/GxList';
import GxPrint from '../../gx/GxPrint';
import CxDlgAlert from '../../cx/CxDlgAlert';
import { useWindowSize } from '../../hx/useWindowSize';
import * as FxTable from '../../fx/FxTable';
import * as FxFetch from '../../fx/FxFetch';
import CxSnackbar from '../../cx/CxSnackbar';
import { StoreMarketing, isApp,  } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import CxIconButton from '../../cx/CxIconButton';
import { useNav } from '../../hx/useNav';


const MxMarketingList = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navTo }] = useNav();
  const size = useWindowSize();

  const r_list = React.useRef(null);
  let r_print = React.useRef(null);

  const r_snack_success = React.useRef(null);
  const r_snack_error = React.useRef(null);
  const r_dlg_alert = React.useRef(null);

  // eslint-disable-next-line
  const [storeMarketing, setStoreMarketing] = useSessionStorage('StoreMarketing', StoreMarketing);

  const [lockExclude, setLockExclude] = React.useState(true);


  React.useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const doPrintX = async (op, row) => {
    let filter = {};
    filter['reportFormat'] = `PDF`;
    filter['inClient'] = row.original.inClient;
    filter['inXXX'] = row.original.inInvoice;
    filter['docType'] = 'i';
    filter['docSize'] = size.isLarge ? 'A4' : 'A8';
    filter['salesSlipWidth'] = localStorage.getItem('MxSettSlsPrinting.sales_slip_width') || '60';
    filter['salesSlipMarginLeft'] = localStorage.getItem('MxSettSlsPrinting.sales_slip_margin_left') || '0';
    let record = {};
    if (filter['docSize'] === 'A8') {
      r_print.current.setScale(1.8);
    }
    if (filter['docSize'] === 'A4') {
      r_print.current.setScale(1.5);
    }
    r_print.current.setUrl('slsDoc/report');
    //----------------
    Promise.all([
      r_print.current.setFilter(filter),
      r_print.current.setRecord(record),
      r_print.current.setInClient(row.original.inClient),
    ]).then((result) => {
      r_print.current.doPrint();
    });
  }

  const doExcludeClient = (row) => {
    let inClient = row.inClient;
    storeMarketing.arExcludedId += "|" + inClient;
    Promise.all([setStoreMarketing(storeMarketing)]).then((result) => {
      Promise.all([r_list.current.setdeepAddParams(getAddParams())]).then((result) => {
        r_list.current.refresh();
      });
    });
  }

  const goNext = async () => {
    r_dlg_alert.current.setOpen(true);
    Promise.all([setListedId()]).then((result) => {
      r_dlg_alert.current.setOpen(false);
      if (storeMarketing.marketingType==='E') {
        navTo("marketing-option-e");
      }
      if (storeMarketing.marketingType==='S') {
        navTo("marketing-option-s");
      }
    });
  }

  const setListedId = () => {
    let record = {};
    record['pageNo'] = '0';
    record['pageSize'] = '1000';
    record['sortBy'] = '';
    record['filter'] = '';
    record['group'] = '';
    let addParams = getAddParams();
    if (addParams) {
      let params = addParams.split("&");
      params.forEach(function (item, index) {
        record[item.split("=")[0]] = item.split("=")[1];
      });
    }
    return Promise.all([FxFetch.doJsonX("marketing", record)]).then((result) => {
      let rs = result[0];
      let all = '';
      if (rs) {
        FxLog.infox('MxMarketingList.setListedId', '...rs:', rs);
        for (let i = 0; i < rs.length; i++) {
          let inClient = rs[i].inClient || '';
          let inAnimal = rs[i].animal_inAnimal || '';
          let inPs = rs[i].inPs || '';
          let dtNext = FxStr.substringBefore(rs[i].dtNext, ' ');
          all += inClient + "|" + inAnimal + "|" + inPs + "|" + dtNext + '\n';
        }
        FxLog.infox('MxMarketingList.setListedId', '...all:', '\n'+all);
        storeMarketing.arListedId = all;
        return Promise.all([setStoreMarketing(storeMarketing)]).then((result) => {
          return;
        });
      }
    });
  }

  const getTableColumns = () => {
    let cols = [];
    try {
      let id = -1;
      cols.push({ id: id++, Header: "ID", accessor: "inClient", show: false });
      if (size.isLarge) { // PANTALLA GRANDE
        cols.push({ id: "txClient", Header: trans('field.client'), accessor: "txClient", width: '30%' });
        cols.push({ id: "txEmail", Header: trans('field.email'), accessor: "txEmail", width: '15%' });
        cols.push({ id: "txMobile", Header: trans('field.mobile'), accessor: "txMobile", width: '15%' });
        cols.push({ id: "txAddress", Header: trans('field.address'), accessor: "txAddress", width: '15%' });
        cols.push({ id: "txCity", Header: trans('field.city'), accessor: "txCity", width: '15%' });
        if ((isApp('vett') || isApp('spaw')) && storeMarketing.boGroupByClient==='0') {
          cols.push({ id: "inAnimal", Header: '_inAnimal', accessor: row => row["animal_inAnimal"], show: false });
          cols.push({ id: "txAnimal", Header: trans('field.patient'), accessor: "animal_txAnimal", width: '15%' });
        } else {
        }
        cols.push({ id: id++, Header: trans('field.balance'), accessor: row => FxTable.cellTo2r00(row["theBalance"]), width: '10%', align: 'right' });

      } else { // PANTALLA PEQUEÑA
        cols.push({ id: "txClient", Header: trans('field.client'), accessor: "txClient", width: '70%' });
        if ((isApp('vett') || isApp('spaw')) && storeMarketing.boGroupByClient==='0') {
          cols.push({ id: "inAnimal", Header: '_inAnimal', accessor: "animal_inAnimal", show: false });
          cols.push({ id: "txAnimal", Header: trans('field.patient'), accessor: "animal_txAnimal", width: '30%' });
        } else {
          cols.push({ id: "txMobile", Header: trans('field.mobile'), accessor: "txMobile", width: '30%' });
        }

      }

      /* Esto sólo sirve para debug, cambiando a show: true */
      /*
      if ((isApp('opti') || isApp('vett') || isApp('spaw'))) {
        cols.push({ id: "inPs", Header: '_inPs', accessor: "inPs", show: false });
        cols.push({ id: "txPs", Header: '_txPs', accessor: "txPs", show: false });
        cols.push({ id: "dtNext", Header: '_dtNext', accessor: "dtNext", show: false });
      }
      */

      cols.push({ id: "B", Header: "cp_access", accessor: row => Boolean(row["inUser"]), show: false });
      cols.push({ id: "A", Header: "inactive", accessor: row => FxDat.isExpired(row["dtTo"]), show: false });
      cols.push({ id: "R", Header: "balance", accessor: row => Boolean((row["theBalance"]) < 0 ? true : false), show: false });

      cols.push({
        id: "BTN_EXC", iconID: lockExclude ? "lock" : "unlock", iconTooltip: trans('field.column_lock_unlock'), onIconClick: () => setLockExclude(!lockExclude),
        accessor: row => { return <CxIconButton type="cancel" classType="ibs_iconbutton_table" disabled={lockExclude} data={row} onClick={doExcludeClient} tooltip={trans("field.exclude_from_x", 'field.list')} /> },
        align: 'center', width: '33px'
      });
  
    } catch (error) {
      FxLog.errorx('MxMarketingList.getTableColumns', error)
    }
    return cols;
  }

  const getAddParams = () => {
    return "marketingType=" + storeMarketing.marketingType + ""
      + "&inClient=" + storeMarketing.inClient + ""
      + "&dtRegisteredFrom=" + storeMarketing.dtRegisteredFrom + ""
      + "&dtRegisteredTo=" + storeMarketing.dtRegisteredTo + ""
      + "&dtBirthFrom=" + storeMarketing.dtBirthFrom + ""
      + "&dtBirthTo=" + storeMarketing.dtBirthTo + ""
      + "&inBranch=" + storeMarketing.inBranch + ""
      + "&txCity=" + storeMarketing.txCity + ""
      + "&txState=" + storeMarketing.txState + ""
      + "&txPostalCode=" + storeMarketing.txPostalCode + ""
      + "&txCountry=" + storeMarketing.txCountry + ""
      + "&nuMailing=" + storeMarketing.nuMailing + ""
      + "&txLang=" + storeMarketing.txLang + ""
      + "&boDebtor=" + storeMarketing.boDebtor + ""
      + "&arDocsSigned=" + storeMarketing.arDocsSigned + ""
      + "&dtPssFqFrom=" + storeMarketing.dtPssFqFrom + ""
      + "&dtPssFqTo=" + storeMarketing.dtPssFqTo + ""
      + "&arPssFq=" + storeMarketing.arPssFq + ""
      + "&boGroupByClient=" + storeMarketing.boGroupByClient + ""
      + "&boVaccLast=" + storeMarketing.boVaccLast + ""
      + "&dtAnimalBirthFrom=" + storeMarketing.dtAnimalBirthFrom + ""
      + "&dtAnimalBirthTo=" + storeMarketing.dtAnimalBirthTo + ""
      + "&txTypeAnimalSpecies=" + storeMarketing.txTypeAnimalSpecies + ""
      + "&txTypeAnimalBreed=" + storeMarketing.txTypeAnimalBreed + ""
      + "&arExcludedId=" + storeMarketing.arExcludedId + "";
  }

  return (
    <div>
      <GxList
        moduleName={`${trans('field.marketing')}: ${trans('msg.clients_with_x', storeMarketing.marketingType==='E' ? 'field.email' : 'field.mobile')}`}
        toolbarBackground={storeMarketing.marketingType==='E' ? 'var(--ibs_color_red_light)' : 'var(--ibs_color_yellow_light)'}
        xref={r_list}
        fullscreen
        hasSearchBox={false}
        hasButtonAdd={false}
        url="marketing"
        addParams={getAddParams()}
        onOpenForm={doPrintX}
        colorRefs={['table_cell_red|' + trans('field.debtor')]}
        columns={getTableColumns()}
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <CxIconButton type="chevron_right" classType="ibs_iconbutton_toolbar" onClick={goNext} tooltip={trans('field.next')} />
          </div>
        }
      >
      </GxList>

      <CxDlgAlert xref={r_dlg_alert} />
      <GxPrint xref={r_print} />

      <CxSnackbar xref={r_snack_success} severity="success" value={trans('msg.operation_successful')} />
      <CxSnackbar xref={r_snack_error} severity="error" />

    </div>
  );

}

export default MxMarketingList;
