import { MdOutlinePets } from "react-icons/md"; 
import { CgBrackets } from "react-icons/cg"; 
import { MdOutlineCancelPresentation } from "react-icons/md"; 
import { MdOutlinePresentToAll } from "react-icons/md"; 
import React from 'react';
import GxForm from '../../gx/GxForm';
import GxPrint from '../../gx/GxPrint';
import { useTranslation } from '../../hx/useTranslation';
import { useNav } from '../../hx/useNav';
import CxInput from '../../cx/CxInput';
import CxSnackbar from '../../cx/CxSnackbar';
import CxNumber from '../../cx/CxNumber';
import CxDate from '../../cx/CxDate';
import CxState from '../../cx/CxState';
import CxLink from '../../cx/CxLink';
import CxTableMem from '../../cx/CxTableMem';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import * as FxFetch from '../../fx/FxFetch';
import * as FxTable from '../../fx/FxTable';
import * as FxInet from '../../fx/FxInet';
import { getStoreValue, getStoreValueBo, StoreRxAuto, StoreClient, } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import CxHidden from '../../cx/CxHidden';
import CxChooser from '../../cx/CxChooser';
import CxSearcher from '../../cx/CxSearcher';
import { useAccess } from '../../hx/useAccess';
import CxDialog from '../../cx/CxDialog';
import CxBackdrop from '../../cx/CxBackdrop';
import CxIconButton from '../../cx/CxIconButton';
import CxIconButtonNew from '../../cx/CxIconButtonNew';
import CxDlgAlert from '../../cx/CxDlgAlert';
import CxDlgCertificate from '../../cx/CxDlgCertificate';
import * as FxEdition from '../../fx/FxEdition';
import CxDlgUpgrade from '../../cx/CxDlgUpgrade';
import CxStatic from '../../cx/CxStatic';


const MxAnimalRxAutoForm = (props) => {
  const [{ trans }] = useTranslation();
  const [{ isFullAccess }] = useAccess(trans('field.rxs'));
  const [{ navTo, navBack, navStack }] = useNav();

  const timer = React.useRef(null);

  const [, updateState] = React.useState();
  const forceUIUpdate = React.useCallback(() => updateState({}), []);

  let r_boProcessing = React.useRef(false);

  const [storeClient, ] = useSessionStorage('StoreClient', StoreClient);

  const r_snack_ask_save = React.useRef(null);
  const r_snack_save = React.useRef(null);
  const r_snack_delete = React.useRef(null);
  const r_snack_error = React.useRef(null);
  const r_snack_success = React.useRef(null);

  const r_form = React.useRef(null);
  let r_print = React.useRef(null);
  let r_dlg_upgrade = React.useRef(null);
  const rf_presvet_button_export = React.useRef(null);
  const rf_presvet_button_remove = React.useRef(null);
  const rf_presvet_button_add_code_manually = React.useRef(null);
  const rf_txAddPresvetCode = React.useRef(null);

  const rf_print_preview = React.useRef(null);
  const rf_print_cert_sign = React.useRef(null);
  const rf_print_copies = React.useRef(null);
  
  const rf_inRxAuto = React.useRef(null);
  const rf_txCodePresvet = React.useRef(null);
  const rf_txRxAuto = React.useRef(null);
  const rf_dtRxAuto = React.useRef(null);
  
  const rf_txEmployee = React.useRef(null);
  const rf_txTypeSeriesRx = React.useRef(null);
  const rf_txNumberAnimals = React.useRef(null);
  const rf_txIdentification = React.useRef(null);
  const rf_txNumberRefills = React.useRef(null);
  const rf_boExceptional = React.useRef(null);
  const rf_txDaysValidity = React.useRef(null);
  const rf_txNotes = React.useRef(null);
  const rf_presvet_status = React.useRef(null);
  const rf_presvet_status_button_clear = React.useRef(null);
  
  const rf_table_items = React.useRef(null);
  const r_dlg_certificate = React.useRef(null);
  const r_dlg_alert = React.useRef(null);

  const r_dlg_presvet_export = React.useRef(null);
  const r_dlg_presvet_remove = React.useRef(null);
  const r_dlg_presvet_addcode = React.useRef(null);

  const [dlgPrint, setDlgPrint] = React.useState(false);
  const [dlgDelete, setDlgDelete] = React.useState(false);
  const [dlgExit, setDlgExit] = React.useState(false);
  const [dlgPresvetExport, setDlgPresvetExport] = React.useState(false);
  const [dlgPresvetRemove, setDlgPresvetRemove] = React.useState(false);
  const [dlgPresvetAddCode, setDlgPresvetAddCode] = React.useState(false);

  const [certificate, setCertificate] = React.useState({});

  const [backdrop, setBackdrop] = React.useState(false);

  const [storeRxAuto, setStoreRxAuto] = useSessionStorage('StoreRxAuto', StoreRxAuto);

  // Es el txRxAuto. No puedo usar storeRxAuto.txRxAuto porque al cambiarlo, react no lo vé como un cambio ya que lo que mantiene es la referencia al objeto storeClient.
  // eslint-disable-next-line no-unused-vars
  const [currentID, setCurrentID] = React.useState(storeRxAuto.txRxAuto);

  const isSpain = () => { return Boolean(getStoreValue('StoreSession', 'txIdi').startsWith('es.') || getStoreValue('StoreSession', 'txIdi').startsWith('xx.') ) }
  const isUsAu = () => { return Boolean(getStoreValue('StoreSession', 'txIdi').startsWith('us.') || getStoreValue('StoreSession', 'txIdi').startsWith('au.') ) }

  React.useEffect(() => {
    formLoadCustom();
    FxLog.infox('MxAnimalRxAutoForm.useEffect', '...navStack:', navStack()[navStack().length - 2]);

    if (isSpain()) {
      timer.current = setInterval(() => {
        //FxLog.infox('MxAnimalRxAutoForm.useEffect', '...timer running');
        if (FxInet.isOnline()) {
          presvetRefreshCode();
        }
      }, 2000); //milisegundos
    }
    
    return () => {
      FxLog.infox('MxAnimalRxAutoForm.useEffect', '...timer finishing');
      clearInterval(timer.current);
    }
    // eslint-disable-next-line
  }, []);
  
  const presvetRefreshCode = () => {
    let record = {};
    record['txRxAuto'] = rf_txRxAuto.current.getVal();
    record['itSeriesRx'] = rf_txTypeSeriesRx.current.getKey();
    Promise.all([FxFetch.doJsonX('/rx_auto/presvet/get_code', record)]).then((result) => {
      if (result[0]) {
        let rv = result[0].theValue;
        FxLog.infox('MxAnimalRxAutoForm.presvetRefreshCode', '...rv:', rv);
        if (rf_txCodePresvet.current) {
          rf_txCodePresvet.current.setVal(rv);
          rf_presvet_status_button_clear.current.setShow(false);
          
          if (rv && rv.includes('PRESVET-IN-PROCESS-EXPORT')) { // está exportando a presvet
            r_boProcessing.current = true;
            rf_presvet_status.current.setLabel('Procesando exportación a Presvet...', 'var(--ibs_color_black)');
            rf_presvet_status_button_clear.current.setLabel("[BORRAR MENSAJE - No cancela exportación]");
            rf_presvet_status_button_clear.current.setShow(true);

          } else if (rv && rv.includes('PRESVET-IN-PROCESS-REMOVE')) { // está quitando de presvet
            r_boProcessing.current = true;
            if (rv.split('|')[2]) {
              if (rv.includes('[SHOW-CLEAR-BUTTON]')) {
                rf_presvet_status.current.setLabel('Hubo errores en el proceso Presvet.\n' + rv.split('|')[2], 'var(--ibs_color_red)');
                rf_presvet_status_button_clear.current.setLabel("[BORRAR MENSAJE]");
                rf_presvet_status_button_clear.current.setShow(true);
              } else {
                rf_presvet_status.current.setLabel('Hubo errores en el proceso Presvet. Retrocediendo exportación...\n' + rv.split('|')[2], 'var(--ibs_color_red)');
              }
            } else {
              rf_presvet_status.current.setLabel('Procesando remoción de Presvet...', 'var(--ibs_color_black)');
              rf_presvet_status_button_clear.current.setLabel("[BORRAR MENSAJE - No cancela remoción]");
              rf_presvet_status_button_clear.current.setShow(true);
            }
    
          } else if (rv && rf_txCodePresvet.current.getVal()!==null && rf_txCodePresvet.current.getVal().length>0) { // ha exportado correctamente
            r_boProcessing.current = false;
            let theCodePresvet = rf_txCodePresvet.current.getVal();
            if (theCodePresvet==='rv_error') {
              rf_presvet_status.current.setLabel('ERROR', 'var(--ibs_color_red)');
            } else {
              rf_presvet_status.current.setLabel('Código Presvet: ' + theCodePresvet, 'var(--ibs_color_green)');
            }

          } else { // ha quitado la receta de presvet o nunca la había enviado
            r_boProcessing.current = false;
            rf_presvet_status.current.clear();

          }
          forceUIUpdate();
        }
      }
    });
  }
  
  const presvetClearCode = () => {
    let record = {};
    record['txRxAuto'] = rf_txRxAuto.current.getVal();
    record['itSeriesRx'] = rf_txTypeSeriesRx.current.getKey();
    Promise.all([FxFetch.doJsonX('/rx_auto/presvet/clear_code', record)]).then((result) => {
      if (result[0]) {
        let rv = result[0].theValue;
        FxLog.infox('MxAnimalRxAutoForm.presvetClearCode', '...rv:', rv);
        if (rf_presvet_status.current) {
          rf_presvet_status.current.clear();
          rf_presvet_status_button_clear.current.setShow(false);
          forceUIUpdate();
        }
      }
    });
  }

  const sendStoreToForm = () => {
    try {
      if (rf_txEmployee.current) rf_txEmployee.current.setKey(storeRxAuto.inEmployee);
      if (rf_txEmployee.current) rf_txEmployee.current.setVal(storeRxAuto.txEmployee);
      if (rf_txTypeSeriesRx.current) rf_txTypeSeriesRx.current.setKey(storeRxAuto.itSeriesRx);
      if (rf_txTypeSeriesRx.current) rf_txTypeSeriesRx.current.setVal(storeRxAuto.txTypeSeriesRx);
      if (rf_dtRxAuto.current) rf_dtRxAuto.current.setVal(storeRxAuto.dtRxAuto);
      if (rf_txRxAuto.current) rf_txRxAuto.current.setVal(storeRxAuto.txRxAuto);
      if (rf_txNumberAnimals.current) rf_txNumberAnimals.current.setVal(storeRxAuto.txNumberAnimals);
      if (rf_txIdentification.current) rf_txIdentification.current.setVal(storeRxAuto.txIdentification);
      if (rf_txNumberRefills.current) rf_txNumberRefills.current.setVal(storeRxAuto.txNumberRefills);
      if (rf_boExceptional.current) rf_boExceptional.current.setVal(storeRxAuto.boExceptional);
      if (rf_txDaysValidity.current) rf_txDaysValidity.current.setVal(storeRxAuto.txDaysValidity);
      if (rf_txNotes.current) rf_txNotes.current.setVal(storeRxAuto.txNotes);
      if (rf_txCodePresvet.current) rf_txCodePresvet.current.setVal(storeRxAuto.txCodePresvet);

    } catch (error) {
      FxLog.errorx('MxAnimalRxAutoForm.sendStoreToForm', error)
    }
  }

  const sendFormToStore = async () => {
    try {
      if (rf_txEmployee.current) storeRxAuto.inEmployee = rf_txEmployee.current.getKey();
      if (rf_txEmployee.current) storeRxAuto.txEmployee = rf_txEmployee.current.getVal();
      if (rf_txTypeSeriesRx.current) storeRxAuto.itSeriesRx = rf_txTypeSeriesRx.current.getKey();
      if (rf_txTypeSeriesRx.current) storeRxAuto.txTypeSeriesRx = rf_txTypeSeriesRx.current.getVal();
      if (rf_dtRxAuto.current) storeRxAuto.dtRxAuto = rf_dtRxAuto.current.getVal();
      if (rf_txRxAuto.current) storeRxAuto.txRxAuto = rf_txRxAuto.current.getVal();
      if (rf_txNumberAnimals.current) storeRxAuto.txNumberAnimals = rf_txNumberAnimals.current.getVal();
      if (rf_txIdentification.current) storeRxAuto.txIdentification = rf_txIdentification.current.getVal();
      if (rf_txNumberRefills.current) storeRxAuto.txNumberRefills = rf_txNumberRefills.current.getVal();
      if (rf_boExceptional.current) storeRxAuto.boExceptional = rf_boExceptional.current.getVal();
      if (rf_txDaysValidity.current) storeRxAuto.txDaysValidity = rf_txDaysValidity.current.getVal();
      if (rf_txNotes.current) storeRxAuto.txNotes = rf_txNotes.current.getVal();
      if (rf_txCodePresvet.current) storeRxAuto.txCodePresvet = rf_txCodePresvet.current.getVal();

      if (storeRxAuto.boDataChanged === '0') {
        storeRxAuto.boDataChanged = (r_form.current.wasChanged() ? '1' : '0');
      }

      Promise.all([setStoreRxAuto(storeRxAuto)]).then((result) => {
        return;
      });

    } catch (error) {
      FxLog.errorx('MxAnimalRxAutoForm.sendFormToStore', error)
    }
  }

  const formLoadCustom = () => {
    try {
      sendStoreToForm();

      if (!currentID) { //nuevo registro
        if (storeRxAuto.boFirstRender) {
          if (rf_dtRxAuto.current) rf_dtRxAuto.current.setValToday();
          if (rf_txNumberAnimals.current) rf_txNumberAnimals.current.setVal('1');
          if (rf_txDaysValidity.current) rf_txDaysValidity.current.setVal('30');
          if (getStoreValueBo('StoreSession', 'boAutoSelEmployee')) {
            rf_txEmployee.current.setKey(getStoreValue('StoreSession', 'inEmployee'));
            rf_txEmployee.current.setVal(getStoreValue('StoreSession', 'txEmployee'));
          }
          storeRxAuto.boFirstRender = false;
          setStoreRxAuto(storeRxAuto);
        }
      } else { // registro existente
      }

    } catch (error) {
      FxLog.errorx('MxAnimalRxAutoForm.formLoadCustom', error)
    }
  }

  const formSaveCustom = () => {
    try {
      if (r_form.current.hasIncompleteRequired()) {
        return;
      }

      Promise.all([sendFormToStore()]).then((result) => {

        let filter = {};
        filter['txRxAuto'] = currentID;

        Promise.all([FxFetch.doJsonF(`rx_auto/cu`, filter, storeRxAuto)]).then((resultSave) => {
          if (resultSave[0]) {
            let txRxAuto = resultSave[0].theValue.split('|')[0];
            let itSeriesRx = resultSave[0].theValue.split('|')[1];
            storeRxAuto.boDataChanged = '0';
            storeRxAuto.txRxAuto = txRxAuto;
            storeRxAuto.itSeriesRx = itSeriesRx;

            r_form.current.resetChanged();
            setStoreRxAuto(storeRxAuto);
            setCurrentID(txRxAuto);
            rf_txRxAuto.current.setVal(txRxAuto);
            r_snack_save.current.setOpen(true);

            //navBack();
          }
        });
      });

    } catch (error) {
      FxLog.errorx('MxAnimalRxAutoForm.formSaveCustom', error)
    }
  }

  const formDeleteCustom = () => {
    if (rf_txCodePresvet.current && !rf_txCodePresvet.current.isEmpty()) {
      r_snack_error.current.setValAndOpen('Para eliminar esta receta, primero debe quitarla de Presvet');
    } else {
      setDlgDelete(true);
    }
  }

  const doDelete = () => {
    FxLog.infox('MxAnimalRxAutoForm.doDelete', '...storeRxAuto:', storeRxAuto);
    //-----------------------------------------------------------------------
    setBackdrop(true);

    let url = `rx_auto/d`;
    Promise.all([FxFetch.doJsonF(url, null, storeRxAuto)]).then((result) => {
      FxLog.infox("MxAnimalRxAutoForm.doDelete", "deleting record...", result);
      if (result[0]) {
        FxLog.infox("MxAnimalRxAutoForm.doDelete", "Record was DELETED...responseData:", result[0]);
      } else {
        FxLog.infox("MxAnimalRxAutoForm.doDelete", "Record was DEACTIVATED...responseData:", result[0]);
      }
      r_snack_delete.current.setOpen(true);
    });
    //-----------------------------------------------------------------------
  }

  const canGoBack = () => {
    if (storeRxAuto.boDataChanged === '0') {
      storeRxAuto.boDataChanged = (r_form.current.wasChanged() ? '1' : '0');
    }
    if (storeRxAuto.boDataChanged === '0') {
      FxLog.infox("MxPurDocForm.canGoBack", "data was NOT changed!...");
      return true;
    } else {
      FxLog.infox("MxPurDocForm.canGoBack", "data was changed!...");
      setDlgExit(true);
      return false;
    }
  }

  const openFormItem = async (op, row) => {
    // actualiza el store
    sendFormToStore();

    if (row === null) {
      storeRxAuto.idx = null;
    } else {
      storeRxAuto.idx = row.original["idx"];
    }
    setStoreRxAuto(storeRxAuto);

    navTo("animal-rx-auto-form-item");
  }

  const presvetExport = () => {
    setDlgPresvetExport(false);
    r_dlg_alert.current.setOpen(true);
    let record = {};
    record['txRxAuto'] = rf_txRxAuto.current.getVal();
    record['itSeriesRx'] = rf_txTypeSeriesRx.current.getKey();
    Promise.all([FxFetch.doJsonX('/rx_auto/presvet/export', record)]).then((result) => {
      if (result[0]) {
        let rv = result[0].theValue;
        FxLog.infox('MxAnimalRxAutoForm.presvetExport', '...rv:', rv);
        if (rv === 'OK') {
          r_dlg_alert.current.setText(trans('msg.request_sent'));
          r_dlg_alert.current.setTextColor('var(--ibs_color_green)');
          r_dlg_alert.current.setHasButtonClose(false);
          r_dlg_alert.current.setAutoClose(true);
        } else {
          /* Errores controlados por nuestro servidor */
          let errorDesc = '';
          if (rv==='rv_wait_previous_proc_to_finish') errorDesc = trans('msg.pending_process');
          if (rv==='rv_no_ps_has_national_code') errorDesc = 'Ningún producto tiene Código Nacional de CIMAVET.';
          if (rv==='rv_no_ps_is_antibiotic') errorDesc = 'Ningún producto es de naturaleza antibiótico.';
          if (rv==='rv_missing_client_code') errorDesc = 'Falta "Código" (explotación) en la ficha de Cliente o es incorrecto.';
          if (rv==='rv_missing_incorrect_rx_national_code') errorDesc = 'Falta "Código Nacional" en la ficha del Producto o es incorrecto.';
          if (rv==='rv_missing_incorrect_species') errorDesc = 'Falta "Especie" en ficha de Paciente o no está entre las aceptadas.';
          if (rv==='rv_missing_incorrect_employee_ic') errorDesc = 'Falta "DNI" en ficha de Empleado (Veterinario) o es incorrecto.';
          if (rv==='rv_field_license_must_be_7') errorDesc = 'El "Número de Colegiado" en la ficha de Empleado debe tener 7 dígitos.';
          if (rv==='rv_missing_incorrect_employee_name') errorDesc = 'El "Nombre" en la ficha de Empleado debe tener el formato: \n\n"Apellido/s, Nombre"';
          if (rv==='rv_missing_incorrect_employee_state') errorDesc = 'Falta "Provincia" en la ficha de Empleado o es incorrecta.';
          if (rv==='rv_missing_incorrect_rx_duration') errorDesc = 'Falta "Duración de Tratamiento" en la ficha del Producto o en la receta. El valor debe comenzar con un número.';
          if (rv==='rv_incorrect_rx_date') errorDesc = 'La fecha prescripción no puede ser mayor a la de hoy, ni anterior a 15 días.';

          if (rv.startsWith('rv_presvet_nok|')) errorDesc = 'Presvet: ' + rv.split('|')[1];
          if (rv==='rv_error') errorDesc = trans('msg.error');

          r_dlg_alert.current.setText(errorDesc);
          r_dlg_alert.current.setTextColor('var(--ibs_color_red)');
          r_dlg_alert.current.setHasButtonClose(true);
          r_dlg_alert.current.setAutoClose(false);
        }
      }
    });
  }

  const presvetRemove = () => {
    setDlgPresvetRemove(false);
    r_dlg_alert.current.setOpen(true);
    let record = {};
    record['txRxAuto'] = rf_txRxAuto.current.getVal();
    record['itSeriesRx'] = rf_txTypeSeriesRx.current.getKey();
    record['txCodePresvet'] = rf_txCodePresvet.current.getVal();
    Promise.all([FxFetch.doJsonX('/rx_auto/presvet/remove', record)]).then((result) => {
      if (result[0]) {
        let rv = result[0].theValue;
        FxLog.infox('MxAnimalRxAutoForm.presvetRemove', '...rv:', rv);
        if (rv === 'OK') {
          r_dlg_alert.current.setText(trans('msg.request_sent'));
          r_dlg_alert.current.setTextColor('var(--ibs_color_green)');
          r_dlg_alert.current.setHasButtonClose(false);
          r_dlg_alert.current.setAutoClose(true);
        } else {
          let errorDesc = '';
          if (rv.startsWith('rv_presvet_nok|')) errorDesc = 'Presvet: ' + rv.split('|')[1];
          if (rv==='rv_error') errorDesc = trans('msg.error');

          r_dlg_alert.current.setText(errorDesc);
          r_dlg_alert.current.setTextColor('var(--ibs_color_red)');
          r_dlg_alert.current.setHasButtonClose(true);
          r_dlg_alert.current.setAutoClose(false);
        }
      }
    });
  }

  const presvetAddCode = () => {
    let txCodePresvet = rf_txAddPresvetCode.current.getVal();
    FxLog.infox('MxAnimalRxAutoForm.presvetAddCode', '...txCodePresvet:', txCodePresvet);
    setDlgPresvetAddCode(false)
    if (txCodePresvet.length!==19) {
      r_dlg_alert.current.setText('El Código Presvet debe tener 19 dígitos.');
      r_dlg_alert.current.setTextColor('var(--ibs_color_red)');
      r_dlg_alert.current.setHasButtonClose(false);
      r_dlg_alert.current.setAutoClose(true);
      r_dlg_alert.current.setOpen(true);
    } else {
      let record = {};
      record['txRxAuto'] = rf_txRxAuto.current.getVal();
      record['itSeriesRx'] = rf_txTypeSeriesRx.current.getKey();
      record['txCodePresvet'] = txCodePresvet;
      Promise.all([FxFetch.doJsonX('/rx_auto/presvet/add_code', record)]).then((result) => {
        if (result[0]) {
          let rv = result[0].theValue;
          FxLog.infox('MxAnimalRxAutoForm.presvetAddCode', '...rv:', rv);
          if (rv === 'OK') {
            r_dlg_alert.current.setText('El nuevo Código Presvet ha sido guardado');
            r_dlg_alert.current.setTextColor('var(--ibs_color_green)');
            r_dlg_alert.current.setHasButtonClose(false);
            r_dlg_alert.current.setAutoClose(true);
            r_dlg_alert.current.setOpen(true);
          }
        }
      });
    }
  }

  const doPrintX = async (option) => {
    FxLog.infox('MxAnimalRxAutoForm.doPrintX', '...option', option);
    let boPreview = rf_print_preview.current.isChecked();
    let boCertSign = rf_print_cert_sign.current.isChecked();
    let inEmployee = storeRxAuto.inEmployee;
    let inClient = storeRxAuto.inClient;
    let inAnimal = storeRxAuto.inAnimal;
    let filter = {};
    filter['reportFormat'] = `PDF`;
    filter['txRxAuto'] = `${storeRxAuto.txRxAuto}`;
    filter['itSeriesRx'] = `${storeRxAuto.itSeriesRx}`;
    filter['docSize'] = 'A4';
    filter['nuCopies'] = (rf_print_copies.current ? rf_print_copies.current.getVal() : '1');
    let record = {};
    r_print.current.setScale(1.5);
    r_print.current.setUrl('rx_auto/report');
    r_print.current.setPreview(boPreview);
    r_print.current.setCertificate(boCertSign ? certificate : {});
    localStorage.setItem('MxAnimalRxAutoForm.boPreview', boPreview ? '1' : '0');
    //----------------
    setDlgPrint(false);
    Promise.all([
      r_print.current.setFilter(filter),
      r_print.current.setRecord(record),
      r_print.current.setInEmployee(inEmployee),
      r_print.current.setInClient(inClient),
      r_print.current.setInAnimal(inAnimal),
    ]).then((result) => {
      r_print.current.doPrint();
    });
  }

  /* no deja modificar o eliminar si es factura, o un albarán con factura, o está en el stock */
  const isDisabled = () => { return Boolean(false) };

  return (
    <div>
      <GxForm
        xref={r_form}
        moduleName={`${trans('field.rx')}-${storeRxAuto.boAuto ? trans('field.automatic_0') : trans('field.manual')}`}
        canGoBack={canGoBack}
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>

            {/* botón de exportación presvet */}
            {isSpain() && currentID && !r_boProcessing.current && rf_txCodePresvet.current && rf_txCodePresvet.current.isEmpty() && rf_txCodePresvet.current.getVal()!=='rv_error' &&
              <CxIconButtonNew xref={rf_presvet_button_export} 
                icon={<MdOutlinePresentToAll fontSize="24px" style={{margin: '1px 3px 0 4px'}} />} 
                classType="ibs_iconbutton_toolbar"
                tooltip={trans('field.export_to_x', 'Presvet')}
                onClick={() => {
                  setDlgPresvetExport(true);
                }}
            />}
            {/* botón de remoción presvet */}
            {isSpain() && currentID && !r_boProcessing.current && rf_txCodePresvet.current && !rf_txCodePresvet.current.isEmpty() && rf_txCodePresvet.current.getVal()!=='rv_error' &&
              <CxIconButtonNew xref={rf_presvet_button_remove} 
                icon={<MdOutlineCancelPresentation fontSize="24px" style={{margin: '1px 3px 0 4px'}} />} 
                classType="ibs_iconbutton_toolbar"
                tooltip={trans('field.remove_from_x', 'Presvet')}
                onClick={() => {
                  setDlgPresvetRemove(true);
                }}
            />}
            {/* botón de asignar código presvet manualmente */}
            {isSpain() && currentID && !r_boProcessing.current && rf_txCodePresvet.current && rf_txCodePresvet.current.isEmpty() && rf_txCodePresvet.current.getVal()!=='rv_error' &&
              <CxIconButtonNew xref={rf_presvet_button_add_code_manually} 
                icon={<CgBrackets fontSize="24px" style={{margin: '1px 2px 0 2px'}} />} 
                classType="ibs_iconbutton_toolbar"
                tooltip={'Asignar Código Presvet manualmente'}
                onClick={() => {
                  setDlgPresvetAddCode(true);
                }}
            />}

            {/* botón de impresión */}
            {currentID && <CxIconButton type="print" classType="ibs_iconbutton_toolbar" onClick={() => setDlgPrint(true)} tooltip={`${trans('field.print')} ${trans('field.paper_a4')}`} />}

            {currentID && navStack()[navStack().length-2]==='activity-vett-r-list' && <CxIconButtonNew icon={<MdOutlinePets fontSize={23} />} classType="ibs_iconbutton_toolbar" onClick={() => navTo("animal-form")} tooltip={trans('field.patient')} />}

            {/* botón eliminar. Sólo eliminar lo que no es factura ni relacionado a factura. */}
            {currentID && !isDisabled() && isFullAccess() && <CxIconButton type="delete" classType="ibs_iconbutton_toolbar" onClick={() => formDeleteCustom()} />}

            {/* botón guardar. Incluso la factura puede guardar cambios para poder cambiar las notas. */}
            {!isDisabled() && isFullAccess() && <CxIconButton type="save" classType="ibs_iconbutton_toolbar" onClick={() => formSaveCustom()} />}

          </div>
        }
      >
        <CxHidden xref={rf_inRxAuto} dbfield="n_rx_auto.inRxAuto" />
        <CxHidden xref={React.useRef(null)} dbfield="arItems" value={storeRxAuto.arItems || []} />

        {/* Posibles valores: vacío si no se ha exportado, numérico con Código Presvet si se ha exportado, PRESVET-IN-PROCESS... si está en proceso de exportación o remoción */}
        <CxHidden xref={rf_txCodePresvet} dbfield="n_rx_auto.txCodePresvet" /> 

        {storeClient.txClient && <CxStatic label={`${storeClient.txClient} / ${storeClient.txAnimal}`} border='none' fontcolor='var(--ibs_color_blue)' fontsize='12px' />}
        <CxStatic xref={rf_presvet_status} border='none' fontsize='14px' />
        <span style={{ width: '100%', textAlign: 'left' }}>
          <CxLink xref={rf_presvet_status_button_clear} fontSize='10px' onClick={presvetClearCode} show="false" />
        </span>

        <CxSearcher xref={rf_txEmployee} label={trans('field.veterinarian')}
          dbfield="n_employee.txEmployee" dbfieldkey="n_rx_auto.inEmployee"
          url="employees" col_id="inEmployee" col_tx="txEmployee"
          columns={[
            { id: "0", Header: "ID", accessor: "inEmployee", show: false },
            { id: "1", Header: trans('field.employee'), accessor: row => rf_txEmployee.current.getTable().highlightText(row["txEmployee"]), width: '100%' },
          ]}
          required
        />

        <CxDate xref={rf_dtRxAuto} label={trans('field.date')} dbfield={"n_rx_auto.dtRxAuto"} required />
        <CxInput xref={rf_txRxAuto} label={trans('field.number')} dbfield={"n_rx_auto.txRxAuto"} disabled={true} placeholder={currentID ? '' : 'AUTO'} />

        <CxChooser xref={rf_txTypeSeriesRx} label={trans('field.series')}
          table="t_series_rx"
          col_id="itSeriesRx"
          col_tx="txTypeSeriesRx"
          disabled={Boolean(currentID)}
        />

        <CxInput xref={rf_txNumberAnimals} label={trans('field.rx_number_animals')} dbfield="n_rx_auto.txNumberAnimals" maxLength={10} defaultValue={1} required />
        <CxInput xref={rf_txIdentification} label={trans('field.rx_identification')} dbfield="n_rx_auto.txIdentification" />
        {isUsAu() && <CxInput xref={rf_txNumberRefills} label={trans('field.rx_number_refills')} dbfield="n_rx_auto.txNumberRefills" maxLength={10} />}
        <CxState mode='checkbox' xref={rf_boExceptional} label={trans('field.rx_exceptional')} dbfield="n_rx_auto.boExceptional" />
        <CxInput xref={rf_txDaysValidity} label={trans('field.rx_days_validity')} dbfield="n_rx_auto.txDaysValidity" maxLength={5} />

        {/* PRODUCTOS Y SERVICIOS */}
        {/* ----------------------------------------------------------------------------------------------------------------------------------- */}
        {
          <CxTableMem
            id='doc_items'
            xref={rf_table_items}
            onOpenForm={openFormItem}
            hasButtonAdd={true /* Boolean(!storeRxAuto.boAuto) */}
            disabled={isDisabled()}
            pageSize={5}
            list={storeRxAuto.arItems || []}
            columns={[
              { id: "i", Header: "ID", accessor: "idx", show: false },
              { id: "0", Header: "ID", accessor: "inRxAuto", show: false },
              { id: "1", Header: trans('field.ps'), accessor: "txPs", width: '80%' },
              { id: "2", Header: trans('field.quantity'), accessor: row => FxTable.cellTo4r00(row["nuQuantity"]), width: '20%', align: 'right' },
            ]}
            label={trans('field.ps')}
            required
          />
        }

        <CxInput xref={rf_txNotes} label={trans('field.notes')} dbfield="n_rx_auto.txNotes" minRows={4} />

      </GxForm>

      <CxSnackbar xref={r_snack_ask_save} severity="error" value={trans('msg.save_before')} />
      <CxSnackbar xref={r_snack_save} severity="success" value={trans('msg.saved')} onClose={() => { setBackdrop(false) /* navBack(); */ }} />
      <CxSnackbar xref={r_snack_delete} severity="success" value={trans('msg.deleted')} onClose={() => { navBack(); }} />

      <CxDialog title={trans('msg.confirm_operation')} open={dlgExit} onCancel={() => setDlgExit(false)} onOk={() => navBack()} height='200px'>
        {trans('msg.ask_exit_without_saving')}
      </CxDialog>

      <CxDialog title={trans('msg.warning')} open={dlgDelete}
        onCancel={() => setDlgDelete(false)}
        onOk={() => { setDlgDelete(false); doDelete() }}
        height='200px'>
        {trans('msg.ask_delete')}
      </CxDialog>

      <CxDialog title={trans('msg.ask_print_x', 'field.rx')} open={dlgPrint} onCancel={() => setDlgPrint(false)} onOk={() => doPrintX()} height='290px'>
        <div style={{ width: 'var(--ibs_control_width_md)', display: 'flex', flexDirection: 'column', gap: 5 }}>
          <CxNumber xref={rf_print_copies} label={trans('field.copies')}
            decimals={0}
            width='var(--ibs_control_width_md)'
            sumButton
          />
          <CxState mode='checkbox' xref={rf_print_preview}
            label={trans('field.preview')}
            width='var(--ibs_control_width_md)'
            value={localStorage.getItem('MxAnimalRxAutoForm.boPreview') || '1'}
          />
          <CxState mode='checkbox' xref={rf_print_cert_sign}
            label={trans('field.certificate_based_signature')}
            width='var(--ibs_control_width_md)'
            value={'0'}
            setCertificate={setCertificate}
            onClick={(value) => { if (value==='1') {
              if (!FxEdition.isInEdition('XXX_sig')) {
                rf_print_cert_sign.current.setValBoolean(false);
                r_dlg_upgrade.current.setOpen(true);
              } else {
                r_dlg_certificate.current.setOpen(true);
              }
            }; }}
          />
        </div>
      </CxDialog>

      <CxDlgCertificate xref={r_dlg_certificate} setCertificate={setCertificate} height='290px'
        onCancel={() => {
          rf_print_cert_sign.current.setValBoolean(false);
        }} 
      />

      <CxDlgUpgrade xref={r_dlg_upgrade} />
      <CxBackdrop open={backdrop} />
      <GxPrint xref={r_print} />

      <CxSnackbar xref={r_snack_error} severity="error" />
      <CxSnackbar xref={r_snack_success} severity="success" value={trans('msg.operation_successful')} />

      <CxDlgAlert xref={r_dlg_alert} width="var(--ibs_control_width_md)" fontCase="MIX" fontSize="16px" />

      <CxDialog xref={r_dlg_presvet_export}
        title={'Presvet'}
        open={dlgPresvetExport}
        onCancel={() => setDlgPresvetExport(false)} 
        onOk={presvetExport}
        height='200px'>

        <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }} >
          <CxStatic label={'¿Desea exportar la receta a PresVet?'} border='none' fontcolor='var(--ibs_color_blue)' fontsize='14px' />
        </div>
      </CxDialog>

      <CxDialog xref={r_dlg_presvet_remove}
        title={'Presvet'}
        open={dlgPresvetRemove}
        onCancel={() => setDlgPresvetRemove(false)} 
        onOk={presvetRemove}
        height='200px'>

        <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }} >
          <CxStatic label={'¿Desea quitar la receta de PresVet?'} border='none' fontcolor='var(--ibs_color_red)' fontsize='14px' />
        </div>
      </CxDialog>

      <CxDialog xref={r_dlg_presvet_addcode}
        title={'Presvet'}
        open={dlgPresvetAddCode}
        onCancel={() => setDlgPresvetAddCode(false)} 
        onOk={presvetAddCode}
        height='260px'
      >
        <div style={{ width: 'var(--ibs_control_width_md)', display: 'flex', flexDirection: 'column', gap: 5 }}>
          <CxStatic label={'ATENCIÓN:\nEsta acción se recomienda únicamente cuando se ha añadido la receta directamente en la plataforma Presvet.\n\n'} 
            border='none' 
            fontcolor='var(--ibs_color_red)' 
            fontsize='12px' 
            width='var(--ibs_control_width_md)'
            textAlign='justify'
          />
          <CxNumber xref={rf_txAddPresvetCode} label={'Nuevo Código Presvet'}
            decimals={0}
            width='var(--ibs_control_width_md)'
            maxLength={19}
          />

        </div>
      </CxDialog>

    </div>
  );

}

export default MxAnimalRxAutoForm;
