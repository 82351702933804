import React from 'react';
import '../i18n';
import { Route, Routes } from "react-router-dom";
import MxMain from './modules/MxMain';
import MxLogin from './modules/MxLogin';

import GxViewer from './gx/GxViewer';
import GxSlsAuxTableItem from './gx/GxSlsAuxTableItem';
import MxUpgrade from './MxUpgrade';

import MxMainCP from './modules_cp/MxMainCP';
import MxLoginCP from './modules_cp/MxLoginCP';

import MxClientList from './modules/files/MxClientList';
import MxClientForm from './modules/files/MxClientForm';
import MxClientIncidenceForm from './modules/files/MxClientIncidenceForm';

import MxActivityOptiR from './modules/activity/MxActivityOptiR';
import MxActivityOptiG from './modules/activity/MxActivityOptiG';
import MxActivityOptiC from './modules/activity/MxActivityOptiC';

import MxActivityVettV from './modules/activity/MxActivityVettV';
import MxActivityVettC from './modules/activity/MxActivityVettC';
import MxActivityVettR from './modules/activity/MxActivityVettR';
import MxActivityVettG from './modules/activity/MxActivityVettG';

import MxAnimalForm from './modules/files/MxAnimalForm';
import MxAnimalPhotoForm from './modules/files/MxAnimalPhotoForm';
import MxAnimalWeightForm from './modules/files/MxAnimalWeightForm';
import MxAnimalVaccForm from './modules/files/MxAnimalVaccForm';
import MxAnimalCnsGrlForm from './modules/files/MxAnimalCnsGrlForm';
import MxAnimalGrooForm from './modules/files/MxAnimalGrooForm';
import MxPsList from './modules/files/MxPsList';
import MxPsListPrintLabelFilter from './modules/files/MxPsListPrintLabelFilter';
import MxPsForm from './modules/files/MxPsForm';
import MxPsComponentForm from './modules/files/MxPsComponentForm';
import MxPsRxAutoForm from './modules/files/MxPsRxAutoForm';
import MxAnimalCnsHosForm from './modules/files/MxAnimalCnsHosForm';
import MxAnimalCnsHosFormItem from './modules/files/MxAnimalCnsHosFormItem';
import MxEmployeeList from './modules/files/MxEmployeeList';
import MxEmployeeForm from './modules/files/MxEmployeeForm';
import MxEmployeeCommissionForm from './modules/files/MxEmployeeCommissionForm';
import MxReferrerList from './modules/files/MxReferrerList';
import MxReferrerForm from './modules/files/MxReferrerForm';
import MxClientRxGlasForm from './modules/files/MxClientRxGlasForm';
import MxClientRxClenForm from './modules/files/MxClientRxClenForm';
import MxClientReplForm from './modules/files/MxClientReplForm';
import MxSupplierList from './modules/files/MxSupplierList';
import MxSupplierForm from './modules/files/MxSupplierForm';
import MxLabList from './modules/files/MxLabList';
import MxLabForm from './modules/files/MxLabForm';
import MxClientLabOrderForm from './modules/files/MxClientLabOrderForm';
import MxClientImageForm from './modules/files/MxClientImageForm';
import MxClientMultimediaForm from './modules/files/MxClientMultimediaForm';

import MxCalendarView from './modules/utilities/MxCalendarView';
import MxCalendarForm from './modules/utilities/MxCalendarForm';
import MxCommList from './modules/utilities/MxCommList';
import MxCommForm from './modules/utilities/MxCommForm';
import MxMarketingFilter from './modules/utilities/MxMarketingFilter';
import MxMarketingList from './modules/utilities/MxMarketingList';
import MxMarketingOptionE from './modules/utilities/MxMarketingOptionE';
import MxMarketingOptionS from './modules/utilities/MxMarketingOptionS';
import MxMarketingOptionTsk from './modules/utilities/MxMarketingOptionTsk';
import MxWaitingList from './modules/utilities/MxWaitingList';
import MxWaitingForm from './modules/utilities/MxWaitingForm';
import MxAnimalRxAutoForm from './modules/files/MxAnimalRxAutoForm';
import MxAnimalRxAutoFormItem from './modules/files/MxAnimalRxAutoFormItem';
import MxPlanList from './modules/utilities/MxPlanList';
import MxPlanForm from './modules/utilities/MxPlanForm';
import MxPlanPsForm from './modules/utilities/MxPlanPsForm';

import MxPurDocList from './modules/financial/MxPurDocList';
import MxPurDocForm from './modules/financial/MxPurDocForm';
import MxPurDocFormItem from './modules/financial/MxPurDocFormItem';
import MxPurDocFormPaym from './modules/financial/MxPurDocFormPaym';
import MxSlsDocList from './modules/financial/MxSlsDocList';
import MxSlsDocListSpecOptTsk from './modules/financial/MxSlsDocListSpecOptTsk';
import MxSlsDocForm from './modules/financial/MxSlsDocForm';
import MxSlsDocFormItem from './modules/financial/MxSlsDocFormItem';
import MxSlsDocFormPaym from './modules/financial/MxSlsDocFormPaym';
import MxSlsDocFormLots from './modules/financial/MxSlsDocFormLots';
import MxSlsDocFormPatientsFqPs from './modules/financial/MxSlsDocFormPatientsFqPs';
import MxSlsReceiptInvoicesList from './modules/financial/MxSlsReceiptInvoicesList';
import MxStockroomList from './modules/financial/MxStockroomList';
import MxStockroomForm from './modules/financial/MxStockroomForm';
import MxStats from './modules/financial/MxStats';
import MxCashFilter from './modules/financial/MxCashFilter';
import MxCashList from './modules/financial/MxCashList';
import MxCashForm from './modules/financial/MxCashForm';
import MxMassBillingFilter from './modules/financial/MxMassBillingFilter';
import MxMassBillingList from './modules/financial/MxMassBillingList';
import MxMassBillingOption from './modules/financial/MxMassBillingOption';
import MxMassBillingOptionTsk from './modules/financial/MxMassBillingOptionTsk';
import MxIntegrationPurInvoicesOption from './modules/financial/MxIntegrationPurInvoicesOption';
import MxIntegrationPurInvoicesOptionTsk from './modules/financial/MxIntegrationPurInvoicesOptionTsk';
import MxHistoryFilter from './modules/financial/MxHistoryFilter';
import MxHistorySlsList from './modules/financial/MxHistorySlsList';
import MxHistoryPurList from './modules/financial/MxHistoryPurList';
import MxAnalyticsList from './modules/financial/MxAnalyticsList';
import MxAnalyticsForm from './modules/financial/MxAnalyticsForm';
import MxMultimediaList from './modules/files/MxMultimediaList';
import MxMultimediaForm from './modules/files/MxMultimediaForm';

import MxOptions from './modules/settings/MxOptions';
import MxSettLogo from './modules/settings/MxSettLogo';
import MxSettFileForm from './modules/settings/MxSettFileForm';
import MxSettBranchList from './modules/settings/MxSettBranchList';
import MxSettBranchForm from './modules/settings/MxSettBranchForm';
import MxSettSlsGeneral from './modules/settings/MxSettSlsGeneral';
import MxSettSlsPrinting from './modules/settings/MxSettSlsPrinting';
import MxSettSlsHeaderFooter from './modules/settings/MxSettSlsHeaderFooter';
import MxSettSlsTaxList from './modules/settings/MxSettSlsTaxList';
import MxSettSlsTaxForm from './modules/settings/MxSettSlsTaxForm';
import MxSettSlsWTax from './modules/settings/MxSettSlsWTax';
import MxSettSlsSeriesList from './modules/settings/MxSettSlsSeriesList';
import MxSettSlsSeriesForm from './modules/settings/MxSettSlsSeriesForm';
import MxSettSlsCounterList from './modules/settings/MxSettSlsCounterList';
import MxSettSlsCounterForm from './modules/settings/MxSettSlsCounterForm';
import MxSettSlsNewYearTsk from './modules/settings/MxSettSlsNewYearTsk';
import MxSettPurSurchargeList from './modules/settings/MxSettPurSurchargeList';
import MxSettPurSurchargeForm from './modules/settings/MxSettPurSurchargeForm';
import MxSettCommGeneral from './modules/settings/MxSettCommGeneral';
import MxSettCommAutogen from './modules/settings/MxSettCommAutogen';
import MxSettCommList from './modules/settings/MxSettCommList';
import MxSettCommForm from './modules/settings/MxSettCommForm';
import MxSettCommEmailList from './modules/settings/MxSettCommEmailList';
import MxSettCommEmailForm from './modules/settings/MxSettCommEmailForm';
import MxSettCommSmsList from './modules/settings/MxSettCommSmsList';
import MxSettCommSmsForm from './modules/settings/MxSettCommSmsForm';
import MxSettBankList from './modules/settings/MxSettBankList';
import MxSettBankForm from './modules/settings/MxSettBankForm';
import MxSettCashList from './modules/settings/MxSettCashList';
import MxSettCashForm from './modules/settings/MxSettCashForm';
import MxSettClientList from './modules/settings/MxSettClientList';
import MxSettClientForm from './modules/settings/MxSettClientForm';
import MxSettClientRestoreList from './modules/settings/MxSettClientRestoreList';
import MxSettClientMergeForm from './modules/settings/MxSettClientMergeForm';
import MxSettMultimediaList from './modules/settings/MxSettMultimediaList';
import MxSettMultimediaForm from './modules/settings/MxSettMultimediaForm';
import MxSettImageList from './modules/settings/MxSettImageList';
import MxSettImageForm from './modules/settings/MxSettImageForm';
import MxSettEmployeeList from './modules/settings/MxSettEmployeeList';
import MxSettEmployeeForm from './modules/settings/MxSettEmployeeForm';
import MxSettCusDocList from './modules/settings/MxSettCusDocList';
import MxSettCusDocForm from './modules/settings/MxSettCusDocForm';
import MxSettSupplierList from './modules/settings/MxSettSupplierList';
import MxSettSupplierForm from './modules/settings/MxSettSupplierForm';
import MxSettLabList from './modules/settings/MxSettLabList';
import MxSettLabForm from './modules/settings/MxSettLabForm';
import MxSettLabTechList from './modules/settings/MxSettLabTechList';
import MxSettLabTechForm from './modules/settings/MxSettLabTechForm';
import MxSettProfList from './modules/settings/MxSettProfList';
import MxSettProfForm from './modules/settings/MxSettProfForm';
import MxSettPsGroupList from './modules/settings/MxSettPsGroupList';
import MxSettPsGroupForm from './modules/settings/MxSettPsGroupForm';
import MxSettPsSubgroupList from './modules/settings/MxSettPsSubgroupList';
import MxSettPsSubgroupForm from './modules/settings/MxSettPsSubgroupForm';
import MxSettPsRestoreList from './modules/settings/MxSettPsRestoreList';
import MxSettPsBillingCodeForm from './modules/settings/MxSettPsBillingCodeForm';
import MxSettPsMergeForm from './modules/settings/MxSettPsMergeForm';
import MxSettPsMergeGroupForm from './modules/settings/MxSettPsMergeGroupForm';
import MxSettPsMergeSubgroupForm from './modules/settings/MxSettPsMergeSubgroupForm';
import MxSettAnimalSpeciesList from './modules/settings/MxSettAnimalSpeciesList';
import MxSettAnimalSpeciesForm from './modules/settings/MxSettAnimalSpeciesForm';
import MxSettAnimalBreedList from './modules/settings/MxSettAnimalBreedList';
import MxSettAnimalBreedForm from './modules/settings/MxSettAnimalBreedForm';
import MxSettAnimalCoatList from './modules/settings/MxSettAnimalCoatList';
import MxSettAnimalCoatForm from './modules/settings/MxSettAnimalCoatForm';
import MxSettAnimalRestoreList from './modules/settings/MxSettAnimalRestoreList';
import MxSettAnimalMergeForm from './modules/settings/MxSettAnimalMergeForm';
import MxSettAnimalMergeSpeciesForm from './modules/settings/MxSettAnimalMergeSpeciesForm';
import MxSettAnimalMergeBreedForm from './modules/settings/MxSettAnimalMergeBreedForm';
import MxSettSlsPaymDetList from './modules/settings/MxSettSlsPaymDetList';
import MxSettSlsPaymDetForm from './modules/settings/MxSettSlsPaymDetForm';
import MxSettCalendarGeneral from './modules/settings/MxSettCalendarGeneral';
import MxSettCalendarWorkingHours from './modules/settings/MxSettCalendarWorkingHours';
import MxSettCalendarVisibleDays from './modules/settings/MxSettCalendarVisibleDays';
import MxSettCalendarColList from './modules/settings/MxSettCalendarColList';
import MxSettCalendarColForm from './modules/settings/MxSettCalendarColForm';
import MxSettCalendarGrpList from './modules/settings/MxSettCalendarGrpList';
import MxSettCalendarGrpForm from './modules/settings/MxSettCalendarGrpForm';
import MxSettSlsRateList from './modules/settings/MxSettSlsRateList';
import MxSettSlsRateForm from './modules/settings/MxSettSlsRateForm';
import MxSettSlsRatePsForm from './modules/settings/MxSettSlsRatePsForm';
import MxSettSecUserList from './modules/settings/MxSettSecUserList';
import MxSettSecUserForm from './modules/settings/MxSettSecUserForm';
import MxSettSecGroupList from './modules/settings/MxSettSecGroupList';
import MxSettSecGroupForm from './modules/settings/MxSettSecGroupForm';
import MxSettRxSeriesList from './modules/settings/MxSettRxSeriesList';
import MxSettRxSeriesForm from './modules/settings/MxSettRxSeriesForm';
import MxSettUserActionList from './modules/settings/MxSettUserActionList';
import MxSettCpAccessList from './modules/settings/MxSettCpAccessList';
import MxSettCpAccessForm from './modules/settings/MxSettCpAccessForm';
import MxSettCusTranslationList from './modules/settings/MxSettCusTranslationList';
import MxSettCusTranslationForm from './modules/settings/MxSettCusTranslationForm';
import MxSettStockClear from './modules/settings/MxSettStockClear';
import MxSettStockFill from './modules/settings/MxSettStockFill';
import MxSettStockRebuild from './modules/settings/MxSettStockRebuild';
import MxSettStockSettle from './modules/settings/MxSettStockSettle';

import MxAppointmentListCP from './modules_cp/main_cp/MxAppointmentListCP';
import MxAnimalListCP from './modules_cp/main_cp/MxAnimalListCP';
import MxAnimalFormCP from './modules_cp/main_cp/MxAnimalFormCP';
import MxClientMultimediaFormCP from './modules_cp/main_cp/MxClientMultimediaFormCP';
import MxSlsDocListCP from './modules_cp/main_cp/MxSlsDocListCP';
import MxClientDataCP from './modules_cp/main_cp/MxClientDataCP';
import MxRxFormCP from './modules_cp/main_cp/MxRxFormCP';


function MxNavigator() {
  return (
    <div>
      <Routes>

        {/* Portal Clientes */}
        <Route path="/cp" element={<MxLoginCP />} />

        <Route path="/main_cp" element={<MxMainCP />}>
          <Route path="file-viewer" element={<GxViewer />} />
          <Route path="appointment-list-cp" element={<MxAppointmentListCP />} />
          <Route path="rx-form-cp" element={<MxRxFormCP />} /> {/* OPTI */}
          <Route path="animal-list-cp" element={<MxAnimalListCP />} /> {/* VETT */}
          <Route path="animal-form-cp" element={<MxAnimalFormCP />} /> {/* VETT */}
          <Route path="client-multimedia-form-cp" element={<MxClientMultimediaFormCP />} />
          <Route path="sls-doc-list-cp" element={<MxSlsDocListCP />} />
          <Route path="data-cp" element={<MxClientDataCP />} />
        </Route>


        {/* Aplicación principal */}
        <Route path="/" element={<MxLogin />} />
        <Route path="/login" element={<MxLogin />} />
        
        <Route path="/main" element={<MxMain />}>
          <Route path="upgrade" element={<MxUpgrade />} />
          <Route path="file-viewer" element={<GxViewer />} />
          <Route path="sls-aux-item" element={<GxSlsAuxTableItem />} />

          <Route path="client-list" element={<MxClientList />} />
          <Route path="client-form" element={<MxClientForm />} />
          <Route path="client-incidence-form" element={<MxClientIncidenceForm />} />
          
          <Route path="activity-opti-r-list" element={<MxActivityOptiR />} />
          <Route path="activity-opti-g-list" element={<MxActivityOptiG />} />
          <Route path="activity-opti-c-list" element={<MxActivityOptiC />} />
          
          <Route path="activity-vett-v-list" element={<MxActivityVettV />} />
          <Route path="activity-vett-c-list" element={<MxActivityVettC />} />
          <Route path="activity-vett-r-list" element={<MxActivityVettR />} />
          <Route path="activity-vett-g-list" element={<MxActivityVettG />} />

          <Route path="client-image-form" element={<MxClientImageForm />} />
          <Route path="client-multimedia-form" element={<MxClientMultimediaForm />} />
          <Route path="client-rx-glas-form" element={<MxClientRxGlasForm />} />
          <Route path="client-rx-clen-form" element={<MxClientRxClenForm />} />
          <Route path="client-lab-order-form" element={<MxClientLabOrderForm />} />
          <Route path="client-repl-form" element={<MxClientReplForm />} />
          <Route path="animal-form" element={<MxAnimalForm />} />
          <Route path="animal-photo-form" element={<MxAnimalPhotoForm />} />
          <Route path="animal-weight-form" element={<MxAnimalWeightForm />} />
          <Route path="animal-vacc-form" element={<MxAnimalVaccForm />} />
          <Route path="animal-cns-grl-form" element={<MxAnimalCnsGrlForm />} />
          <Route path="animal-cns-hos-form" element={<MxAnimalCnsHosForm />} />
          <Route path="animal-cns-hos-form-item" element={<MxAnimalCnsHosFormItem />} />
          <Route path="animal-groo-form" element={<MxAnimalGrooForm />} />
          <Route path="animal-rx-auto-form" element={<MxAnimalRxAutoForm />} />
          <Route path="animal-rx-auto-form-item" element={<MxAnimalRxAutoFormItem />} />
          <Route path="pur-doc-list" element={<MxPurDocList />} />
          <Route path="pur-doc-form" element={<MxPurDocForm />} />
          <Route path="pur-doc-form-item" element={<MxPurDocFormItem />} />
          <Route path="pur-doc-form-paym" element={<MxPurDocFormPaym />} />
          <Route path="sls-doc-list" element={<MxSlsDocList />} />
          <Route path="sls-doc-list-spec-opt" element={<MxSlsDocListSpecOptTsk />} />
          <Route path="sls-doc-form" element={<MxSlsDocForm />} />
          <Route path="sls-doc-form-item" element={<MxSlsDocFormItem />} />
          <Route path="sls-doc-form-paym" element={<MxSlsDocFormPaym />} />
          <Route path="sls-doc-form-lots" element={<MxSlsDocFormLots />} />
          <Route path="sls-doc-form-patients-fq-ps" element={<MxSlsDocFormPatientsFqPs />} />
          <Route path="sls-receipt-invoices-list" element={<MxSlsReceiptInvoicesList />} />
          <Route path="history-filter" element={<MxHistoryFilter />} />
          <Route path="history-sls-list" element={<MxHistorySlsList />} />
          <Route path="history-pur-list" element={<MxHistoryPurList />} />
          <Route path="analytics-list" element={<MxAnalyticsList />} />
          <Route path="analytics-form" element={<MxAnalyticsForm />} />
          <Route path="ps-list" element={<MxPsList />} />
          <Route path="ps-form" element={<MxPsForm />} />
          <Route path="ps-list-label-filter" element={<MxPsListPrintLabelFilter />} />
          <Route path="ps-component-form" element={<MxPsComponentForm />} />
          <Route path="ps-rx-auto-form" element={<MxPsRxAutoForm />} />
          <Route path="stock-list" element={<MxStockroomList />} />
          <Route path="stock-form" element={<MxStockroomForm />} />
          <Route path="cash-filter" element={<MxCashFilter />} />
          <Route path="cash-list" element={<MxCashList />} />
          <Route path="cash-form" element={<MxCashForm />} />
          <Route path="mass-billing-filter" element={<MxMassBillingFilter />} />
          <Route path="mass-billing-list" element={<MxMassBillingList />} />
          <Route path="mass-billing-option" element={<MxMassBillingOption />} />
          <Route path="mass-billing-option-tsk" element={<MxMassBillingOptionTsk />} />
          <Route path="integration-pur-invoices-option" element={<MxIntegrationPurInvoicesOption />} />
          <Route path="integration-pur-invoices-option-tsk" element={<MxIntegrationPurInvoicesOptionTsk />} />
          <Route path="calendar-view" element={<MxCalendarView />} />
          <Route path="calendar-form" element={<MxCalendarForm />} />
          <Route path="comm-list" element={<MxCommList />} />
          <Route path="comm-form" element={<MxCommForm />} />
          <Route path="marketing-filter" element={<MxMarketingFilter />} />
          <Route path="marketing-list" element={<MxMarketingList />} />
          <Route path="marketing-option-e" element={<MxMarketingOptionE />} />
          <Route path="marketing-option-s" element={<MxMarketingOptionS />} />
          <Route path="marketing-option-tsk" element={<MxMarketingOptionTsk />} />
          <Route path="waiting-list" element={<MxWaitingList />} />
          <Route path="waiting-form" element={<MxWaitingForm />} />
          <Route path="stats" element={<MxStats />} />
          <Route path="employee-list" element={<MxEmployeeList />} />
          <Route path="employee-form" element={<MxEmployeeForm />} />
          <Route path="commission-form" element={<MxEmployeeCommissionForm />} />
          <Route path="referrer-list" element={<MxReferrerList />} />
          <Route path="referrer-form" element={<MxReferrerForm />} />
          <Route path="supplier-list" element={<MxSupplierList />} />
          <Route path="supplier-form" element={<MxSupplierForm />} />
          <Route path="lab-list" element={<MxLabList />} />
          <Route path="lab-form" element={<MxLabForm />} />
          <Route path="plan-list" element={<MxPlanList />} />
          <Route path="plan-form" element={<MxPlanForm />} />
          <Route path="plan-ps-form" element={<MxPlanPsForm />} />
          <Route path="multimedia-list" element={<MxMultimediaList />} />
          <Route path="multimedia-form" element={<MxMultimediaForm />} />

          <Route path="options" element={<MxOptions />} />
          <Route path="sett-logo" element={<MxSettLogo />} />
          <Route path="sett-file-form" element={<MxSettFileForm />} />
          <Route path="sett-comm-general" element={<MxSettCommGeneral />} />
          <Route path="sett-comm-autogen" element={<MxSettCommAutogen />} />
          <Route path="sett-comm-list" element={<MxSettCommList />} />
          <Route path="sett-comm-form" element={<MxSettCommForm />} />
          <Route path="sett-comm-email-list" element={<MxSettCommEmailList />} />
          <Route path="sett-comm-email-form" element={<MxSettCommEmailForm />} />
          <Route path="sett-comm-sms-list" element={<MxSettCommSmsList />} />
          <Route path="sett-comm-sms-form" element={<MxSettCommSmsForm />} />
          <Route path="sett-sls-general" element={<MxSettSlsGeneral />} />
          <Route path="sett-sls-printing" element={<MxSettSlsPrinting />} />
          <Route path="sett-sls-header-footer" element={<MxSettSlsHeaderFooter />} />
          <Route path="sett-sls-tax-list" element={<MxSettSlsTaxList />} />
          <Route path="sett-sls-tax-form" element={<MxSettSlsTaxForm />} />
          <Route path="sett-sls-wtax" element={<MxSettSlsWTax />} />
          <Route path="sett-sls-series-list" element={<MxSettSlsSeriesList />} />
          <Route path="sett-sls-series-form" element={<MxSettSlsSeriesForm />} />
          <Route path="sett-sls-counter-list" element={<MxSettSlsCounterList />} />
          <Route path="sett-sls-counter-form" element={<MxSettSlsCounterForm />} />
          <Route path="sett-sls-new-year-restart" element={<MxSettSlsNewYearTsk />} />
          <Route path="sett-pur-surcharge-list" element={<MxSettPurSurchargeList />} />
          <Route path="sett-pur-surcharge-form" element={<MxSettPurSurchargeForm />} />
          <Route path="sett-branch-list" element={<MxSettBranchList />} />
          <Route path="sett-branch-form" element={<MxSettBranchForm />} />
          <Route path="sett-bank-list" element={<MxSettBankList />} />
          <Route path="sett-bank-form" element={<MxSettBankForm />} />
          <Route path="sett-cash-list" element={<MxSettCashList />} />
          <Route path="sett-cash-form" element={<MxSettCashForm />} />
          <Route path="sett-client-list" element={<MxSettClientList />} />
          <Route path="sett-client-form" element={<MxSettClientForm />} />
          <Route path="sett-client-portal-list" element={<MxSettCpAccessList />} />
          <Route path="sett-client-portal-form" element={<MxSettCpAccessForm />} />
          <Route path="sett-client-restore-list" element={<MxSettClientRestoreList />} />
          <Route path="sett-client-merge-form" element={<MxSettClientMergeForm />} />
          <Route path="sett-employee-list" element={<MxSettEmployeeList />} />
          <Route path="sett-employee-form" element={<MxSettEmployeeForm />} />
          <Route path="sett-cus-doc-list" element={<MxSettCusDocList />} />
          <Route path="sett-cus-doc-form" element={<MxSettCusDocForm />} />
          <Route path="sett-cus-trans-list" element={<MxSettCusTranslationList />} />
          <Route path="sett-cus-trans-form" element={<MxSettCusTranslationForm />} />
          <Route path="sett-supplier-list" element={<MxSettSupplierList />} />
          <Route path="sett-supplier-form" element={<MxSettSupplierForm />} />
          <Route path="sett-lab-list" element={<MxSettLabList />} />
          <Route path="sett-lab-form" element={<MxSettLabForm />} />
          <Route path="sett-lab-tech-list" element={<MxSettLabTechList />} />
          <Route path="sett-lab-tech-form" element={<MxSettLabTechForm />} />
          <Route path="sett-prof-list" element={<MxSettProfList />} />
          <Route path="sett-prof-form" element={<MxSettProfForm />} />
          <Route path="sett-ps-group-list" element={<MxSettPsGroupList />} />
          <Route path="sett-ps-group-form" element={<MxSettPsGroupForm />} />
          <Route path="sett-ps-subgroup-list" element={<MxSettPsSubgroupList />} />
          <Route path="sett-ps-subgroup-form" element={<MxSettPsSubgroupForm />} />
          <Route path="sett-ps-restore-list" element={<MxSettPsRestoreList />} />
          <Route path="sett-ps-billing-code" element={<MxSettPsBillingCodeForm />} />
          <Route path="sett-ps-merge-form" element={<MxSettPsMergeForm />} />
          <Route path="sett-ps-merge-group-form" element={<MxSettPsMergeGroupForm />} />
          <Route path="sett-ps-merge-subgroup-form" element={<MxSettPsMergeSubgroupForm />} />
          <Route path="sett-animal-species-list" element={<MxSettAnimalSpeciesList />} />
          <Route path="sett-animal-species-form" element={<MxSettAnimalSpeciesForm />} />
          <Route path="sett-animal-breed-list" element={<MxSettAnimalBreedList />} />
          <Route path="sett-animal-breed-form" element={<MxSettAnimalBreedForm />} />
          <Route path="sett-animal-coat-list" element={<MxSettAnimalCoatList />} />
          <Route path="sett-animal-coat-form" element={<MxSettAnimalCoatForm />} />
          <Route path="sett-animal-restore-list" element={<MxSettAnimalRestoreList />} />
          <Route path="sett-animal-merge-form" element={<MxSettAnimalMergeForm />} />
          <Route path="sett-animal-merge-species-form" element={<MxSettAnimalMergeSpeciesForm />} />
          <Route path="sett-animal-merge-breed-form" element={<MxSettAnimalMergeBreedForm />} />
          <Route path="sett-sls-payment-det-list" element={<MxSettSlsPaymDetList />} />
          <Route path="sett-sls-payment-det-form" element={<MxSettSlsPaymDetForm />} />
          <Route path="sett-calendar-general" element={<MxSettCalendarGeneral />} />
          <Route path="sett-calendar-working-hours" element={<MxSettCalendarWorkingHours />} />
          <Route path="sett-calendar-visible-days" element={<MxSettCalendarVisibleDays />} />
          <Route path="sett-calendar-col-list" element={<MxSettCalendarColList />} />
          <Route path="sett-calendar-col-form" element={<MxSettCalendarColForm />} />
          <Route path="sett-calendar-grp-list" element={<MxSettCalendarGrpList />} />
          <Route path="sett-calendar-grp-form" element={<MxSettCalendarGrpForm />} />
          <Route path="sett-sls-rate-list" element={<MxSettSlsRateList />} />
          <Route path="sett-sls-rate-form" element={<MxSettSlsRateForm />} />
          <Route path="sett-sls-rate-ps-form" element={<MxSettSlsRatePsForm />} />
          <Route path="sett-sec-user-list" element={<MxSettSecUserList />} />
          <Route path="sett-sec-user-form" element={<MxSettSecUserForm />} />
          <Route path="sett-sec-group-list" element={<MxSettSecGroupList />} />
          <Route path="sett-sec-group-form" element={<MxSettSecGroupForm />} />
          <Route path="sett-rxs-series-list" element={<MxSettRxSeriesList />} />
          <Route path="sett-rxs-series-form" element={<MxSettRxSeriesForm />} />
          <Route path="sett-multimedia-list" element={<MxSettMultimediaList />} />
          <Route path="sett-client-multimedia-form" element={<MxSettMultimediaForm />} />
          <Route path="sett-image-list" element={<MxSettImageList />} />
          <Route path="sett-client-image-form" element={<MxSettImageForm />} />
          <Route path="sett-user-action-list" element={<MxSettUserActionList />} />
          <Route path="sett-stock-op-clear" element={<MxSettStockClear />} />
          <Route path="sett-stock-op-fill" element={<MxSettStockFill />} />
          <Route path="sett-stock-op-rebuild" element={<MxSettStockRebuild />} />
          <Route path="sett-stock-op-settle" element={<MxSettStockSettle />} />
          
        </Route>

        {/* página predeterminada */}
        <Route element={<h1>404: page not found</h1>} />

      </Routes>

    </div>
  )
}

export default MxNavigator
