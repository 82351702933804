import React from 'react';
import GxForm from '../../gx/GxForm';
import { useNav } from '../../hx/useNav';
import { useTranslation } from '../../hx/useTranslation';
// eslint-disable-next-line no-unused-vars
import CxSnackbar from '../../cx/CxSnackbar';
import { getStoreValue } from "../../gx/GxStore";
import CxImageEditor from '../../cx/CxImageEditor';

const MxSettLogo = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navBack }] = useNav();

  let r_form = React.useRef(null);
  let r_branch = React.useRef(getStoreValue('StoreSession', 'inBranch'));
  
  const rf_image = React.useRef(null);

  const r_snack_ask_save = React.useRef(null);


  React.useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const formLoad = async (data) => {
    if (data === null) { //nuevo registro
    }
  }
  const formSave = async (lastSaveRv) => {
    // se guardó exitosamente
    rf_image.current.doSave(r_branch.current);
    navBack();
  }
  const formDelete = async (rv) => {
  }

  return (
    <div>
      <GxForm
        moduleName={`${trans('field.logo')}`}
        xref={r_form}
        url="sett/logo"
        filterIdField='n_branch_blob.inBranch'
        filterIdValue={r_branch.current}
        onFormLoad={formLoad}
        onFormSave={formSave}
        onFormDelete={formDelete}
      >
        <CxImageEditor xref={rf_image} url='sett/logo' inXXX={r_branch.current} can_search />

      </GxForm>

      <CxSnackbar xref={r_snack_ask_save} severity="error" value={trans('msg.save_before')} />

    </div>
  );

}

export default MxSettLogo;
