// Input para formularios
import React from "react";
import TextField from '@material-ui/core/TextField';
import * as FxLog from '../fx/FxLog';
import CxIconButton from "./CxIconButton";
import '../../styles/main.css';
import { useStyles } from "./StylesCx";


const CxFile = props => {
  const classes = useStyles(props);
  const { onFileChange, onClear, onLoad, InputLabelProps, ...rest } = props;
  const [myFile, setMyFile] = React.useState(null);
  const [myFileName, setMyFileName] = React.useState('');
  const [isRequired, setIsRequired] = React.useState(false);
  const [accept, setAccept] = React.useState(rest.accept||'*');

  const rf_inputfile = React.useRef(null);


  React.useEffect(() => {
    setIsRequired(props.required);
    if (onLoad) onLoad();

    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (onFileChange !== undefined) onFileChange();
    // eslint-disable-next-line
  }, [myFile]);

  // para poder usar setFile desde el parent component con ref.current
  React.useImperativeHandle(props.xref, () => {
    return {
      getType, setFile, getFile, setFileName, getIsRequired, getLabel, clear, isEmpty, setAccept,
    };
  });
  const setFile = (newFile) => { setMyFile(newFile) };
  const setFileName = (newFileName) => { setMyFileName(newFileName) };
  const getFile = () => { return myFile };
  const getIsRequired = () => { return isRequired && (getFile() === null || (getFile()||'').length === 0) };
  const getLabel = () => { return props.label };
  const isEmpty = () => { return myFile===null };
  const getType = () => { return 'CxFile' };

  const clear = () => {
    FxLog.infox("CxFile.clear", "...");
    if (!props.readOnly && !props.disabled) {
      setMyFile(null);
      setMyFileName('');
      if (onClear) onClear();
    }
  };

  const search = async () => {
    if (!props.readOnly && !props.disabled) {
      rf_inputfile.current.click()
    }
  }

  const onChangeFile = (event) => {
    event.stopPropagation();
    event.preventDefault();
    var file = event.target.files[0];
    if (file) {
      setFile(file);
      setFileName(file.name);
    }
  }

  return (
    <div className={classes.frame}>
      <input type="file" accept={accept} ref={rf_inputfile} style={{ display: "none" }} onChange={onChangeFile} />
      <div className={classes.frame_content}>
        <TextField
          minRows={props.minRows}
          multiline={props.minRows !== undefined ? true : false}
          className={classes.control}
          InputLabelProps={{
            classes: { root: classes.root, },
            shrink: true,
            ...InputLabelProps
          }}
          InputProps={{ //refers to the Input component
            disableUnderline: true,
            required: false,
            classes: { root: classes.root, },
            inputProps: { //refers to the input element
              maxLength: props.maxLength,
              readOnly: true,
            },
          }}
          value={myFileName||''}
          {...rest}
        />
      </div>

      <div className={classes.frame_buttons}>
        {!props.readOnly && <CxIconButton type="clear" classType={classes.frame_icons} onClick={clear} disabled={props.disabled} />}
        {!props.readOnly && <CxIconButton type="search" classType={classes.frame_icons} onClick={() => search()} disabled={props.disabled} />}
      </div>
    </div>
  );
};

export default CxFile; 
